export const PUBLIC_ROUTES = [
  "/login",
  "/invite",
  "/register",
  "/pricing",
  "/payment-success",
  "/payment-cancel",
  "/subscription-success",
  "/subscription-error",
  "/email-verification",
];

// Helper function to check if a path is public
export const isPublicRoute = (pathname) => {
  // For root path, only match exactly
  if (pathname === "/") {
    return true;
  }

  // For other paths, check if they start with any public route except root
  return PUBLIC_ROUTES.some((route) => pathname.startsWith(route));
};

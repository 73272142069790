import { Box, Button, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  setShippingAddress,
  setShippingOption,
} from "../../redux/checkoutSlice";

const PaymentCancel = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const dispatch = useDispatch();

  const handleReturnToCheckout = () => {
    // Retrieve saved checkout data from localStorage
    const savedCheckoutData = JSON.parse(
      localStorage.getItem("checkoutData") || "{}"
    );

    if (savedCheckoutData.shippingAddress) {
      dispatch(setShippingAddress(savedCheckoutData.shippingAddress));
    }
    if (savedCheckoutData.shippingOption) {
      dispatch(setShippingOption(savedCheckoutData.shippingOption));
    }

    navigate("/checkout");
  };

  return (
    <Box sx={{ p: 4, maxWidth: "800px" }}>
      <Typography variant="h4" gutterBottom color="error">
        Payment Cancelled
      </Typography>
      <Typography paragraph color="text.secondary">
        Your payment was cancelled. No charges were made to your account.
      </Typography>
      <Box sx={{ mt: 4 }}>
        <Button
          variant="darkRounded"
          onClick={handleReturnToCheckout}
          sx={{ mr: 2 }}
        >
          Return to Checkout
        </Button>
        <Button variant="lightRoundedOutline" onClick={() => navigate("/cart")}>
          Back to Cart
        </Button>
      </Box>
    </Box>
  );
};

export default PaymentCancel;

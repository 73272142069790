import axios from "axios";
import axiosRetry from "axios-retry";
import apiUrl from "../config/config.js";
import store from "../redux/store.js";
import { debug } from "../utils/debug";
import { parseJwt } from "../utils/utils";
import AuthManager from "./AuthManager.js";

export const doPerformRefresh = async (newToken) => {
  try {
    let token = newToken;
    if (!token) {
      // If no new token is provided, use the existing token from the store
      const state = store.getState();
      token = state.auth.token;
    }

    const response = await api.post(
      `${apiUrl}/api/v1/auth/refresh`,
      {},
      {
        headers: {
          Authorization: token ? `Bearer ${token}` : undefined,
        },
      }
    );

    const responseData = response.data;
    const authData = responseData.data || responseData;

    const {
      token: refreshedToken,
      user,
      accounts,
      showQuickStart,
      calendarConnections,
    } = authData;

    const decodedToken = parseJwt(refreshedToken);
    const loggedInUserRoles = decodedToken.roles || [];

    // Process calendar connections properly
    const processedCalendarConnections = {};
    if (calendarConnections?.GOOGLE) {
      const googleStatus = calendarConnections.GOOGLE;
      processedCalendarConnections.GOOGLE = {
        connected: googleStatus.connected || false,
        needsReconnect: googleStatus.needsReconnect || false,
        defaultSyncCalendarId: googleStatus.defaultSyncCalendarId || null,
      };
    }

    debug.auth.log("Token refresh successful", {
      hasCalendarConnections: !!calendarConnections?.GOOGLE,
    });

    return {
      token: refreshedToken,
      loggedInUser: user || {},
      loggedInUserRoles,
      loggedInUserAccounts: accounts || [],
      showQuickStart,
      calendarConnections: processedCalendarConnections,
      googleCalendarConnected:
        processedCalendarConnections.GOOGLE?.connected || false,
      reconnectGoogleCalendar:
        processedCalendarConnections.GOOGLE?.needsReconnect || false,
      defaultSyncCalendarId:
        processedCalendarConnections.GOOGLE?.defaultSyncCalendarId || null,
    };
  } catch (error) {
    debug.auth.error("Refresh error:", error);
    AuthManager.handleAuthError(error);
    throw error;
  }
};

export const api = axios.create({
  baseURL: apiUrl,
  headers: {
    "Content-Type": "application/json",
  },
});

api.interceptors.request.use(async (config) => {
  const isAuthRequest = config.url.includes("/api/v1/auth/");

  if (!isAuthRequest) {
    const token = await AuthManager.getValidToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
  }

  return config;
});

api.interceptors.response.use(
  (response) => {
    AuthManager.clearConsecutiveErrors();
    return response;
  },
  (error) => {
    AuthManager.handleResponseError(error);
    return Promise.reject(error);
  }
);

// Configure retry behavior
axiosRetry(api, {
  retries: 3,
  retryDelay: axiosRetry.exponentialDelay,
  retryCondition: (error) => {
    // Retry on network errors and 5xx errors
    return (
      axiosRetry.isNetworkOrIdempotentRequestError(error) ||
      (error.response && error.response.status >= 500)
    );
  },
});

export default api;

import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import {
  AppBar,
  Box,
  Button,
  IconButton,
  Toolbar,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { debug } from "../utils/debug";
import LoginModal from "./modals/LoginModal";

const navItems = [
  {
    name: "How we can help",
    sectionId: "how-we-can-help",
  },
  {
    name: "Benefits",
    sectionId: "benefits",
  },
  {
    name: "Pricing",
    sectionId: "pricing",
  },
  {
    name: "Testimonials",
    sectionId: "testimonials",
  },
  {
    name: "Contact",
    sectionId: "contact",
  },
];

const LoggedOutHeaderBar = ({ showNavItems = true, showButtons = true }) => {
  const { loggedIn } = useSelector((state) => state.auth);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const navigate = useNavigate();
  const theme = useTheme();
  const headerRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      if (headerRef.current) {
        if (window.scrollY > 0) {
          headerRef.current.style.backgroundColor =
            theme.palette.background.paper;
        } else {
          headerRef.current.style.backgroundColor = "transparent";
        }
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [theme.palette.background.paper]);

  const handleScrollTo = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      const headerHeight = headerRef.current
        ? headerRef.current.offsetHeight
        : 0;
      const elementPosition = section.getBoundingClientRect().top;
      const offsetPosition =
        elementPosition + window.pageYOffset - headerHeight;

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    }
  };

  const handleMenu = () => {
    // Handle menu click
  };

  const handleLogin = () => {
    setShowLoginModal(true);
  };

  const handleCloseLoginModal = () => {
    setShowLoginModal(false);
  };

  const handleNavigateToRegister = () => {
    debug.navigation.log("Navigating to register page");
    navigate("/register");
  };

  return (
    <>
      <AppBar
        ref={headerRef}
        position="fixed"
        elevation={0}
        sx={{
          backgroundColor: "transparent",
          transition: "background-color 0.3s ease",
          borderBottom: "1px solid",
          borderColor: "secondary.light",
        }}
      >
        <Toolbar sx={{ justifyContent: "space-between" }}>
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="/"
            sx={{
              fontFamily: '"Nanum Myeongjo", serif',
              fontWeight: 700,
              fontSize: "1.25rem",
              color: "#000000",
              textDecoration: "none",
              marginLeft: 4,
              letterSpacing: "normal",
            }}
          >
            HomeschoolPlanner
          </Typography>

          {showNavItems && (
            <Box
              sx={{
                position: "absolute",
                left: "50%",
                transform: "translateX(-50%)",
                display: { xs: "none", md: "flex" },
                backgroundColor: theme.palette.primary.dark,
                top: 0,
                bottom: 0,
              }}
              id="nav-items-container"
            >
              {navItems.map((item) => (
                <Button
                  key={item.name}
                  onClick={() => handleScrollTo(item.sectionId)}
                  variant="navItem"
                  sx={{ color: "white" }}
                >
                  {item.name}
                </Button>
              ))}
            </Box>
          )}

          <Box sx={{ display: "flex", gap: 2, marginRight: 4 }}>
            {!loggedIn ? (
              <>
                {showButtons && (
                  <>
                    <Button
                      onClick={handleNavigateToRegister}
                      variant="lightRoundedShadow"
                    >
                      Try for Free
                    </Button>
                    <Button onClick={handleLogin} variant="darkRounded">
                      Log In
                    </Button>
                  </>
                )}
              </>
            ) : (
              <IconButton edge="end" color="inherit" onClick={handleMenu}>
                <AccountCircleIcon />
              </IconButton>
            )}
          </Box>
        </Toolbar>
      </AppBar>
      {showLoginModal && (
        <LoginModal
          open={showLoginModal}
          handleClose={handleCloseLoginModal}
          showRegisterAccount={handleNavigateToRegister}
        />
      )}
    </>
  );
};

export default LoggedOutHeaderBar;

import { Stack } from "@mui/material";
import React from "react";
import StudentsComponent from "./StudentsComponent";

const Users = () => {
  return (
    <Stack spacing={2}>
      <StudentsComponent />
      {/* <EducatorsComponent /> */}
    </Stack>
  );
};

export default Users;

import { LoadingButton } from "@mui/lab";
import { Button, Divider, Stack, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import HsModal from "../common/HsModal";

const DeleteConfirmationModal = ({
  open,
  handleClose,
  onConfirm,
  isLoading,
  title,
  message,
}) => {
  const [confirmText, setConfirmText] = useState("");
  const isConfirmEnabled = confirmText.toLowerCase() === "confirm";

  return (
    <HsModal open={open} handleClose={handleClose} title={title} maxWidth="sm">
      <Stack spacing={3}>
        <Typography color="text.secondary">{message}</Typography>
        <TextField
          fullWidth
          size="small"
          label="Type 'confirm' to delete"
          value={confirmText}
          onChange={(e) => setConfirmText(e.target.value)}
        />
        <Divider />
        <Stack direction="row" spacing={2} justifyContent="flex-end">
          <Button variant="outlined" onClick={handleClose} disabled={isLoading}>
            Cancel
          </Button>
          <LoadingButton
            variant="contained"
            color="error"
            onClick={onConfirm}
            loading={isLoading}
            disabled={!isConfirmEnabled || isLoading}
          >
            Delete
          </LoadingButton>
        </Stack>
      </Stack>
    </HsModal>
  );
};

export default DeleteConfirmationModal;

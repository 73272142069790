import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CONFIG } from "../../config/config";
import { initializeAssistant } from "../../redux/aiAssistantSlice";
import AiAssistantButton from "./AiAssistantButton";
import ChatWindow from "./ChatWindow";

const LOCAL_STORAGE_KEY = "ai_assistant_state";

const AiAssistantContainer = () => {
  const dispatch = useDispatch();
  const { isOpen } = useSelector((state) => state.aiAssistant);
  const { loggedIn } = useSelector((state) => state.auth);

  // Load saved state on mount
  useEffect(() => {
    const savedState = localStorage.getItem(LOCAL_STORAGE_KEY);
    if (savedState) {
      dispatch(initializeAssistant(JSON.parse(savedState)));
    }
  }, [dispatch]);

  // Save state changes to localStorage
  useEffect(() => {
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify({ isOpen }));
  }, [isOpen]);

  // Clear localStorage when user logs out
  useEffect(() => {
    if (!loggedIn) {
      localStorage.removeItem(LOCAL_STORAGE_KEY);
    }
  }, [loggedIn]);

  // Only render if feature flag is enabled and user is logged in
  if (!CONFIG.REACT_APP_ENABLE_AI_ASSISTANT || !loggedIn) {
    return null;
  }

  return (
    <>
      {!isOpen && <AiAssistantButton />}
      {isOpen && <ChatWindow />}
    </>
  );
};

export default AiAssistantContainer;

import SmartToyIcon from "@mui/icons-material/SmartToy";
import { Fab, Tooltip, useTheme } from "@mui/material";
import React from "react";
import { useDispatch } from "react-redux";
import { openAssistant } from "../../redux/aiAssistantSlice";

const AiAssistantButton = () => {
  const theme = useTheme();
  const dispatch = useDispatch();

  return (
    <Tooltip title="AI Scheduling Assistant" placement="left">
      <Fab
        color="primary"
        aria-label="AI Assistant"
        onClick={() => dispatch(openAssistant())}
        sx={{
          position: "fixed",
          bottom: theme.spacing(3),
          right: theme.spacing(3),
          bgcolor: theme.palette.purple.main,
          "&:hover": {
            bgcolor: theme.palette.purple.dark,
          },
          [theme.breakpoints.down("sm")]: {
            bottom: theme.spacing(2),
            right: theme.spacing(2),
          },
        }}
      >
        <SmartToyIcon />
      </Fab>
    </Tooltip>
  );
};

export default AiAssistantButton;

export const PRODUCT_IDS = {
  PLANNER: process.env.REACT_APP_PLANNER_PRODUCT_ID,
  // Add other products here as needed
};

// If you prefer an enum-like structure, you could also do:
// export const PRODUCT_IDS = Object.freeze({
//   PLANNER: "66f79a3ce6615479291a432c",
//   // Add other products here as needed
// });

export const PAID_PLAN_TYPES = ["BASIC", "FAMILY", "PRO"];
export const ALL_PLAN_TYPES = ["BASIC", "FAMILY", "PRO", "FREE", "NONE"];

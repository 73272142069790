// src/components/CalendarView.js

import { Box, Button, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import dayjs from "dayjs";
import React, { useCallback, useEffect, useState } from "react";
import { Calendar, Views, dayjsLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import LoadingIndicator from "../../components/common/LoadingIndicator";

const localizer = dayjsLocalizer(dayjs);

const CalendarView = ({
  events,
  handleOpenDetailsModal,
  loading,
  error,
  onRangeChange,
  onView,
  onNavigate,
  defaultView = Views.MONTH,
  currentDate,
}) => {
  const theme = useTheme();
  const [currentLabel, setCurrentLabel] = useState("");

  const handleClickEvent = (event) => {
    handleOpenDetailsModal(event);
  };

  // Custom toolbar to match theme
  const CustomToolbar = ({
    label,
    onNavigate: toolbarNavigate,
    onView,
    view,
  }) => {
    // Update the label whenever it changes
    useEffect(() => {
      setCurrentLabel(label);
    }, [label]);

    const handleNavigate = (action) => {
      toolbarNavigate(action);
      onNavigate(action);
    };

    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          justifyContent: "space-between",
          alignItems: { xs: "stretch", sm: "center" },
          gap: 2,
          mb: 2,
        }}
      >
        {/* Navigation Buttons */}
        <Box
          sx={{
            display: "flex",
            gap: 1,
          }}
        >
          <Button
            onClick={() => handleNavigate("PREV")}
            variant="outlined"
            size="small"
            sx={{
              flex: 1,
              minWidth: 0,
              px: { xs: 1, sm: 2 },
            }}
          >
            Back
          </Button>
          <Button
            onClick={() => handleNavigate("TODAY")}
            variant="outlined"
            size="small"
            sx={{
              flex: 1,
              minWidth: 0,
              px: { xs: 1, sm: 2 },
            }}
          >
            Today
          </Button>
          <Button
            onClick={() => handleNavigate("NEXT")}
            variant="outlined"
            size="small"
            sx={{
              flex: 1,
              minWidth: 0,
              px: { xs: 1, sm: 2 },
            }}
          >
            Next
          </Button>
        </Box>

        {/* Current Period Label */}
        <Typography
          variant="subtitle1"
          sx={{
            fontWeight: "bold",
            textAlign: { xs: "center", sm: "left" },
            order: { xs: -1, sm: 0 },
          }}
        >
          {currentLabel}
        </Typography>

        {/* View Selection Buttons */}
        <Box
          sx={{
            display: "flex",
            gap: 1,
            flexWrap: "wrap",
            justifyContent: { xs: "space-between", sm: "flex-end" },
          }}
        >
          {["month", "week", "day", "agenda"].map((viewName) => (
            <Button
              key={viewName}
              onClick={() => onView(viewName)}
              variant={view === viewName ? "contained" : "outlined"}
              size="small"
              sx={{
                minWidth: { xs: "calc(50% - 4px)", sm: "auto" },
                px: { xs: 1, sm: 2 },
              }}
            >
              {viewName.charAt(0).toUpperCase() + viewName.slice(1)}
            </Button>
          ))}
        </Box>
      </Box>
    );
  };

  // Custom event component
  const EventComponent = useCallback(
    ({ event }) => (
      <div
        style={{
          backgroundColor: theme.palette.primary.light,
          color: theme.palette.primary.contrastText,
          padding: "4px 8px",
          borderRadius: "4px",
          fontSize: "0.875rem",
        }}
      >
        {event.title}
      </div>
    ),
    [theme]
  );

  if (loading) {
    return <LoadingIndicator message="Loading calendar events..." />;
  }

  if (error) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height={500}
        sx={{
          backgroundColor: "background.paper",
          borderRadius: 2,
          p: 3,
        }}
      >
        <Typography color="error" variant="h6">
          {error}
        </Typography>
      </Box>
    );
  }

  return (
    <Calendar
      localizer={localizer}
      events={events}
      startAccessor="start"
      endAccessor="end"
      defaultView={defaultView}
      views={[Views.MONTH, Views.WEEK, Views.DAY, Views.AGENDA]}
      onSelectEvent={handleClickEvent}
      onRangeChange={onRangeChange}
      onView={onView}
      defaultDate={currentDate}
      style={{ height: 700 }}
      components={{
        toolbar: CustomToolbar,
        event: EventComponent,
      }}
      popup
      selectable
      min={new Date().setHours(6, 0, 0)}
      max={new Date().setHours(20, 0, 0)}
      eventPropGetter={() => ({
        style: {
          backgroundColor: theme.palette.primary.light,
          border: "none",
        },
      })}
    />
  );
};

export default CalendarView;

import { LoadingButton } from "@mui/lab";
import { Button, Stack, TextField } from "@mui/material";
import { Field, Form, Formik } from "formik";
import React from "react";
import { useSelector } from "react-redux";
import { updateUserProfile } from "../../api/userApi";
import HsModal from "../../components/common/HsModal";
import { useReportContext } from "../../contexts/report-context";
import { useAsyncOperation } from "../../hooks/useAsyncOperation";
import { registrationValidationSchema } from "../../utils/validation";

const EditUserModal = ({ open, handleClose, user, onSave }) => {
  const { setMessage } = useReportContext();
  const selectedAccountId = useSelector(
    (state) => state.auth.selectedAccountId
  );
  const { execute: executeUpdateUser, loading: isUpdating } =
    useAsyncOperation(updateUserProfile);

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      await executeUpdateUser(
        user.id,
        {
          ...values,
          username: values.email,
        },
        selectedAccountId
      );
      setMessage({
        text: "User updated successfully",
        severity: "success",
        flag: true,
      });
      onSave();
    } catch (err) {
      setMessage({
        text: err.message || "Failed to update user",
        severity: "error",
        flag: true,
      });
      setSubmitting(false);
    }
  };

  return (
    <HsModal open={open} handleClose={handleClose} title="Edit User">
      <Formik
        initialValues={{
          firstName: user?.firstName || "",
          lastName: user?.lastName || "",
          email: user?.email || "",
        }}
        validationSchema={registrationValidationSchema.pick([
          "firstName",
          "lastName",
          "email",
        ])}
        onSubmit={handleSubmit}
        validateOnChange={false}
        validateOnBlur={true}
      >
        {({ isSubmitting, touched, errors }) => (
          <Form>
            <Stack spacing={3}>
              <Stack spacing={3}>
                <Field name="firstName">
                  {({ field }) => (
                    <TextField
                      {...field}
                      label="First Name"
                      fullWidth
                      size="small"
                      error={touched.firstName && Boolean(errors.firstName)}
                      helperText={touched.firstName && errors.firstName}
                      required
                    />
                  )}
                </Field>

                <Field name="lastName">
                  {({ field }) => (
                    <TextField
                      {...field}
                      label="Last Name"
                      fullWidth
                      size="small"
                      error={touched.lastName && Boolean(errors.lastName)}
                      helperText={touched.lastName && errors.lastName}
                      required
                    />
                  )}
                </Field>

                <Field name="email">
                  {({ field }) => (
                    <TextField
                      {...field}
                      type="email"
                      label="Email Address"
                      fullWidth
                      size="small"
                      error={touched.email && Boolean(errors.email)}
                      helperText={touched.email && errors.email}
                      required
                    />
                  )}
                </Field>
              </Stack>

              <Stack
                direction="row"
                spacing={2}
                justifyContent="flex-end"
                sx={{ mt: 3 }}
              >
                <Button
                  variant="outlined"
                  onClick={handleClose}
                  disabled={isSubmitting}
                >
                  Cancel
                </Button>
                <LoadingButton
                  type="submit"
                  variant="contained"
                  loading={isUpdating}
                  disabled={isUpdating}
                >
                  Save Changes
                </LoadingButton>
              </Stack>
            </Stack>
          </Form>
        )}
      </Formik>
    </HsModal>
  );
};

export default EditUserModal;

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  Box,
  Button,
  Card,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { format } from "date-fns";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ConfirmationDialog from "../../components/common/ConfirmationDialog";
import LoadingIndicator from "../../components/common/LoadingIndicator";
import { useReportContext } from "../../contexts/report-context";
import { useAsyncOperation } from "../../hooks/useAsyncOperation";
import { useTerms } from "../../hooks/useTerms";
import { debug } from "../../utils/debug";
import SchoolTermModal from "./modal/SchoolTermModal";

const StyledCard = styled(Card)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius,
  boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
  overflow: "hidden",
}));

const DetailItem = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(3),
  "&:last-child": {
    marginBottom: 0,
  },
}));

const HeaderStack = styled(Stack)(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    "& .back-button": {
      marginRight: theme.spacing(1),
    },
    "& .term-name": {
      fontSize: "1.5rem",
      marginTop: theme.spacing(1),
    },
    "& .actions": {
      marginTop: theme.spacing(2),
      width: "100%",
      "& .MuiButton-root": {
        flex: 1,
      },
    },
  },
}));

const DAYS_ORDER = [
  "MONDAY",
  "TUESDAY",
  "WEDNESDAY",
  "THURSDAY",
  "FRIDAY",
  "SATURDAY",
  "SUNDAY",
];

const formatDayName = (day) => {
  return day.charAt(0) + day.slice(1).toLowerCase();
};

const SchoolTermDetail = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { id } = useParams();
  const { setMessage } = useReportContext();
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [deleteConfirmation, setDeleteConfirmation] = useState({
    open: false,
    confirmText: "",
  });
  const [anchorEl, setAnchorEl] = useState(null);

  const {
    activeTerms,
    futureTerms,
    pastTerms,
    activeTermsLoading,
    futureTermsLoading,
    pastTermsLoading,
    refreshTerms,
    deleteTerm,
  } = useTerms();

  const {
    loading: isDeleting,
    error: deleteError,
    execute: executeDelete,
  } = useAsyncOperation(async () => {
    debug.log("Executing delete operation:", { termId: id });

    if (!id) {
      throw new Error("Term ID not found");
    }
    await deleteTerm(id);
  });

  // Add useEffect to load terms when component mounts
  useEffect(() => {
    refreshTerms();
  }, [refreshTerms]);

  // Find the term in all term lists
  const term = [
    ...(activeTerms || []),
    ...(futureTerms || []),
    ...(pastTerms || []),
  ].find((t) => t.id === id);

  const isLoading =
    activeTermsLoading || futureTermsLoading || pastTermsLoading;

  // Log when term changes
  useEffect(() => {
    debug.log("Term state updated:", {
      termId: term?.id,
      hasActiveTerm: activeTerms?.some((t) => t.id === id),
      hasFutureTerm: futureTerms?.some((t) => t.id === id),
      hasPastTerm: pastTerms?.some((t) => t.id === id),
      isLoading,
    });
  }, [term, id, activeTerms, futureTerms, pastTerms, isLoading]);

  const sortedInstructionHours = useMemo(() => {
    if (!term?.instructionHours?.hours) return [];

    return DAYS_ORDER.map((day) => ({
      day,
      hours: term.instructionHours.hours[day],
    })).filter(({ hours }) => {
      // Filter out null, undefined, empty arrays, and empty objects
      if (!hours) return false;
      if (Array.isArray(hours) && hours.length === 0) return false;
      if (typeof hours === "object" && Object.keys(hours).length === 0)
        return false;
      return true;
    });
  }, [term?.instructionHours?.hours]);

  const handleBack = () => {
    navigate("/terms");
  };

  const handleEdit = () => {
    setIsEditModalOpen(true);
  };

  const handleSave = useCallback(() => {
    setIsEditModalOpen(false);
    refreshTerms();
  }, [refreshTerms]);

  const handleDelete = () => {
    setDeleteConfirmation({
      open: true,
      confirmText: "",
    });
  };

  const handleConfirmDelete = async () => {
    debug.log("Starting delete confirmation:", {
      termId: term?.id,
      isDeleting,
      hasError: !!deleteError,
    });

    try {
      await executeDelete();
      setMessage({
        text: "Term deleted successfully",
        severity: "success",
        flag: true,
      });
      navigate("/terms");
    } catch (error) {
      debug.error("Failed to delete term:", {
        termId: term?.id,
        error: error.message || error,
      });
      setMessage({
        text: error.message || "Failed to delete term",
        severity: "error",
        flag: true,
      });
      return;
    }

    setDeleteConfirmation({ open: false, confirmText: "" });
  };

  if (isLoading) {
    return (
      <Box sx={{ p: 3 }}>
        <LoadingIndicator message="Loading term details..." />
      </Box>
    );
  }

  if (!term) {
    return (
      <Box sx={{ p: 3 }}>
        <Stack spacing={2}>
          <Typography variant="h5" color="error">
            Term not found
          </Typography>
          <Box>
            <Button
              startIcon={<ArrowBackIcon />}
              onClick={handleBack}
              variant="outlined"
            >
              Back to Terms
            </Button>
          </Box>
        </Stack>
      </Box>
    );
  }

  return (
    <Box sx={{ p: 3 }}>
      <HeaderStack spacing={3}>
        <Stack direction="row" alignItems="center" spacing={2}>
          <IconButton
            onClick={handleBack}
            className="back-button"
            sx={{
              color: theme.palette.text.secondary,
              marginLeft: "-8px",
            }}
          >
            <ArrowBackIcon />
          </IconButton>
          <Typography
            variant="h4"
            className="term-name"
            sx={{
              flex: 1,
              fontFamily: theme.typography.h4.fontFamily,
            }}
          >
            {term.name}
          </Typography>
        </Stack>

        <Stack
          direction={{ xs: "column", sm: "row" }}
          spacing={1.5}
          className="actions"
          sx={{
            justifyContent: { xs: "stretch", sm: "flex-start" },
          }}
        >
          <Button
            variant="contained"
            startIcon={<EditIcon />}
            onClick={handleEdit}
            sx={{ borderRadius: 1 }}
          >
            Edit Term
          </Button>
          <IconButton
            onClick={(e) => setAnchorEl(e.currentTarget)}
            sx={{
              color: theme.palette.text.secondary,
              border: `1px solid ${theme.palette.divider}`,
              borderRadius: 1,
              width: { xs: "100%", sm: "auto" },
              "&:hover": {
                backgroundColor: theme.palette.action.hover,
              },
              display: "flex",
              gap: 1,
              px: 2,
              py: 1,
            }}
          >
            <Typography
              variant="button"
              sx={{ display: { xs: "block", sm: "none" } }}
            >
              More Actions
            </Typography>
            <MoreVertIcon />
          </IconButton>
        </Stack>
      </HeaderStack>

      <StyledCard sx={{ mt: 4 }}>
        <Box sx={{ p: { xs: 2, sm: 3 } }}>
          <DetailItem>
            <Typography variant="subtitle2" color="text.secondary" gutterBottom>
              Date Range
            </Typography>
            <Typography>
              {format(new Date(term.startDate), "MMMM d, yyyy")} -{" "}
              {format(new Date(term.endDate), "MMMM d, yyyy")}
            </Typography>
          </DetailItem>
          <DetailItem>
            <Typography variant="subtitle2" color="text.secondary" gutterBottom>
              Instruction Hours
            </Typography>
            {sortedInstructionHours.map(({ day, hours }) => (
              <Box
                key={day}
                sx={{
                  mt: 1,
                  display: "flex",
                  flexDirection: { xs: "column", sm: "row" },
                  gap: { xs: 0.5, sm: 2 },
                  alignItems: { xs: "flex-start", sm: "center" },
                }}
              >
                <Typography
                  variant="body2"
                  sx={{ minWidth: 100, fontWeight: 500 }}
                >
                  {formatDayName(day)}:
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {Array.isArray(hours)
                    ? hours
                        .map((h) => `${h.startTime} - ${h.endTime}`)
                        .join(", ")
                    : `${hours.startTime} - ${hours.endTime}`}
                </Typography>
              </Box>
            ))}
            {sortedInstructionHours.length === 0 && (
              <Typography variant="body2" color="text.secondary">
                No instruction hours set
              </Typography>
            )}
          </DetailItem>
        </Box>
      </StyledCard>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuItem
          onClick={() => {
            setAnchorEl(null);
            handleDelete();
          }}
          sx={{
            color: "error.main",
            "&:hover": {
              backgroundColor: "error.lighter",
            },
            minWidth: 200,
          }}
        >
          <DeleteIcon sx={{ fontSize: 20, mr: 1 }} />
          Delete Term
        </MenuItem>
      </Menu>

      <SchoolTermModal
        open={isEditModalOpen}
        onSave={handleSave}
        onCancel={() => setIsEditModalOpen(false)}
        term={term}
      />

      <ConfirmationDialog
        open={deleteConfirmation.open}
        title="Delete School Term?"
        message={
          <Box>
            <Typography variant="body1" gutterBottom>
              Are you sure you want to delete "{term?.name}"?
            </Typography>
            <Typography variant="body2" color="error" sx={{ mt: 2 }}>
              Warning: This action cannot be undone. Some data associated with
              this term may no longer be editable or accessible.
            </Typography>
            <Typography variant="body2" sx={{ mt: 2 }}>
              Type <strong>CONFIRM</strong> to delete this term.
            </Typography>
            {deleteError && (
              <Typography color="error" sx={{ mt: 2 }}>
                {deleteError.message ||
                  "Failed to delete term. Please try again."}
              </Typography>
            )}
          </Box>
        }
        confirmText={isDeleting ? "Deleting..." : "Delete"}
        cancelText="Cancel"
        onConfirm={handleConfirmDelete}
        onCancel={() => setDeleteConfirmation({ open: false, confirmText: "" })}
        requireConfirmationText="CONFIRM"
        caseSensitive={false}
        confirmButtonProps={{
          color: "error",
        }}
      />
    </Box>
  );
};

export default SchoolTermDetail;

import { Box, CssBaseline } from "@mui/material";
import { styled, ThemeProvider } from "@mui/material/styles";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import React, { useEffect, useMemo } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import AiAssistantContainer from "./components/AiAssistant/AiAssistantContainer";
import CalendarConnectionManager from "./components/CalendarConnectionManager";
import DrawerComponent from "./components/DrawerComponent";
import ErrorBoundary from "./components/ErrorBoundary";
import Footer from "./components/Footer";
import HeaderBar from "./components/HeaderBar";
import LoggedOutHeaderBar from "./components/LoggedOutHeaderBar";
import Login from "./components/Login";
import NetworkErrorBoundary from "./components/NetworkErrorBoundary";
import ShoppingCart from "./components/ShoppingCart";
import Checkout from "./components/checkout/Checkout";
import LoadingIndicator from "./components/common/LoadingIndicator";
import OrderPrintedPlanner from "./components/printedPlanner/OrderPrintedPlanner";
import { isPublicRoute } from "./config/routeConstants";
import { DrawerProvider } from "./contexts/drawer-context";
import { NetworkProvider } from "./contexts/network-context";
import { PlannerWizardContext } from "./contexts/planner-wizard-context";
import { ReportContextProvider } from "./contexts/report-context";
import { useLoadAuth } from "./hooks/useLoadAuth";
import AccountSettings from "./pages/account-settings";
import CalendarEventsPage from "./pages/calendar-events";
import EmailVerification from "./pages/email-verification";
import Home from "./pages/home";
import InvitePage from "./pages/invite";
import ClassesPage from "./pages/lesson-plans";
import LessonPlansDetailsPage from "./pages/lesson-plans/LessonPlanDetails";
import Orders from "./pages/orders";
import OrderDetails from "./pages/orders/OrderDetails";
import PaymentCancel from "./pages/payment-cancel";
import PaymentSuccess from "./pages/payment-success";
import PricingPage from "./pages/pricing";
import ProfilePage from "./pages/profile";
import RegistrationPage from "./pages/register";
import ReportGenerator from "./pages/reports/ReportGenerator";
import ReportsPage from "./pages/reports/ReportsPage";
import SchoolTermsPage from "./pages/school-terms";
import SchoolTermDetail from "./pages/school-terms/SchoolTermDetail";
import SubscriptionPage from "./pages/subscription";
import SubscriptionError from "./pages/subscription-error";
import SubscriptionSuccessHandler from "./pages/subscription-success";
import Users from "./pages/users";
import UserDetailsPage from "./pages/users/UserDetails";
import theme from "./theme";
import { debug } from "./utils/debug";

const StyledContent = styled(Box)(({ theme }) => ({
  flexGrow: 1,
  width: "100%",
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
}));

const shouldHideLoggedOutHeaderContent = (pathname) => {
  return (
    isAlwaysPublicRoute(pathname) ||
    pathname === "/register" ||
    pathname === "/login" ||
    pathname.startsWith("/email-verification")
  );
};

const ALWAYS_PUBLIC_ROUTES = ["/", "/email-verification"];

const isAlwaysPublicRoute = (pathname) => {
  return ALWAYS_PUBLIC_ROUTES.some((route) => pathname.startsWith(route));
};

const App = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { isLoading, loggedIn, selectedAccountId, isInitialized } =
    useLoadAuth();

  const headerHeight =
    theme.components.MuiToolbar.styleOverrides.root.minHeight;

  const isPublicPath = useMemo(() => {
    return isPublicRoute(location.pathname);
  }, [location.pathname]);

  useEffect(() => {
    if (loggedIn && selectedAccountId && location.pathname === "/") {
      navigate("/home");
    }
  }, [loggedIn, selectedAccountId, location, navigate]);

  useEffect(() => {
    if (isInitialized) {
      if (!loggedIn && !isPublicPath) {
        navigate("/");
      }
    }
  }, [isInitialized, loggedIn, isPublicPath, navigate, location.pathname]);

  if (isLoading) {
    return (
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <LoadingIndicator message="Loading your planner..." fullHeight={true} />
      </ThemeProvider>
    );
  }

  const renderContent = () => (
    <Box sx={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}>
      {loggedIn && <HeaderBar />}
      {!loggedIn && (
        <LoggedOutHeaderBar
          showButtons={!shouldHideLoggedOutHeaderContent(location.pathname)}
          showNavItems={!shouldHideLoggedOutHeaderContent(location.pathname)}
        />
      )}
      <Box sx={{ display: "flex", flexGrow: 1 }}>
        {loggedIn && <DrawerComponent />}

        <StyledContent
          sx={{
            paddingTop: !loggedIn
              ? `${headerHeight}px`
              : loggedIn
              ? `${headerHeight + 32}px`
              : 0,
            paddingLeft: theme.spacing(3),
            paddingRight: theme.spacing(3),
            paddingBottom: theme.spacing(3),
          }}
        >
          <Routes>
            <Route path="/" element={<Login />} />
            {!loggedIn && (
              <>
                <Route path="/invite" element={<InvitePage />} />
              </>
            )}
            {loggedIn && (
              // TODO: Add invite page for logged in users
              <>
                <Route path="/invite" element={<Home />} />
              </>
            )}
            <Route path="/home" element={<Home />} />
            <Route path="/calendar-events" element={<CalendarEventsPage />} />
            <Route path="/terms" element={<SchoolTermsPage />} />
            <Route path="/terms/:id" element={<SchoolTermDetail />} />
            <Route path="/users" element={<Users />} />
            <Route path="/users/:id" element={<UserDetailsPage />} />
            <Route path="/login" element={<Login />} />
            <Route path="/pricing" element={<PricingPage />} />
            <Route path="/lesson-plans" element={<ClassesPage />} />
            <Route
              path="/lesson-plans/:id"
              element={<LessonPlansDetailsPage />}
            />
            <Route path="/reports" element={<ReportsPage />} />
            <Route path="/reports/generate" element={<ReportGenerator />} />
            <Route path="/account-settings" element={<AccountSettings />} />
            <Route path="/profile" element={<ProfilePage />} />
            <Route
              path="/subscription-success"
              element={<SubscriptionSuccessHandler />}
            />
            <Route path="/subscription-error" element={<SubscriptionError />} />
            <Route
              path="/email-verification/:token"
              element={<EmailVerification />}
            />
            <Route path="/register" element={<RegistrationPage />} />
            <Route path="/cart" element={<ShoppingCart />} />
            <Route path="/checkout" element={<Checkout />} />
            <Route path="/payment-success" element={<PaymentSuccess />} />
            <Route path="/payment-cancel" element={<PaymentCancel />} />
            <Route path="/orders" element={<Orders />} />
            <Route path="/orders/:orderId" element={<OrderDetails />} />
            <Route path="/subscription" element={<SubscriptionPage />} />
          </Routes>
        </StyledContent>
      </Box>
      {!loggedIn && !shouldHideLoggedOutHeaderContent(location.pathname) && (
        <Footer />
      )}
    </Box>
  );

  debug.wizard.state("App render", {
    hasOrderPrintedPlanner: Boolean(OrderPrintedPlanner),
    hasWizardContext: Boolean(PlannerWizardContext),
  });

  return (
    <ErrorBoundary>
      <ThemeProvider theme={theme}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <ReportContextProvider>
            <CssBaseline />
            <DrawerProvider>
              <NetworkProvider>
                <NetworkErrorBoundary>
                  <CalendarConnectionManager />
                  {renderContent()}
                  <AiAssistantContainer />
                </NetworkErrorBoundary>
              </NetworkProvider>
            </DrawerProvider>
          </ReportContextProvider>
        </LocalizationProvider>
        <ToastContainer />
      </ThemeProvider>
    </ErrorBoundary>
  );
};

export default App;

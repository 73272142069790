import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import {
  Box,
  Checkbox,
  CircularProgress,
  IconButton,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import React from "react";

// Keep only necessary styled components
const StyledPaper = styled(Paper)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  boxShadow: "0px 0px 6px rgba(0, 0, 0, 0.3)", // Updated shadow
  overflow: "hidden",
}));

const StyledTableContainer = styled(TableContainer)({
  borderRadius: 0,
});

const PageControlSelect = styled(Select)(({ theme }) => ({
  height: "32px",
  minWidth: "64px",
  backgroundColor: theme.palette.primary.light,
  borderRadius: "16px",
  "& .MuiOutlinedInput-notchedOutline": {
    border: "none",
  },
  "& .MuiSelect-select": {
    paddingLeft: theme.spacing(1.5),
    paddingRight: theme.spacing(1.5),
  },
  [theme.breakpoints.down("sm")]: {
    height: "28px", // Slightly smaller on mobile
    minWidth: "56px",
    "& .MuiSelect-select": {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      fontSize: "0.875rem",
    },
  },
}));

const StyledTableBody = styled(TableBody)(({ theme, haspagination }) => ({
  "& tr:last-child td": {
    borderBottom: haspagination === "true" ? undefined : "none",
  },
}));

const HsTable = ({
  columns,
  data,
  totalCount,
  page,
  rowsPerPage,
  loading,
  error,
  onPageChange,
  onRowsPerPageChange,
  onRowClick,
  renderDesktopRow,
  renderMobileRow,
  showPagination = true,
  rowsPerPageOptions = [10, 25, 50],
  selectable = false,
  selectedRows = [],
  onSelectRow,
  onSelectAllRows,
  tableId,
  emptyMessage,
  disableRowHover = false,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleChangePage = (newPage) => {
    onPageChange(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    onRowsPerPageChange(parseInt(event.target.value, 10));
  };

  const handleSelectAllClick = (event) => {
    if (onSelectAllRows) {
      onSelectAllRows(event.target.checked, tableId);
    }
  };

  const handleRowClick = (row) => {
    if (onRowClick) {
      onRowClick(row);
    }
  };

  const handleSelectRow = (event, row) => {
    event.stopPropagation();
    if (onSelectRow) {
      onSelectRow(row, tableId);
    }
  };

  const isSelected = (id) =>
    Array.isArray(selectedRows) &&
    selectedRows.some((item) => item.id === id && item.tableId === tableId);

  const renderTableContent = () => {
    if (loading) {
      return (
        <TableRow>
          <TableCell colSpan={columns.length} align="center">
            <CircularProgress />
          </TableCell>
        </TableRow>
      );
    }

    if (error) {
      const errorMessage =
        error?.message || error?.toString() || "An error occurred";
      return (
        <TableRow>
          <TableCell colSpan={columns.length} align="center">
            {errorMessage}
          </TableCell>
        </TableRow>
      );
    }

    if (data.length === 0) {
      return (
        <TableRow>
          <TableCell colSpan={columns.length} align="center">
            {emptyMessage || "No Data Available"}
          </TableCell>
        </TableRow>
      );
    }

    return data.map((row, index) => {
      const isItemSelected = isSelected(row.id);

      if (isMobile && renderMobileRow) {
        return (
          <TableRow
            hover={!disableRowHover}
            role="checkbox"
            aria-checked={isItemSelected}
            tabIndex={-1}
            key={row.id || index}
            selected={isItemSelected}
            onClick={() => handleRowClick(row)}
            sx={
              disableRowHover
                ? {
                    "&:hover": {
                      backgroundColor: "inherit !important",
                      cursor: "default",
                    },
                    "&.Mui-selected": {
                      backgroundColor: "inherit !important",
                    },
                  }
                : undefined
            }
          >
            {selectable && (
              <TableCell padding="checkbox" className="checkbox-cell">
                <Checkbox
                  checked={isItemSelected}
                  onChange={(event) => handleSelectRow(event, row)}
                  onClick={(event) => event.stopPropagation()}
                />
              </TableCell>
            )}
            <TableCell>{renderMobileRow(row)}</TableCell>
          </TableRow>
        );
      }

      return (
        <TableRow
          hover={!disableRowHover}
          role="checkbox"
          aria-checked={isItemSelected}
          tabIndex={-1}
          key={row.id || index}
          selected={isItemSelected}
          onClick={() => handleRowClick(row)}
          sx={
            disableRowHover
              ? {
                  "&:hover": {
                    backgroundColor: "inherit !important",
                    cursor: "default",
                  },
                  "&.Mui-selected": {
                    backgroundColor: "inherit !important",
                  },
                }
              : undefined
          }
        >
          {selectable && (
            <TableCell padding="checkbox" className="checkbox-cell">
              <Checkbox
                checked={isItemSelected}
                onChange={(event) => handleSelectRow(event, row)}
                onClick={(event) => event.stopPropagation()}
              />
            </TableCell>
          )}
          {renderDesktopRow
            ? renderDesktopRow(row, columns)
            : columns.map((column) => (
                <TableCell key={column.id}>{row[column.id]}</TableCell>
              ))}
        </TableRow>
      );
    });
  };

  const renderPagination = () => {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          alignItems: { xs: "stretch", sm: "center" },
          gap: { xs: 2, sm: 0 },
          p: 2,
        }}
      >
        {/* Row selector group */}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: { xs: "space-between", sm: "flex-start" },
            flex: { sm: 1 },
          }}
        >
          <Typography
            variant="body2"
            sx={{
              mr: { xs: 1, sm: 2 },
              color: "text.secondary",
              whiteSpace: "nowrap",
            }}
          >
            Rows per page:
          </Typography>
          <PageControlSelect
            value={rowsPerPage}
            onChange={handleChangeRowsPerPage}
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
              },
              transformOrigin: {
                vertical: "top",
                horizontal: "left",
              },
            }}
          >
            {rowsPerPageOptions.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </PageControlSelect>
        </Box>

        {/* Navigation group */}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: { xs: "space-between", sm: "flex-end" },
          }}
        >
          <Typography
            variant="body2"
            sx={{
              mx: { xs: 0, sm: 4 },
              color: "text.secondary",
              whiteSpace: "nowrap",
            }}
          >
            {page * rowsPerPage + 1}-
            {Math.min((page + 1) * rowsPerPage, totalCount)} of {totalCount}
          </Typography>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <IconButton
              onClick={() => handleChangePage(page - 1)}
              disabled={page === 0}
              size={isMobile ? "small" : "medium"}
            >
              <KeyboardArrowLeftIcon />
            </IconButton>
            <IconButton
              onClick={() => handleChangePage(page + 1)}
              disabled={page >= Math.ceil(totalCount / rowsPerPage) - 1}
              size={isMobile ? "small" : "medium"}
            >
              <KeyboardArrowRightIcon />
            </IconButton>
          </Box>
        </Box>
      </Box>
    );
  };

  return (
    <StyledPaper elevation={1}>
      <StyledTableContainer>
        <Table>
          {!isMobile && (
            <TableHead>
              <TableRow>
                {selectable && (
                  <TableCell padding="checkbox" className="checkbox-cell">
                    <Checkbox
                      indeterminate={
                        selectedRows.filter((item) => item.tableId === tableId)
                          .length > 0 &&
                        selectedRows.filter((item) => item.tableId === tableId)
                          .length < data.length
                      }
                      checked={
                        data.length > 0 &&
                        selectedRows.filter((item) => item.tableId === tableId)
                          .length === data.length
                      }
                      onChange={handleSelectAllClick}
                    />
                  </TableCell>
                )}
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    style={{ width: column.width }}
                    padding="normal"
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
          )}
          <StyledTableBody haspagination={showPagination.toString()}>
            {renderTableContent()}
          </StyledTableBody>
        </Table>
      </StyledTableContainer>
      {showPagination && renderPagination()}
    </StyledPaper>
  );
};

HsTable.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      width: PropTypes.string,
    })
  ).isRequired,
  data: PropTypes.array.isRequired,
  totalCount: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  loading: PropTypes.bool,
  error: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      message: PropTypes.string,
    }),
  ]),
  onPageChange: PropTypes.func.isRequired,
  onRowsPerPageChange: PropTypes.func.isRequired,
  onRowClick: PropTypes.func,
  renderDesktopRow: PropTypes.func,
  renderMobileRow: PropTypes.func,
  showPagination: PropTypes.bool,
  rowsPerPageOptions: PropTypes.arrayOf(PropTypes.number),
  selectable: PropTypes.bool,
  selectedRows: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      tableId: PropTypes.string.isRequired,
    })
  ),
  onSelectRow: PropTypes.func,
  onSelectAllRows: PropTypes.func,
  tableId: PropTypes.string,
  emptyMessage: PropTypes.string,
  disableRowHover: PropTypes.bool,
};

export default HsTable;

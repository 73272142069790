import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { useCallback, useState } from "react";

const StyledDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialog-paper": {
    borderRadius: "16px",
    boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.15)",
  },
}));

const StyledDialogTitle = styled(DialogTitle)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.light,
  color: theme.palette.text.primary,
  padding: theme.spacing(2),
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  borderBottom: `1px solid ${theme.palette.divider}`,
}));

const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
  padding: theme.spacing(3),
  paddingBottom: theme.spacing(3),
  backgroundColor: theme.palette.background.default,
  borderBottom: `1px solid ${theme.palette.divider}`,
}));

const StyledDialogActions = styled(DialogActions)(({ theme }) => ({
  padding: theme.spacing(2, 3),
  borderTop: `1px solid ${theme.palette.divider}`,
  backgroundColor: theme.palette.background.paper,
}));

const ConfirmationDialog = ({
  open,
  title,
  message,
  confirmText = "Confirm",
  cancelText = "Cancel",
  onConfirm,
  onCancel,
  requireConfirmationText,
  confirmButtonProps = {},
  caseSensitive = true,
}) => {
  const [confirmationInput, setConfirmationInput] = useState("");

  const isConfirmationValid = useCallback(() => {
    if (!requireConfirmationText) return true;
    if (!confirmationInput) return false;

    return caseSensitive
      ? confirmationInput === requireConfirmationText
      : confirmationInput.toUpperCase() ===
          requireConfirmationText.toUpperCase();
  }, [confirmationInput, requireConfirmationText, caseSensitive]);

  const handleConfirm = () => {
    if (requireConfirmationText && !isConfirmationValid()) {
      return;
    }
    onConfirm();
    setConfirmationInput("");
  };

  const handleCancel = () => {
    onCancel();
    setConfirmationInput("");
  };

  const handleKeyPress = (event) => {
    if (
      event.key === "Enter" &&
      (!requireConfirmationText ||
        confirmationInput === requireConfirmationText)
    ) {
      handleConfirm();
    }
  };

  return (
    <StyledDialog open={open} onClose={handleCancel} maxWidth="sm" fullWidth>
      <StyledDialogTitle>
        <Typography variant="h6" component="div" sx={{ fontWeight: 600 }}>
          {title}
        </Typography>
        <IconButton
          aria-label="close"
          onClick={handleCancel}
          size="small"
          sx={{
            color: "text.primary",
            "&:hover": {
              backgroundColor: "rgba(0, 0, 0, 0.04)",
            },
          }}
        >
          <CloseIcon />
        </IconButton>
      </StyledDialogTitle>

      <StyledDialogContent>
        <Box
          sx={{
            mb: requireConfirmationText ? 3 : 2,
            pt: 2,
          }}
        >
          {typeof message === "string" ? (
            <Typography variant="body1">{message}</Typography>
          ) : (
            message
          )}
        </Box>
        {requireConfirmationText && (
          <TextField
            fullWidth
            value={confirmationInput}
            onChange={(e) => setConfirmationInput(e.target.value)}
            placeholder="Type to confirm"
            size="small"
            onKeyPress={handleKeyPress}
            autoFocus
            sx={{
              "& .MuiOutlinedInput-root": {
                borderRadius: 2,
                backgroundColor: "background.paper",
              },
            }}
          />
        )}
      </StyledDialogContent>

      <StyledDialogActions>
        <Button
          onClick={handleCancel}
          variant="outlined"
          sx={{
            borderRadius: "50px",
            px: 3,
            color: "text.primary",
            borderColor: "divider",
            "&:hover": {
              borderColor: "text.primary",
              backgroundColor: "action.hover",
            },
          }}
        >
          {cancelText}
        </Button>
        <Button
          onClick={handleConfirm}
          variant="contained"
          disabled={requireConfirmationText && !isConfirmationValid()}
          sx={{
            borderRadius: "50px",
            px: 3,
            backgroundColor:
              confirmButtonProps.color === "error"
                ? "error.main"
                : "primary.main",
            color: "primary.contrastText",
            "&:hover": {
              backgroundColor:
                confirmButtonProps.color === "error"
                  ? "error.dark"
                  : "primary.dark",
            },
          }}
          {...confirmButtonProps}
        >
          {confirmText}
        </Button>
      </StyledDialogActions>
    </StyledDialog>
  );
};

export default ConfirmationDialog;

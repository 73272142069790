import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  Link,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import React, { memo, useCallback, useState } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { registerInitial } from "../../api/authApi";
import HsGoogleButton from "../../components/HsGoogleButton";
import InviteRegistration from "../../components/InviteRegistration";
import { CONFIG } from "../../config/config";
import { useReportContext } from "../../contexts/report-context";
import { useLogin } from "../../hooks/useLogin";

const INVITE_ONLY = CONFIG.REACT_APP_INVITE_ONLY === "true" || false;

// Create a separate form component
const RegistrationForm = memo(
  ({ onSubmit, isLoading, requireInvite, onBack }) => {
    const [formData, setFormData] = useState({
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      confirmPassword: "",
      inviteCode: "",
    });
    const [errors, setErrors] = useState({});

    const handleInputChange = useCallback((e) => {
      const { name, value } = e.target;
      setFormData((prev) => ({
        ...prev,
        [name]: value,
      }));
    }, []);

    const validateEmail = (email) => {
      const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return regex.test(email);
    };

    const validateForm = useCallback(
      (data) => {
        let tempErrors = {};
        if (!data.firstName.trim())
          tempErrors.firstName = "First name is required";
        if (!data.lastName.trim())
          tempErrors.lastName = "Last name is required";
        if (!validateEmail(data.email))
          tempErrors.email = "Invalid email format";
        if (data.password !== data.confirmPassword) {
          tempErrors.confirmPassword = "Passwords do not match";
        }
        if (data.password.length < 8) {
          tempErrors.password = "Password must be at least 8 characters";
        }
        if (requireInvite && !data.inviteCode.trim()) {
          tempErrors.inviteCode = "Invite code is required";
        }
        setErrors(tempErrors);
        return tempErrors;
      },
      [requireInvite]
    );

    const handleSubmit = useCallback(
      (e) => {
        e.preventDefault();
        const tempErrors = validateForm(formData);
        if (Object.keys(tempErrors).length === 0) {
          onSubmit(formData);
        }
      },
      [formData, onSubmit, validateForm]
    );

    return (
      <form noValidate onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          {requireInvite && (
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                fullWidth
                label="Invite Code"
                name="inviteCode"
                value={formData.inviteCode}
                onChange={handleInputChange}
                error={!!errors.inviteCode}
                helperText={errors.inviteCode}
                required
              />
            </Grid>
          )}
          <Grid item xs={12} sm={6}>
            <TextField
              variant="outlined"
              fullWidth
              label="First Name"
              name="firstName"
              value={formData.firstName}
              onChange={handleInputChange}
              error={!!errors.firstName}
              helperText={errors.firstName}
              autoComplete="given-name"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              variant="outlined"
              fullWidth
              label="Last Name"
              name="lastName"
              value={formData.lastName}
              onChange={handleInputChange}
              error={!!errors.lastName}
              helperText={errors.lastName}
              autoComplete="family-name"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              fullWidth
              label="Email"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
              error={!!errors.email}
              helperText={errors.email}
              autoComplete="email"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              fullWidth
              label="Password"
              type="password"
              name="password"
              value={formData.password}
              onChange={handleInputChange}
              error={!!errors.password}
              helperText={errors.password}
              autoComplete="new-password"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              fullWidth
              label="Confirm Password"
              type="password"
              name="confirmPassword"
              value={formData.confirmPassword}
              onChange={handleInputChange}
              error={!!errors.confirmPassword}
              helperText={errors.confirmPassword}
              autoComplete="new-password"
            />
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ display: "flex", gap: 2 }}>
              <Button variant="outlined" fullWidth onClick={onBack}>
                Back
              </Button>
              <Button
                type="submit"
                variant="darkRounded"
                fullWidth
                disabled={isLoading}
              >
                {isLoading ? (
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  "Create Account"
                )}
              </Button>
            </Box>
          </Grid>
        </Grid>
      </form>
    );
  }
);

const RegistrationPage = () => {
  const navigate = useNavigate();
  const [registerWithEmail, setRegisterWithEmail] = useState(false);
  const { setMessage } = useReportContext();
  const { isLoading } = useLogin();

  const handleRegister = useCallback(
    async (formData) => {
      try {
        const result = await registerInitial({
          username: formData.email,
          email: formData.email,
          password: formData.password,
          firstName: formData.firstName,
          lastName: formData.lastName,
          inviteCode: formData.inviteCode,
        });

        switch (result.status) {
          case "USER_CREATED_UNVERIFIED":
            navigate("/login", {
              state: {
                verificationRequired: true,
                userEmail: formData.email,
                newRegistration: true,
              },
            });
            break;
          case "USER_EXISTS_UNVERIFIED":
            navigate("/login", {
              state: {
                verificationRequired: true,
                userEmail: formData.email,
              },
            });
            break;
          case "USER_EXISTS_VERIFIED":
            navigate("/login", {
              state: {
                message: "Account already exists. Please sign in.",
              },
            });
            break;
          case "INVITE_INVALID":
            setMessage({
              text: "Invalid invite code",
              severity: "error",
              flag: true,
            });
            break;
          default:
            setMessage({
              text: "An unexpected error occurred. Please try again.",
              severity: "error",
              flag: true,
            });
        }
      } catch (error) {
        const errorMessage =
          error?.response?.data?.status === "INVITE_INVALID"
            ? "Invalid invite code"
            : error?.response?.data?.message ||
              error?.message ||
              "An unexpected error occurred";

        setMessage({
          text: errorMessage,
          severity: "error",
          flag: true,
        });
      }
    },
    [navigate, setMessage]
  );

  const content = INVITE_ONLY ? (
    <InviteRegistration />
  ) : (
    <Paper
      elevation={4}
      sx={{
        p: 4,
        width: "100%",
        borderRadius: 2,
      }}
    >
      <Typography variant="h4" align="center" gutterBottom>
        Create Account
      </Typography>
      <Typography
        variant="body1"
        align="center"
        color="textSecondary"
        sx={{ mb: 4 }}
      >
        Join Homeschool Planner to start organizing your homeschool journey
      </Typography>

      {!registerWithEmail ? (
        <>
          <HsGoogleButton mode="register" fullWidth />
          <Divider sx={{ my: 3 }}>
            <Typography variant="body2" color="textSecondary">
              OR
            </Typography>
          </Divider>
          <Button
            variant="darkRounded"
            onClick={() => setRegisterWithEmail(true)}
            fullWidth
          >
            Sign up with Email
          </Button>
        </>
      ) : (
        <RegistrationForm
          onSubmit={handleRegister}
          isLoading={isLoading}
          requireInvite={INVITE_ONLY}
          onBack={() => setRegisterWithEmail(false)}
        />
      )}

      <Box sx={{ mt: 3, textAlign: "center" }}>
        <Typography variant="body2">
          Already have an account?{" "}
          <Link
            component={RouterLink}
            to="/login"
            color="primary"
            sx={{ fontWeight: 500 }}
          >
            Sign in here
          </Link>
        </Typography>
      </Box>
    </Paper>
  );

  return (
    <Box
      sx={{
        position: "relative",
        height: "calc(100vh - 64px)",
        overflow: "auto",
      }}
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "100%",
          maxWidth: "400px",
          p: 2,
        }}
      >
        {content}
      </Box>
    </Box>
  );
};

export default RegistrationPage;

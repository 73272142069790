import apiUrl from "../config/config.js";
import { api } from "./api.js";

export const REPORT_TYPE = {
  ATTENDANCE_BY_EVENT: "ATTENDANCE_BY_EVENT",
  ATTENDANCE_BY_DAY: "ATTENDANCE_BY_DAY",
  PLANNER: "PLANNER",
  PROGRESS_REPORT: "PROGRESS_REPORT",
  TRANSCRIPT: "TRANSCRIPT",
};

export const REPORT_FORMAT = {
  PDF: "PDF",
  CSV: "CSV",
  XLSX: "XLSX",
};

export const REPORT_STATUS = {
  QUEUED: "QUEUED",
  PROCESSING: "PROCESSING",
  COMPLETED: "COMPLETED",
  FAILED: "FAILED",
};

export const generateReport = async (request, signal) => {
  const response = await api.post(`${apiUrl}/api/v1/report/generate`, request, {
    signal,
  });
  return response.data;
};

export const getReportStatus = async (reportId, accountId, signal) => {
  const response = await api.get(
    `${apiUrl}/api/v1/report/status/${reportId}?account_id=${accountId}`,
    { signal }
  );
  return response.data;
};

export const fetchReports = async (
  accountId,
  { page = 0, size = 10, type, format, studentId, termId } = {},
  signal
) => {
  const params = new URLSearchParams({
    account_id: accountId,
    page,
    size,
    ...(type && { type }),
    ...(format && { format }),
    ...(studentId && { studentId }),
    ...(termId && { termId }),
  });

  const response = await api.get(`/api/v1/report?${params}`, { signal });
  return response.data;
};

export const downloadReport = async (url, filename) => {
  try {
    const response = await fetch(url);
    if (!response.ok) throw new Error("Download failed");

    const blob = await response.blob();
    const downloadUrl = window.URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = downloadUrl;
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(downloadUrl);
  } catch (error) {
    throw new Error(`Failed to download report: ${error.message}`);
  }
};

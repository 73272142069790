import { Box, Card, CardContent, Typography, useTheme } from "@mui/material";
import React from "react";

const PlanCard = ({ plan, isSelected, onSelect }) => {
  const theme = useTheme();

  return (
    <Card
      onClick={() => onSelect(plan.type)}
      sx={{
        backgroundColor: isSelected
          ? theme.palette.secondary.light
          : theme.palette.background.default,
        border: `2px solid ${
          isSelected ? theme.palette.secondary.main : "transparent"
        }`,
        borderRadius: "16px",
        cursor: "pointer",
        position: "relative",
        minWidth: "250px",
        minHeight: "250px",
        width: "48%",
        display: "flex",
        flexDirection: "column",
        transition: "all 0.3s ease",
        "&:hover": {
          boxShadow: 3,
          transform: "translateY(-4px)",
        },
      }}
    >
      <CardContent
        sx={{
          flexGrow: 1,
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          p: 3,
          marginLeft: 6,
        }}
      >
        <Box>
          <Typography variant="h5" fontWeight="bold" mb={1}>
            {plan.title}
          </Typography>
          {plan.type === "FAMILY" && (
            <Typography
              variant="caption"
              sx={{
                display: "inline-block",
                backgroundColor: theme.palette.yellow.main,
                color: theme.palette.common.black,
                padding: "4px 12px",
                borderRadius: "20px",
                fontWeight: "bold",
                fontSize: "0.75rem",
                mb: 2,
                lineHeight: 1.5,
              }}
            >
              Best Value
            </Typography>
          )}
          <Typography variant="body1" color="text.secondary" mb={3}>
            {plan.description}
          </Typography>
        </Box>
        <Box>
          <Typography variant="h5" fontWeight="bold" mb={1}>
            ${plan.price}
            <Typography component="span" variant="body2" color="text.secondary">
              /year
            </Typography>
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
};

export default PlanCard;

import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  Link,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import React, { useCallback, useState } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { registerInitial } from "../api/authApi";
import { useReportContext } from "../contexts/report-context";
import HsGoogleButton from "./HsGoogleButton";

const InviteRegistration = ({ modal = false }) => {
  const navigate = useNavigate();
  const [registerWithEmail, setRegisterWithEmail] = useState(false);
  const [inviteCode, setInviteCode] = useState("");
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: "",
  });
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const { setMessage } = useReportContext();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "inviteCode") {
      setInviteCode(value);
    } else {
      setFormData((prev) => ({ ...prev, [name]: value }));
    }
  };

  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const validateForm = useCallback(() => {
    let tempErrors = {};
    if (!formData.firstName.trim())
      tempErrors.firstName = "First name is required";
    if (!formData.lastName.trim())
      tempErrors.lastName = "Last name is required";
    if (!validateEmail(formData.email))
      tempErrors.email = "Invalid email format";
    if (formData.password !== formData.confirmPassword) {
      tempErrors.confirmPassword = "Passwords do not match";
    }
    if (formData.password.length < 8) {
      tempErrors.password = "Password must be at least 8 characters";
    }
    if (!inviteCode.trim()) {
      tempErrors.inviteCode = "Invite code is required";
    }
    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  }, [formData, inviteCode]);

  const handleRegister = async (e) => {
    e?.preventDefault();
    if (!validateForm()) return;

    setIsLoading(true);
    try {
      const result = await registerInitial({
        username: formData.email,
        email: formData.email,
        password: formData.password,
        firstName: formData.firstName,
        lastName: formData.lastName,
        inviteCode: inviteCode,
      });

      switch (result.status) {
        case "USER_CREATED_UNVERIFIED":
          navigate("/login", {
            state: {
              verificationRequired: true,
              userEmail: formData.email,
              newRegistration: true,
            },
          });
          break;
        case "USER_EXISTS_UNVERIFIED":
          navigate("/login", {
            state: {
              verificationRequired: true,
              userEmail: formData.email,
            },
          });
          break;
        case "USER_EXISTS_VERIFIED":
          navigate("/login", {
            state: {
              message: "Account already exists. Please sign in.",
            },
          });
          break;
        case "INVITE_INVALID":
          setMessage({
            text: "Invalid invite code",
            severity: "error",
            flag: true,
          });
          break;
        default:
          setMessage({
            text: "An unexpected error occurred. Please try again.",
            severity: "error",
            flag: true,
          });
      }
    } catch (error) {
      const errorMessage =
        error?.response?.data?.status === "INVITE_INVALID"
          ? "Invalid invite code"
          : error?.response?.data?.message ||
            error?.message ||
            "An unexpected error occurred";

      setMessage({
        text: errorMessage,
        severity: "error",
        flag: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const content = (
    <>
      <Typography variant="h4" gutterBottom align="center">
        Create Account
      </Typography>
      <Typography
        variant="subtitle1"
        gutterBottom
        align="center"
        color="textSecondary"
      >
        (currently invite only)
      </Typography>

      {!registerWithEmail ? (
        <>
          <TextField
            label="Invite Code"
            type="text"
            name="inviteCode"
            value={inviteCode}
            onChange={handleInputChange}
            error={!!errors.inviteCode}
            helperText={errors.inviteCode}
            margin="normal"
            fullWidth
            required
            autoFocus
          />
          <Box sx={{ mt: 3, mb: 2 }}>
            <HsGoogleButton
              mode="register"
              inviteCode={inviteCode}
              fullWidth
              disabled={!inviteCode.trim()}
            />
          </Box>
          <Divider sx={{ my: 2 }}>
            <Typography variant="body2" color="textSecondary">
              OR
            </Typography>
          </Divider>
          <Button
            variant="darkRounded"
            onClick={() => setRegisterWithEmail(true)}
            fullWidth
            disabled={!inviteCode.trim()}
          >
            Sign up with Email
          </Button>
        </>
      ) : (
        <form onSubmit={handleRegister}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                label="Invite Code"
                type="text"
                name="inviteCode"
                value={inviteCode}
                onChange={handleInputChange}
                error={!!errors.inviteCode}
                helperText={errors.inviteCode}
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="First Name"
                name="firstName"
                value={formData.firstName}
                onChange={handleInputChange}
                error={!!errors.firstName}
                helperText={errors.firstName}
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Last Name"
                name="lastName"
                value={formData.lastName}
                onChange={handleInputChange}
                error={!!errors.lastName}
                helperText={errors.lastName}
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Email"
                name="email"
                type="email"
                value={formData.email}
                onChange={handleInputChange}
                error={!!errors.email}
                helperText={errors.email}
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Password"
                name="password"
                type="password"
                value={formData.password}
                onChange={handleInputChange}
                error={!!errors.password}
                helperText={errors.password}
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Confirm Password"
                name="confirmPassword"
                type="password"
                value={formData.confirmPassword}
                onChange={handleInputChange}
                error={!!errors.confirmPassword}
                helperText={errors.confirmPassword}
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={12}>
              <Box sx={{ display: "flex", gap: 2 }}>
                <Button
                  variant="outlined"
                  fullWidth
                  onClick={() => setRegisterWithEmail(false)}
                >
                  Back
                </Button>
                <Button
                  type="submit"
                  variant="darkRounded"
                  fullWidth
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <CircularProgress size={24} color="inherit" />
                  ) : (
                    "Create Account"
                  )}
                </Button>
              </Box>
            </Grid>
          </Grid>
        </form>
      )}

      <Box sx={{ mt: 3, textAlign: "center" }}>
        <Typography variant="body2">
          Already have an account?{" "}
          <Link
            component={RouterLink}
            to="/login"
            color="primary"
            sx={{ fontWeight: 500 }}
          >
            Sign in here
          </Link>
        </Typography>
      </Box>
    </>
  );

  if (modal) {
    return content;
  }

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
        p: 2,
      }}
    >
      <Paper
        elevation={4}
        sx={{
          p: 4,
          maxWidth: 400,
          width: "100%",
          borderRadius: 2,
        }}
      >
        {content}
      </Paper>
    </Box>
  );
};

export default InviteRegistration;

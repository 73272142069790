import React, { createContext, useContext, useEffect, useState } from "react";

const NetworkContext = createContext({
  isOnline: true,
  isAPIAvailable: true,
});

export const NetworkProvider = ({ children }) => {
  const [isOnline, setIsOnline] = useState(window.navigator.onLine);
  const [isAPIAvailable, setIsAPIAvailable] = useState(true);

  useEffect(() => {
    const handleOnline = () => setIsOnline(true);
    const handleOffline = () => setIsOnline(false);

    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, []);

  return (
    <NetworkContext.Provider
      value={{ isOnline, isAPIAvailable, setIsAPIAvailable }}
    >
      {children}
    </NetworkContext.Provider>
  );
};

export const useNetwork = () => useContext(NetworkContext);

// AccountSelectorModal.js
import { Button, Divider, MenuItem, Select, Stack } from "@mui/material";
import React, { useState } from "react";
import HsModal from "../common/HsModal";

const AccountSelectorModal = ({
  authResponse,
  isOpen,
  handleClose,
  handleConfirmAccount,
}) => {
  const [selectedAccount, setSelectedAccount] = useState("");

  const handleAccountSelect = (e) => {
    setSelectedAccount(e.target.value);
  };

  const handleContinue = () => {
    handleConfirmAccount(selectedAccount);
    handleClose();
  };

  return (
    <HsModal
      open={isOpen}
      handleClose={handleClose}
      title="Select an Account"
      dismissable={false}
    >
      <Stack spacing={3}>
        <Select
          value={selectedAccount ?? ""}
          onChange={handleAccountSelect}
          displayEmpty
          fullWidth
          size="small"
          required
        >
          <MenuItem value="" disabled>
            Select an account
          </MenuItem>
          {authResponse?.loggedInUserAccounts?.length > 1 &&
            authResponse.loggedInUserAccounts.map((account) => (
              <MenuItem key={account.id} value={account.id}>
                {account.name}
              </MenuItem>
            ))}
        </Select>

        <Divider />
        <Stack direction="row" spacing={2} justifyContent="flex-end">
          <Button
            variant="contained"
            onClick={handleContinue}
            disabled={!selectedAccount}
          >
            Continue
          </Button>
        </Stack>
      </Stack>
    </HsModal>
  );
};

export default AccountSelectorModal;

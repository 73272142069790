import {
  Box,
  Button,
  CircularProgress,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { loadStripe } from "@stripe/stripe-js";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { initiateCheckout } from "../../api/checkoutApi";
import { CONFIG } from "../../config/config";
import { useAsyncOperation } from "../../hooks/useAsyncOperation";
import ShoppingCart from "../ShoppingCart";
import ShippingInformation from "./ShippingInformation";
import ShippingOptions from "./ShippingOptions";
import SummaryStep from "./SummaryStep";

const steps = ["Cart", "Shipping Information", "Shipping Options", "Summary"];

// Initialize Stripe outside of the component
const stripePromise = loadStripe(CONFIG.STRIPE_PUBLISHABLE_KEY);

const Checkout = () => {
  const theme = useTheme();
  const [activeStep, setActiveStep] = useState(1);
  const navigate = useNavigate();
  const { shippingAddress, shippingOption } = useSelector(
    (state) => state.checkout
  );
  const { items } = useSelector((state) => state.cart);
  const { execute: executeCheckout, loading: checkoutLoading } =
    useAsyncOperation(initiateCheckout);
  const { selectedAccountId } = useSelector((state) => state.auth);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    if (activeStep === 1) {
      navigate("/cart");
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
  };

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return <ShoppingCart />;
      case 1:
        return <ShippingInformation />;
      case 2:
        return <ShippingOptions />;
      case 3:
        return <SummaryStep />;
      default:
        return "Unknown step";
    }
  };

  const isNextButtonDisabled = () => {
    if (activeStep === 1) {
      return !shippingAddress;
    }
    if (activeStep === 2) {
      return !shippingOption;
    }
    return false;
  };

  const handleCheckoutComplete = async () => {
    try {
      const stripe = await stripePromise;
      if (!stripe) {
        throw new Error("Failed to load Stripe");
      }

      const formattedShippingAddress = {
        city: shippingAddress.city,
        countryCode: shippingAddress.countryCode,
        isBusiness: shippingAddress.isBusiness || false,
        name: shippingAddress.name || "Customer",
        phoneNumber: shippingAddress.phoneNumber,
        postcode: shippingAddress.postcode,
        stateCode: shippingAddress.stateCode,
        street1: shippingAddress.street1,
        street2: shippingAddress.street2 || "",
      };

      const request = {
        accountId: selectedAccountId,
        currency: "USD",
        shippingAddress: formattedShippingAddress,
        shippingMethod: shippingOption,
      };

      const response = await executeCheckout(request);

      if (response.sessionId) {
        // Use Stripe's redirectToCheckout instead of window.location
        const result = await stripe.redirectToCheckout({
          sessionId: response.sessionId,
        });

        if (result.error) {
          console.error("Stripe redirect error:", result.error);
          // Handle the error, maybe show a message to the user
        }
      }
    } catch (error) {
      console.error("Checkout failed:", error);
    }
  };

  // Add this effect to save checkout data to localStorage
  useEffect(() => {
    if (shippingAddress || shippingOption) {
      localStorage.setItem(
        "checkoutData",
        JSON.stringify({
          shippingAddress,
          shippingOption,
        })
      );
    }
  }, [shippingAddress, shippingOption]);

  // Clear checkout data when component unmounts
  useEffect(() => {
    return () => {
      localStorage.removeItem("checkoutData");
    };
  }, []);

  return (
    <Box sx={{ width: "100%" }}>
      <Stepper activeStep={activeStep}>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <Box sx={{ mt: 2, mb: 1 }}>
        {activeStep === steps.length ? (
          <Typography>Order completed</Typography>
        ) : (
          <>
            {getStepContent(activeStep)}
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                pt: 2,
                justifyContent: "space-between",
              }}
            >
              <Button color="inherit" onClick={handleBack}>
                Back
              </Button>
              {activeStep < steps.length - 1 && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleNext}
                  disabled={isNextButtonDisabled()}
                >
                  Next
                </Button>
              )}
              {activeStep === steps.length - 1 && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleCheckoutComplete}
                  disabled={checkoutLoading}
                  sx={{
                    minWidth: 200,
                    "&.Mui-disabled": {
                      backgroundColor: theme.palette.primary.light,
                      color: theme.palette.primary.contrastText,
                    },
                  }}
                >
                  {checkoutLoading ? (
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <CircularProgress
                        size={20}
                        sx={{
                          color: theme.palette.primary.contrastText,
                          mr: 1,
                        }}
                      />
                      Processing...
                    </Box>
                  ) : (
                    "Complete Checkout"
                  )}
                </Button>
              )}
            </Box>
          </>
        )}
      </Box>
    </Box>
  );
};

export default Checkout;

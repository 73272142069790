import { Button } from "@mui/material";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useReportContext } from "../contexts/report-context";

const RESTRICTION_MESSAGES = {
  STUDENT_LIMIT: "You've reached the maximum number of students for your plan.",
  LESSON_PLAN_LIMIT:
    "You've reached the maximum number of lesson plans for your plan.",
  TERM_LIMIT: "You've reached the maximum number of terms for your plan.",
};

export const usePlanRestriction = () => {
  const navigate = useNavigate();
  const { setMessage } = useReportContext();

  const handlePlanRestrictionError = useCallback(
    (error) => {
      if (error.response?.status === 403) {
        const errorData = error.response.data;

        if (errorData.error === "Plan Restriction") {
          const defaultMessage =
            RESTRICTION_MESSAGES[errorData.restrictionType] ||
            errorData.message;

          setMessage({
            text: (
              <div
                style={{ display: "flex", alignItems: "center", gap: "8px" }}
              >
                <span>{defaultMessage}</span>
                <Button
                  variant="outlined"
                  size="small"
                  onClick={() => navigate("/subscription")}
                  sx={{ ml: 1 }}
                >
                  Upgrade Plan
                </Button>
              </div>
            ),
            severity: "warning",
            flag: true,
            duration: 8000, // Give users more time to read and click
          });
          return true;
        }
      }
      return false;
    },
    [navigate, setMessage]
  );

  return { handlePlanRestrictionError };
};

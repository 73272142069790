// hooks/useAsyncOperation.js
import { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

export const useAsyncOperation = (asyncFunction, options = {}) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const mounted = useRef(true);

  const asyncFunctionRef = useRef(asyncFunction);
  const optionsRef = useRef(options);
  const abortControllerRef = useRef(null);

  useEffect(() => {
    mounted.current = true;
    window.__networkErrorThrown = false;
    asyncFunctionRef.current = asyncFunction;
    optionsRef.current = options;
    return () => {
      mounted.current = false;
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
      window.__networkErrorThrown = false;
    };
  }, []); // this is not to include asyncFunction and options in the dependency array!

  const execute = useCallback(
    async (...args) => {
      if (!mounted.current) return;

      try {
        setLoading(true);
        setError(null);

        // Cancel any in-flight requests
        if (abortControllerRef.current) {
          abortControllerRef.current.abort();
        }

        abortControllerRef.current = new AbortController();
        const { signal } = abortControllerRef.current;

        const result = await asyncFunctionRef.current(...args, signal);
        if (!signal.aborted && mounted.current) {
          setData(result);
          setLoading(false);
          return result;
        }
      } catch (err) {
        if (
          !mounted.current ||
          (abortControllerRef.current &&
            abortControllerRef.current.signal.aborted)
        ) {
          return;
        }

        console.error("AsyncOperation error:", err);
        setError(err);
        setLoading(false);

        // Handle network errors first
        if (err?.code === "ERR_NETWORK" || err?.message === "Network Error") {
          if (!optionsRef.current.suppressNetworkErrors) {
            // Only throw the first network error
            if (!window.__networkErrorThrown) {
              window.__networkErrorThrown = true;
              throw err; // Let error boundary handle network errors
            }
            return;
          }
        }

        if (
          optionsRef.current.handleUnauthorized &&
          err.response?.status === 401
        ) {
          if (optionsRef.current.onUnauthorized) {
            optionsRef.current.onUnauthorized();
          } else {
            navigate("/login");
          }
        }

        if (optionsRef.current.errorHandler) {
          optionsRef.current.errorHandler(err);
        }

        throw err;
      }
    },
    [navigate]
  );

  const abort = useCallback(() => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }
  }, []);

  return { data, loading, error, execute, abort };
};

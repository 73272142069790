import DateRangeIcon from "@mui/icons-material/DateRange";
import ViewWeekIcon from "@mui/icons-material/ViewWeek";
import {
  MenuItem,
  Select,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import React from "react";

const statusMenuItems = [
  { name: "Pending", value: "PENDING" },
  { name: "Completed", value: "COMPLETED" },
  { name: "Skipped", value: "SKIPPED" },
  { name: "All", value: "ALL" },
];

const actionsMenuItems = [
  { name: "Complete", value: "COMPLETE" },
  { name: "Skip", value: "SKIP" },
  { name: "Postpone", value: "POSTPONE" },
];

const FilterControls = ({
  students = [],
  selectedStudent = "",
  handleSelectStudent,
  selectedStatus,
  handleSelectStatus,
  selectedRows,
  handleBulkAction,
  viewMode,
  onViewModeChange,
}) => {
  return (
    <Stack
      direction={{ xs: "column", sm: "row" }}
      spacing={2}
      justifyContent="space-between"
      alignItems="stretch"
    >
      <Stack
        direction={{ xs: "column", sm: "row" }}
        spacing={2}
        alignItems="stretch"
      >
        <ToggleButtonGroup
          value={viewMode}
          exclusive
          onChange={onViewModeChange}
          aria-label="view mode"
          sx={{
            "& .MuiToggleButton-root": {
              whiteSpace: "nowrap",
              minWidth: "fit-content",
              display: "flex",
              alignItems: "center",
              gap: 1,
            },
            "& .MuiToggleButton-root.Mui-selected": {
              backgroundColor: "primary.lighter",
              color: "primary.main",
              "&:hover": {
                backgroundColor: "primary.lighter",
              },
            },
          }}
        >
          <ToggleButton value="week">
            <ViewWeekIcon /> Weekly
          </ToggleButton>
          <ToggleButton value="term">
            <DateRangeIcon /> Full Term
          </ToggleButton>
        </ToggleButtonGroup>

        <Select
          size="small"
          value={selectedStudent}
          onChange={handleSelectStudent}
          displayEmpty
          renderValue={(selected) =>
            selected
              ? `${students.find((s) => s.id === selected)?.firstName} ${
                  students.find((s) => s.id === selected)?.lastName
                }`
              : "Select Student"
          }
        >
          <MenuItem value="">All</MenuItem>
          {students.map((student) => (
            <MenuItem key={student.id} value={student.id}>
              {student.firstName} {student.lastName}
            </MenuItem>
          ))}
        </Select>
        <Select
          size="small"
          value={selectedStatus}
          onChange={handleSelectStatus}
          displayEmpty
          renderValue={(selected) =>
            statusMenuItems.find((item) => item.value === selected)?.name ||
            "Select Status"
          }
        >
          {statusMenuItems.map((status) => (
            <MenuItem key={status.value} value={status.value}>
              {status.name}
            </MenuItem>
          ))}
        </Select>
      </Stack>

      <Select
        size="small"
        value=""
        onChange={(e) => handleBulkAction(e.target.value)}
        disabled={selectedRows.length === 0}
        displayEmpty
        renderValue={() => "Actions"}
      >
        {actionsMenuItems.map((action) => (
          <MenuItem key={action.value} value={action.value}>
            {action.name}
          </MenuItem>
        ))}
      </Select>
    </Stack>
  );
};

export default FilterControls;

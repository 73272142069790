import {
  Box,
  Button,
  Chip,
  List,
  ListItem,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import React from "react";
import { useNavigate } from "react-router-dom";

const StyledChip = styled(Chip)(({ theme, status }) => ({
  borderRadius: "16px",
  fontWeight: 500,
  padding: theme.spacing(1, 2),
  ...(status === "COMPLETED" && {
    backgroundColor: theme.palette.success.light,
    color: theme.palette.success.dark,
  }),
  ...(status === "PENDING" && {
    backgroundColor: theme.palette.warning.light,
    color: theme.palette.warning.dark,
  }),
  ...(status === "SKIPPED" && {
    backgroundColor: theme.palette.error.light,
    color: theme.palette.error.dark,
  }),
}));

const LessonDetailsTab = ({ event, onStatusUpdate }) => {
  const navigate = useNavigate();
  const theme = useTheme();

  const handleStatusUpdate = (newStatus) => {
    onStatusUpdate({
      ...event,
      checklistStatus: newStatus,
    });
  };

  const navigateToLessonPlan = () => {
    if (event?.classInstanceSummary?.lessonPlanId) {
      navigate(`/lesson-plans/${event.classInstanceSummary.lessonPlanId}`);
    }
  };

  return (
    <Box>
      <Stack spacing={3}>
        {/* Class and Lesson Info */}
        <Box>
          <Typography variant="h6" gutterBottom>
            Class Information
          </Typography>
          <Stack spacing={2}>
            <Typography>
              <strong>Class:</strong>{" "}
              {event?.classInstanceSummary?.name || "N/A"}
            </Typography>
            <Typography>
              <strong>Subject:</strong>{" "}
              {event?.classInstanceSummary?.subject || "N/A"}
            </Typography>
            {event?.classInstanceSummary?.termSummary && (
              <Typography>
                <strong>Term:</strong>{" "}
                {event.classInstanceSummary.termSummary.name}
              </Typography>
            )}
            <Typography>
              <strong>Lesson Number:</strong>{" "}
              {event?.classLessonSummary?.lessonNumber || "N/A"}
            </Typography>
          </Stack>
        </Box>

        {/* Status Section */}
        <Box>
          <Typography variant="h6" gutterBottom>
            Lesson Status
          </Typography>
          <Stack direction="row" spacing={2} alignItems="center">
            <Box
              sx={{
                p: 1,
                borderRadius: 1,
                backgroundColor: theme.palette.background.gray,
              }}
            >
              <Typography variant="subtitle2" sx={{ mr: 1 }}>
                Current Status:
              </Typography>
              <StyledChip
                label={event?.checklistStatus || "PENDING"}
                status={event?.checklistStatus || "PENDING"}
              />
            </Box>
            <Stack direction="row" spacing={1}>
              {event?.checklistStatus !== "COMPLETED" && (
                <Button
                  variant="contained"
                  color="success"
                  onClick={() => handleStatusUpdate("COMPLETED")}
                  sx={{
                    color: "white",
                  }}
                >
                  Mark Complete
                </Button>
              )}
              {event?.checklistStatus !== "SKIPPED" && (
                <Button
                  variant="contained"
                  color="warning"
                  onClick={() => handleStatusUpdate("SKIPPED")}
                  sx={{
                    color: "white",
                  }}
                >
                  Mark Skipped
                </Button>
              )}
            </Stack>
          </Stack>
        </Box>

        {/* Work Items */}
        {event?.classLessonSummary?.workItems?.length > 0 && (
          <Box>
            <Typography variant="h6" gutterBottom>
              Work Items
            </Typography>
            <List>
              {event.classLessonSummary.workItems.map((item, index) => (
                <ListItem key={index}>
                  <ListItemText
                    primary={item.title}
                    secondary={item.description}
                  />
                </ListItem>
              ))}
            </List>
          </Box>
        )}

        {/* Link to Lesson Plan */}
        {event?.classInstanceSummary?.lessonPlanId && (
          <Box>
            <Button variant="outlined" onClick={navigateToLessonPlan}>
              View Full Lesson Plan
            </Button>
          </Box>
        )}
      </Stack>
    </Box>
  );
};

export default LessonDetailsTab;

import AddCircleIcon from "@mui/icons-material/AddCircle";
import {
  Box,
  Checkbox,
  Collapse,
  FormControlLabel,
  IconButton,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import capitalize from "lodash/capitalize";
import React from "react";
import InstructionHour from "./InstructionHour";

const InstructionDay = React.memo(
  ({
    day,
    isSelected,
    value,
    handleSelectInstructionDay,
    handleAddDayInstructionHour,
    handleChangeTime,
    handleRemoveDayInstructionHour,
    errors,
    isMobile,
  }) => {
    const theme = useTheme();
    const handleDaySelection = (event) => {
      handleSelectInstructionDay(day, event);
    };

    return (
      <Box
        sx={{
          borderRadius: 1,
          backgroundColor: isSelected
            ? theme.palette.primary.lighter
            : "transparent",
          transition: "background-color 0.2s ease",
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{
            px: isMobile ? 1 : 2,
            py: 1,
          }}
        >
          <FormControlLabel
            label={
              <Typography
                variant={isMobile ? "body2" : "body1"}
                sx={{ color: theme.palette.text.primary }}
              >
                {capitalize(day.toLowerCase())}
              </Typography>
            }
            control={
              <Checkbox
                checked={isSelected}
                onChange={handleDaySelection}
                sx={{
                  color: theme.palette.primary.main,
                  "&.Mui-checked": {
                    color: theme.palette.primary.main,
                  },
                }}
              />
            }
          />
          {isSelected && (
            <IconButton
              aria-label="add time range"
              size={isMobile ? "small" : "medium"}
              onClick={() => handleAddDayInstructionHour(day)}
              sx={{
                color: theme.palette.primary.main,
                "&:hover": {
                  backgroundColor: theme.palette.primary.lighter,
                },
              }}
            >
              <AddCircleIcon fontSize={isMobile ? "small" : "medium"} />
            </IconButton>
          )}
        </Stack>

        <Collapse in={isSelected}>
          <Box
            sx={{
              px: isMobile ? 1 : 2,
              pb: 2,
              pt: 1,
            }}
          >
            <Stack spacing={2}>
              {value.map((hour, index) => (
                <InstructionHour
                  key={`${day}-${index}`}
                  day={day}
                  index={index}
                  hour={hour}
                  handleChangeTime={handleChangeTime}
                  handleRemoveDayInstructionHour={
                    handleRemoveDayInstructionHour
                  }
                  error={errors?.[index]}
                  showRemove={value.length > 1}
                  isMobile={isMobile}
                />
              ))}
            </Stack>
          </Box>
        </Collapse>
      </Box>
    );
  }
);

export default InstructionDay;

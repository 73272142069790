import React, { createContext, useCallback, useContext, useMemo } from "react";
import { useBaseWizardState } from "../hooks/useBaseWizardState";

export const PlannerWizardContext = createContext(null);

export const PlannerWizardProvider = ({ children, steps }) => {
  const {
    activeStep,
    wizardData,
    stepValidation,
    setActiveStep,
    updateWizardData,
    updateStepValidation,
    validateStep,
    handleStepChange,
    clearWizardData,
  } = useBaseWizardState(steps);

  const resetPreviewGeneration = useCallback(() => {
    updateWizardData({
      previewGenerated: false,
      previewUrl: null,
    });
  }, [updateWizardData]);

  const contextValue = useMemo(
    () => ({
      activeStep,
      wizardData,
      stepValidation,
      setActiveStep,
      updateWizardData,
      updateStepValidation,
      validateStep,
      handleStepChange,
      clearWizardData,
      resetPreviewGeneration,
    }),
    [
      activeStep,
      wizardData,
      stepValidation,
      setActiveStep,
      updateWizardData,
      updateStepValidation,
      validateStep,
      handleStepChange,
      clearWizardData,
      resetPreviewGeneration,
    ]
  );

  return (
    <PlannerWizardContext.Provider value={contextValue}>
      {children}
    </PlannerWizardContext.Provider>
  );
};

// Helper to determine if relevant data changed
function hasRelevantChanges(oldData, newData) {
  const relevantFields = [
    "termId",
    "studentId",
    "themeId", // Add theme selection
  ];
  return relevantFields.some((field) => oldData[field] !== newData[field]);
}

export const usePlannerWizardContext = () => {
  const context = useContext(PlannerWizardContext);
  if (!context) {
    throw new Error(
      "usePlannerWizardContext must be used within a PlannerWizardProvider"
    );
  }
  return context;
};

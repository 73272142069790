import PersonIcon from "@mui/icons-material/Person";
import { Avatar, Box, Button, Stack, Typography } from "@mui/material";
import React, { useRef, useState } from "react";
import AvatarEditor from "react-avatar-editor";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteProfileImage,
  updateProfileImage,
  uploadProfileImage,
} from "../../../api/userApi";
import HsModal from "../../../components/common/HsModal";
import { useReportContext } from "../../../contexts/report-context";
import { useAsyncOperation } from "../../../hooks/useAsyncOperation";
import { setAuthInfo } from "../../../redux/authSlice";
import { debug } from "../../../utils/debug";

const ProfileImage = ({ user, onEditImageClick }) => {
  const [avatarFile, setAvatarFile] = useState(null);
  const [avatarEditorOpen, setAvatarEditorOpen] = useState(false);
  const [avatarScale, setAvatarScale] = useState(1);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const editorRef = useRef(null);
  const fileInputRef = useRef(null);
  const { setMessage } = useReportContext();
  const { selectedAccountId } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const { execute: executeUploadImage } = useAsyncOperation(uploadProfileImage);
  const { execute: executeUpdateImage } = useAsyncOperation(updateProfileImage);
  const { execute: executeDeleteImage } = useAsyncOperation(deleteProfileImage);

  const handleSaveAvatar = async () => {
    if (editorRef.current) {
      const canvas = editorRef.current.getImageScaledToCanvas();
      canvas.toBlob(async (blob) => {
        try {
          const formData = new FormData();
          formData.append("file", blob, "profile-image.png");

          const updatedUser = user.profileImageUrl
            ? await executeUpdateImage(user.id, formData, selectedAccountId)
            : await executeUploadImage(user.id, formData, selectedAccountId);

          dispatch(setAuthInfo({ loggedInUser: updatedUser }));

          setMessage({
            text: "Profile image updated successfully",
            severity: "success",
            flag: true,
          });

          setAvatarEditorOpen(false);
        } catch (error) {
          debug.profile.error("Failed to update profile image:", error);
          setMessage({
            text: "Failed to update profile image",
            severity: "error",
            flag: true,
          });
        }
      }, "image/png");
    }
  };

  const handleDeleteAvatar = async () => {
    setEditModalOpen(false);
    try {
      const updatedUser = await executeDeleteImage(user.id, selectedAccountId);
      dispatch(setAuthInfo({ loggedInUser: updatedUser }));
      setMessage({
        text: "Profile image deleted successfully",
        severity: "success",
        flag: true,
      });
    } catch (error) {
      debug.profile.error("Error deleting profile image:", error);
      if (error.response && error.response.status === 401) {
        dispatch(setAuthInfo({ loggedInUser: null, token: null }));
      } else {
        setMessage({
          text: "Failed to delete profile image",
          severity: "error",
          flag: true,
        });
      }
    }
  };

  const handleAvatarClick = () => {
    setEditModalOpen(true);
  };

  const handleAvatarChange = (e) => {
    const file = e.target.files[0];
    setAvatarFile(file);
    setAvatarEditorOpen(true);
  };

  const handleSelectNewImage = () => {
    fileInputRef.current.click();
    setEditModalOpen(false);
  };

  return (
    <Box display="flex" flexDirection="column" height="100%" width={200}>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="flex-start"
        justifyContent="space-between"
        height="100%"
      >
        <Avatar
          src={user.profileImageUrl}
          sx={{
            width: 200,
            height: 200,
            cursor: "pointer",
          }}
          onClick={handleAvatarClick}
        >
          {!user.profileImageUrl && <PersonIcon style={{ fontSize: "5rem" }} />}
        </Avatar>
      </Box>
      <Box mt={2}>
        <Button
          variant="contained"
          color="primary"
          onClick={onEditImageClick || handleAvatarClick}
          sx={{ whiteSpace: "nowrap", width: "100%" }}
        >
          Edit Image
        </Button>
      </Box>
      <input
        type="file"
        accept="image/*"
        onChange={handleAvatarChange}
        style={{ display: "none" }}
        ref={fileInputRef}
      />

      <HsModal
        open={editModalOpen}
        handleClose={() => setEditModalOpen(false)}
        title="Edit Profile Image"
      >
        <Stack spacing={2}>
          <Typography>What would you like to do?</Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSelectNewImage}
            fullWidth
          >
            Select New Image
          </Button>
          {user.profileImageUrl && (
            <Button
              variant="contained"
              color="secondary"
              onClick={handleDeleteAvatar}
              fullWidth
            >
              Remove Current Image
            </Button>
          )}
        </Stack>
      </HsModal>

      <HsModal
        open={avatarEditorOpen}
        handleClose={() => setAvatarEditorOpen(false)}
        title="Edit Avatar"
      >
        <Stack spacing={2}>
          <AvatarEditor
            ref={editorRef}
            image={avatarFile}
            width={250}
            height={250}
            border={50}
            borderRadius={125}
            color={[255, 255, 255, 0.6]}
            scale={avatarScale}
            rotate={0}
          />
          <Typography gutterBottom>Zoom</Typography>
          <input
            type="range"
            min="1"
            max="2"
            step="0.01"
            value={avatarScale}
            onChange={(e) => setAvatarScale(parseFloat(e.target.value))}
          />
          <Button
            onClick={handleSaveAvatar}
            color="primary"
            variant="contained"
          >
            Save
          </Button>
        </Stack>
      </HsModal>
    </Box>
  );
};

export default ProfileImage;

import StarIcon from "@mui/icons-material/Star";
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Typography,
  alpha,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { loadStripe } from "@stripe/stripe-js";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { createCheckoutSession } from "../api/paymentApi";
import { CONFIG } from "../config/config";

const stripePromise = loadStripe(CONFIG.STRIPE_PUBLISHABLE_KEY);

const EmptySubscriptionView = () => {
  const { selectedAccountId } = useSelector((state) => state.auth);
  const [loadingPlan, setLoadingPlan] = useState(null);
  const theme = useTheme();

  const plans = [
    {
      type: "BASIC",
      title: "Basic Plan",
      monthlyPrice: "$5",
      yearlyPrice: "$60",
      color: theme.palette.primary.main,
      features: [
        "1 student",
        "Unlimited school terms",
        "Unlimited lesson plans",
        "Priority support",
      ],
    },
    {
      type: "FAMILY",
      title: "Family Plan",
      monthlyPrice: "$10",
      yearlyPrice: "$120",
      color: theme.palette.purple.main,
      features: [
        "Up to 5 students",
        "Unlimited school terms",
        "Unlimited lesson plans",
        "Priority support",
      ],
      recommended: true,
    },
    {
      type: "PRO",
      title: "Pro Plan",
      monthlyPrice: "$17",
      yearlyPrice: "$200",
      color: theme.palette.yellow.main,
      features: [
        "Up to 10 students",
        "Unlimited school terms",
        "Unlimited lesson plans",
        "Priority support",
      ],
    },
  ];

  const handleStartTrial = async (planType) => {
    setLoadingPlan(planType);

    try {
      const { sessionId } = await createCheckoutSession(
        planType,
        selectedAccountId
      );
      const stripe = await stripePromise;
      await stripe.redirectToCheckout({ sessionId });
    } catch (error) {
      console.error("Error creating checkout session:", error);
    } finally {
      setLoadingPlan(null);
    }
  };

  return (
    <Container
      maxWidth="lg"
      sx={{
        mt: 2,
        px: { xs: 1, sm: 2 },
      }}
    >
      {/* Current Plan Limitations Banner */}
      <Paper
        elevation={0}
        sx={{
          p: { xs: 2, sm: 2 },
          mb: 3,
          mx: { xs: 0, sm: 0 },
          borderRadius: 2,
          backgroundColor: alpha(theme.palette.warning.light, 0.3),
          border: `1px solid ${theme.palette.warning.main}`,
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
          <Box>
            <Typography
              variant="subtitle1"
              color="warning.dark"
              fontWeight="medium"
              gutterBottom
            >
              Free Plan Limitations
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Your current plan is limited to 1 student, 1 school term, and 1
              lesson plan. Upgrade now to unlock unlimited features and enhance
              your homeschool planning experience.
            </Typography>
          </Box>
        </Box>
      </Paper>

      <Paper
        elevation={0}
        sx={{
          p: { xs: 2, sm: 4 },
          mx: { xs: 0, sm: 0 },
          borderRadius: 2,
          border: `1px solid ${theme.palette.divider}`,
          backgroundColor: alpha(theme.palette.primary.lighter, 0.4),
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Box textAlign="center" mb={4}>
          <Typography
            variant="h4"
            gutterBottom
            sx={{
              color: theme.palette.primary.main,
              fontWeight: "bold",
              mb: 2,
              fontSize: { xs: "1.75rem", sm: "2.125rem" },
            }}
          >
            Upgrade Your Homeschool Experience
          </Typography>
          <Typography
            variant="h6"
            color="text.secondary"
            sx={{
              mb: 3,
              maxWidth: "600px",
              mx: "auto",
              fontSize: { xs: "1rem", sm: "1.25rem" },
            }}
          >
            Start your 7-day free trial today and unlock the full potential of
            Homeschool Planner
          </Typography>
          <Divider sx={{ my: 4 }} />
        </Box>

        <Box
          sx={{
            width: "100%",
            maxWidth: "1000px",
            px: { xs: 1, sm: 2 },
          }}
        >
          <Grid
            container
            spacing={{ xs: 2, sm: 0 }}
            justifyContent="center"
            alignItems="stretch"
            sx={{
              width: "100%",
            }}
          >
            {plans.map((plan) => (
              <Grid
                item
                xs={12}
                md={4}
                key={plan.type}
                sx={{
                  p: { xs: 1, sm: 2 },
                }}
              >
                <Paper
                  elevation={2}
                  sx={{
                    p: { xs: 2, sm: 3 },
                    height: "100%",
                    borderRadius: 2,
                    display: "flex",
                    flexDirection: "column",
                    position: "relative",
                    mx: "auto",
                    width: "100%",
                    maxWidth: { xs: "100%", sm: "400px" },
                    border: plan.recommended
                      ? `2px solid ${theme.palette.primary.main}`
                      : "none",
                    transform: plan.recommended
                      ? { xs: "none", sm: "scale(1.02)" }
                      : "none",
                    transition: "transform 0.3s ease-in-out",
                    "&:hover": {
                      transform: { xs: "none", sm: "translateY(-4px)" },
                      boxShadow: { xs: "none", sm: theme.shadows[4] },
                    },
                  }}
                >
                  {plan.recommended && (
                    <Box
                      sx={{
                        position: "absolute",
                        top: { xs: 8, sm: 12 },
                        right: { xs: 8, sm: 12 },
                        backgroundColor: theme.palette.primary.main,
                        color: "white",
                        px: { xs: 1.5, sm: 2 },
                        py: { xs: 0.25, sm: 0.5 },
                        borderRadius: 1,
                        fontSize: { xs: "0.7rem", sm: "0.75rem" },
                        fontWeight: "bold",
                        zIndex: 1,
                      }}
                    >
                      RECOMMENDED
                    </Box>
                  )}
                  <Typography
                    variant="h5"
                    gutterBottom
                    fontWeight="bold"
                    sx={{
                      color: plan.color,
                      pr: plan.recommended ? { xs: 70, sm: 0 } : 0,
                    }}
                  >
                    {plan.title}
                  </Typography>
                  <Box sx={{ mb: 3 }}>
                    <Box
                      sx={{ display: "flex", alignItems: "baseline", gap: 0.5 }}
                    >
                      <Typography
                        variant="h3"
                        sx={{ color: plan.color, fontWeight: "bold" }}
                      >
                        {plan.monthlyPrice}
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        sx={{ color: "text.secondary" }}
                      >
                        /month
                      </Typography>
                    </Box>
                    <Typography
                      variant="caption"
                      sx={{
                        color: "text.secondary",
                        display: "block",
                        mt: 0.5,
                      }}
                    >
                      Billed annually at {plan.yearlyPrice}/year
                    </Typography>
                  </Box>
                  <List sx={{ mb: 4, flexGrow: 1 }}>
                    {plan.features.map((feature, index) => (
                      <ListItem key={index} sx={{ py: 1 }}>
                        <ListItemIcon sx={{ minWidth: 36 }}>
                          <StarIcon
                            sx={{ color: plan.color }}
                            fontSize="small"
                          />
                        </ListItemIcon>
                        <ListItemText
                          primary={feature}
                          primaryTypographyProps={{
                            sx: { fontSize: "0.9rem" },
                          }}
                        />
                      </ListItem>
                    ))}
                  </List>
                  <Button
                    variant="contained"
                    size="large"
                    fullWidth
                    onClick={() => handleStartTrial(plan.type)}
                    disabled={loadingPlan !== null}
                    sx={{
                      py: 1.5,
                      fontSize: "1rem",
                      fontWeight: "bold",
                      backgroundColor: plan.color,
                      "&:hover": {
                        backgroundColor: alpha(plan.color, 0.9),
                      },
                    }}
                  >
                    {loadingPlan === plan.type ? (
                      <CircularProgress size={24} color="inherit" />
                    ) : (
                      "Start 7-Day Free Trial"
                    )}
                  </Button>
                </Paper>
              </Grid>
            ))}
          </Grid>
        </Box>

        <Box sx={{ mt: 6, textAlign: "center" }}>
          <Typography
            variant="body2"
            sx={{ color: theme.palette.text.secondary }}
          >
            Cancel anytime during your 7-day trial period.
          </Typography>
        </Box>
      </Paper>
    </Container>
  );
};

export default EmptySubscriptionView;

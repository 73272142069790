import { Box, Button, Stack, Typography } from "@mui/material";
import React, { useCallback, useMemo, useRef, useState } from "react";
import {
  PlannerWizardProvider,
  usePlannerWizardContext,
} from "../../contexts/planner-wizard-context";
import { debug } from "../../utils/debug";
import HsModal from "../common/HsModal";
import HsWizard from "../common/HsWizard";
import FinalReview from "./FinalReview";
import PlannerIntroduction from "./PlannerIntroduction";
import PlannerPreview from "./PlannerPreview";
import StudentInfoConfirmation from "./StudentInfoConfirmation";
import TermStudentSelection from "./TermStudentSelection";
import ThemeSelection from "./ThemeSelection";

const WizardContent = ({ open, onClose, steps, refs }) => {
  const { activeStep, setActiveStep } = usePlannerWizardContext();
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);

  const handleForceBack = useCallback(() => {
    if (activeStep > 0) {
      debug.wizard.state("Force back called in PrintedPlannerWizard:", {
        currentStep: activeStep,
        targetStep: activeStep - 1,
      });
      setActiveStep(activeStep - 1);
    }
  }, [activeStep, setActiveStep]);

  const WIZARD_STEPS = useMemo(
    () =>
      steps.map((step) =>
        step.id === "preview"
          ? {
              ...step,
              props: {
                onForceBack: handleForceBack,
              },
            }
          : step
      ),
    [steps, handleForceBack]
  );

  const handleCloseRequest = () => {
    setShowConfirmDialog(true);
  };

  const handleConfirmClose = () => {
    setShowConfirmDialog(false);
    onClose?.();
  };

  const handleCancelClose = () => {
    setShowConfirmDialog(false);
  };

  const handleComplete = (data) => {
    debug.wizard.log("Wizard completed with data:", data);
    onClose?.();
  };

  return (
    <>
      <HsWizard
        open={open}
        handleClose={handleCloseRequest}
        title="Create Printed Planner"
        steps={WIZARD_STEPS}
        onComplete={handleComplete}
        contextType="planner"
        finishButtonText="Add to Cart"
      />

      <HsModal
        open={showConfirmDialog}
        handleClose={handleCancelClose}
        title="Whoa there, planning pioneer!"
      >
        <Box sx={{ p: 3, maxWidth: 400 }}>
          <Typography variant="body1" sx={{ mb: 3 }}>
            Are you sure you want to cancel your planner order? Your future self
            might be disappointed to miss out on this organizational
            masterpiece!
          </Typography>
          <Stack direction="row" spacing={2} justifyContent="center">
            <Button
              variant="outlined"
              onClick={handleCancelClose}
              color="primary"
            >
              Keep Planning
            </Button>
            <Button
              variant="contained"
              onClick={handleConfirmClose}
              color="secondary"
            >
              Exit (Sadly)
            </Button>
          </Stack>
        </Box>
      </HsModal>
    </>
  );
};

const PrintedPlannerWizard = ({ open, onClose }) => {
  const studentInfoRef = useRef(null);
  const themeSelectionRef = useRef(null);
  const plannerPreviewRef = useRef(null);
  const finalReviewRef = useRef(null);

  const WIZARD_STEPS = useMemo(
    () => [
      {
        id: "introduction",
        label: "Introduction",
        component: PlannerIntroduction,
        isValid: true,
      },
      {
        id: "term-student",
        label: "Term & Student",
        component: TermStudentSelection,
        isValid: false,
      },
      {
        id: "student-info",
        label: "Student Info",
        component: StudentInfoConfirmation,
        ref: studentInfoRef,
        isValid: false,
      },
      {
        id: "theme",
        label: "Theme",
        component: ThemeSelection,
        ref: themeSelectionRef,
        isValid: false,
      },
      {
        id: "preview",
        label: "Preview",
        component: PlannerPreview,
        ref: plannerPreviewRef,
        isValid: false,
      },
      {
        id: "review",
        label: "Review",
        component: FinalReview,
        ref: finalReviewRef,
        isValid: false,
      },
    ],
    []
  );

  return (
    <PlannerWizardProvider steps={WIZARD_STEPS}>
      <WizardContent
        open={open}
        onClose={onClose}
        steps={WIZARD_STEPS}
        refs={{
          studentInfoRef,
          themeSelectionRef,
          plannerPreviewRef,
          finalReviewRef,
        }}
      />
    </PlannerWizardProvider>
  );
};

export default PrintedPlannerWizard;

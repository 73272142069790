import { createSlice } from "@reduxjs/toolkit";
import { clearAuthInfo } from "./authSlice";

const initialState = {
  isOpen: false,
  messages: [],
  isLoading: false,
  error: null,
  isTyping: false, // For typing indicator
};

const aiAssistantSlice = createSlice({
  name: "aiAssistant",
  initialState,
  reducers: {
    initializeAssistant: (state, action) => {
      return {
        ...state,
        isOpen: action.payload.isOpen,
      };
    },
    openAssistant: (state) => {
      state.isOpen = true;
    },
    minimizeAssistant: (state) => {
      state.isOpen = false;
    },
    addMessage: (state, action) => {
      state.messages.push(action.payload);
    },
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setTyping: (state, action) => {
      state.isTyping = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    clearMessages: (state) => {
      state.messages = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(clearAuthInfo, (state) => {
      return initialState;
    });
  },
});

export const {
  initializeAssistant,
  openAssistant,
  minimizeAssistant,
  addMessage,
  setLoading,
  setTyping,
  setError,
  clearMessages,
} = aiAssistantSlice.actions;

export default aiAssistantSlice.reducer;

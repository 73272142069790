import {
  Box,
  Button,
  CircularProgress,
  Stack,
  Typography,
} from "@mui/material";
import { isEqual } from "lodash";
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import Carousel from "react-material-ui-carousel";
import { useSelector } from "react-redux";
import { printedPlannerApi } from "../../api/printedPlannerApi";
import { usePlannerWizardContext } from "../../contexts/planner-wizard-context";
import { useAsyncOperation } from "../../hooks/useAsyncOperation";
import { debug } from "../../utils/debug";
import HsModal from "../common/HsModal";
import LightboxPreview from "./LightboxPreview";

const getWittyMessage = () => {
  const messages = [
    "Brewing a pot of organizational magic...",
    "Channeling the energy of a thousand to-do lists...",
    "Aligning the stars for your perfect planner...",
  ];
  return messages[Math.floor(Math.random() * messages.length)];
};

const PlannerPreview = forwardRef(({ onForceBack }, ref) => {
  const {
    wizardData,
    updateWizardData,
    updateStepValidation,
    validateStep,
    activeStep: stepIndex,
    stepValidation,
  } = usePlannerWizardContext();

  const { loggedInUser } = useSelector((state) => state.auth);
  const { selectedAccountId } = useSelector((state) => state.auth);
  const pollIntervalRef = useRef(null);
  const reportIdRef = useRef(null);
  const isGeneratingRef = useRef(false);

  const [localPreviewUrls, setLocalPreviewUrls] = useState(
    wizardData.previewUrls || []
  );
  const [localStatus, setLocalStatus] = useState(() => {
    if (
      wizardData.status === "COMPLETED" &&
      wizardData.previewUrls?.length > 0
    ) {
      return "COMPLETED";
    }
    return "PENDING";
  });
  const [localError, setLocalError] = useState(null);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [pendingBack, setPendingBack] = useState(false);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [activeStep, setActiveStep] = useState(0);

  const { execute: generatePreview, loading } = useAsyncOperation(
    printedPlannerApi.generatePlannerPreview
  );

  const { execute: checkPreviewStatus } = useAsyncOperation(
    printedPlannerApi.getPlannerPreviewStatus
  );

  // Add refs to track previous values
  const prevStudentRef = useRef(wizardData.student);
  const prevTermRef = useRef(wizardData.term);
  const prevThemeRef = useRef(wizardData.themeId);

  // Update the data change effect to be more thorough
  useEffect(() => {
    debug.wizard.state("Checking for preview input changes:", {
      currentTheme: wizardData.themeId,
      prevTheme: prevThemeRef.current,
      currentStudent: wizardData.student,
      prevStudent: prevStudentRef.current,
      currentTerm: wizardData.term,
      prevTerm: prevTermRef.current,
    });

    const studentChanged = !isEqual(prevStudentRef.current, wizardData.student);
    const termChanged = !isEqual(prevTermRef.current, wizardData.term);
    const themeChanged = wizardData.themeId !== prevThemeRef.current;

    if (studentChanged || termChanged || themeChanged) {
      debug.wizard.state("Preview inputs changed:", {
        studentChanged,
        termChanged,
        themeChanged,
        prevTheme: prevThemeRef.current,
        newTheme: wizardData.themeId,
      });

      // Reset preview state
      setLocalStatus("PENDING");
      setLocalPreviewUrls([]);
      isGeneratingRef.current = false;

      // Reset wizard preview data
      updateWizardData({
        previewUrls: null,
        status: "PENDING",
        reportId: null,
        pageCount: null,
        itemPrice: null,
        generatedFor: null,
      });

      // Update refs with new values
      prevStudentRef.current = wizardData.student;
      prevTermRef.current = wizardData.term;
      prevThemeRef.current = wizardData.themeId;
    }
  }, [
    wizardData.student,
    wizardData.term,
    wizardData.themeId,
    updateWizardData,
  ]);

  const startPolling = useCallback(
    (reportId) => {
      debug.wizard.state("Starting polling for reportId:", {
        reportId,
        currentStatus: localStatus,
        hasExistingInterval: Boolean(pollIntervalRef.current),
      });

      // Clear any existing interval first
      if (pollIntervalRef.current) {
        clearInterval(pollIntervalRef.current);
        pollIntervalRef.current = null;
      }

      reportIdRef.current = reportId;

      const poll = async () => {
        // Skip polling if component is generating or has completed
        if (localStatus === "COMPLETED" || !reportIdRef.current) {
          if (pollIntervalRef.current) {
            clearInterval(pollIntervalRef.current);
            pollIntervalRef.current = null;
          }
          return;
        }

        try {
          const status = await checkPreviewStatus(reportId, selectedAccountId);
          debug.wizard.state("Preview status update:", {
            status,
            currentReportId: reportIdRef.current,
            currentStatus: localStatus,
          });

          if (status.status === "COMPLETED" && status.previewUrls) {
            // Clear interval before updating state
            if (pollIntervalRef.current) {
              clearInterval(pollIntervalRef.current);
              pollIntervalRef.current = null;
            }
            isGeneratingRef.current = false;

            // Update states in a specific order
            setLocalPreviewUrls(status.previewUrls);
            setLocalStatus("COMPLETED");

            updateWizardData({
              previewUrls: status.previewUrls,
              status: "COMPLETED",
              reportId,
              pageCount: status.pageCount,
              itemPrice: status.itemPrice,
              generatedFor: {
                studentId: wizardData.student.id,
                termId: wizardData.term.id,
                themeId: wizardData.themeId,
              },
            });

            debug.wizard.state("Preview generation completed:", {
              urls: status.previewUrls,
              pageCount: status.pageCount,
              itemPrice: status.itemPrice,
              localStatus: "COMPLETED",
            });
          } else if (status.status === "FAILED") {
            if (pollIntervalRef.current) {
              clearInterval(pollIntervalRef.current);
              pollIntervalRef.current = null;
            }
            isGeneratingRef.current = false;
            setLocalStatus("FAILED");
            setLocalError("Preview generation failed");
          }
        } catch (error) {
          if (error.name === "CanceledError") {
            return;
          }
          console.error("Error polling preview status:", error);
        }
      };

      pollIntervalRef.current = setInterval(poll, 3000); // Increased interval to 3s
      poll(); // Initial poll
    },
    [
      checkPreviewStatus,
      selectedAccountId,
      updateWizardData,
      wizardData,
      localStatus,
    ]
  );

  // Cleanup polling on unmount or when component is hidden
  useEffect(() => {
    return () => {
      debug.wizard.state("Cleaning up polling", {
        hasInterval: Boolean(pollIntervalRef.current),
        currentStatus: localStatus,
      });
      if (pollIntervalRef.current) {
        clearInterval(pollIntervalRef.current);
        pollIntervalRef.current = null;
      }
    };
  }, []);

  // Update shouldGeneratePreview to be more explicit about theme changes
  const shouldGeneratePreview = useMemo(() => {
    const hasRequiredData = Boolean(
      wizardData.student?.id && wizardData.term?.id && wizardData.themeId
    );

    const currentPreviewMatchesInputs = Boolean(
      wizardData.generatedFor?.studentId === wizardData.student?.id &&
        wizardData.generatedFor?.termId === wizardData.term?.id &&
        wizardData.generatedFor?.themeId === wizardData.themeId
    );

    const hasValidPreview = Boolean(
      wizardData.previewUrls?.length > 0 &&
        wizardData.generatedFor &&
        currentPreviewMatchesInputs
    );

    const isIdle =
      !loading && !isGeneratingRef.current && localStatus !== "GENERATING";

    debug.wizard.state("Preview generation check:", {
      hasRequiredData,
      hasValidPreview,
      currentPreviewMatchesInputs,
      isIdle,
      themeId: wizardData.themeId,
      generatedForTheme: wizardData.generatedFor?.themeId,
    });

    return hasRequiredData && !hasValidPreview && isIdle;
  }, [
    wizardData.student?.id,
    wizardData.term?.id,
    wizardData.themeId,
    wizardData.previewUrls,
    wizardData.generatedFor,
    loading,
    localStatus,
  ]);

  // Update the main preview generation effect to use the memoized check
  useEffect(() => {
    if (localStatus === "COMPLETED" || isGeneratingRef.current || loading) {
      return;
    }

    if (shouldGeneratePreview) {
      isGeneratingRef.current = true;
      setLocalStatus("GENERATING");

      generatePreview({
        studentId: wizardData.student.id,
        termId: wizardData.term.id,
        themeId: wizardData.themeId,
        userId: loggedInUser?.id,
        accountId: selectedAccountId,
      })
        .then((response) => {
          if (response?.id) {
            updateWizardData({
              reportId: response.id,
              status: "GENERATING",
              generatedFor: {
                studentId: wizardData.student.id,
                termId: wizardData.term.id,
                themeId: wizardData.themeId,
              },
            });
            startPolling(response.id);
          }
        })
        .catch((error) => {
          if (error.name !== "CanceledError") {
            isGeneratingRef.current = false;
            setLocalStatus("FAILED");
            setLocalError("Failed to generate preview");
            updateWizardData({
              status: "FAILED",
            });
          }
        });
    }
  }, [
    shouldGeneratePreview,
    generatePreview,
    updateWizardData,
    startPolling,
    loggedInUser?.id,
    selectedAccountId,
    wizardData.student?.id,
    wizardData.term?.id,
    wizardData.themeId,
    localStatus,
    loading,
  ]);

  useEffect(() => {
    const isValid = localStatus === "COMPLETED" && localPreviewUrls.length > 0;
    debug.wizard.state("PlannerPreview validation:", {
      localStatus,
      previewUrlsLength: localPreviewUrls.length,
      isValid,
    });
    updateStepValidation(isValid);
  }, [localStatus, localPreviewUrls, updateStepValidation]);

  const handleConfirmBack = useCallback(() => {
    debug.wizard.state("PlannerPreview handleConfirmBack:", {
      hasForceBack: Boolean(onForceBack),
      onForceBack,
      localStatus,
      isGenerating: isGeneratingRef.current,
      hasPollingInterval: Boolean(pollIntervalRef.current),
      reportId: reportIdRef.current,
    });

    // Clean up polling and generation state
    if (pollIntervalRef.current) {
      debug.wizard.state("Cleaning up polling interval in handleConfirmBack");
      clearInterval(pollIntervalRef.current);
      pollIntervalRef.current = null;
    }
    isGeneratingRef.current = false;

    // Reset all state
    debug.wizard.state("Resetting state in handleConfirmBack");
    setLocalStatus("PENDING");
    setLocalPreviewUrls([]);
    setShowConfirmDialog(false);
    setPendingBack(false);

    // Reset wizard data
    debug.wizard.state("Resetting wizard data in handleConfirmBack");
    updateWizardData({
      previewUrls: null,
      status: "PENDING",
      reportId: null,
      pageCount: null,
      itemPrice: null,
      generatedFor: null,
    });

    // Force navigation back
    debug.wizard.state("Attempting force back navigation");
    if (onForceBack) {
      debug.wizard.state("Calling onForceBack");
      onForceBack();
    } else {
      debug.wizard.state(
        "Warning: onForceBack not available in handleConfirmBack"
      );
    }
  }, [onForceBack, updateWizardData]);

  const handleCancelBack = useCallback(() => {
    setShowConfirmDialog(false);
    setPendingBack(false);
  }, []);

  useImperativeHandle(
    ref,
    () => ({
      handleBack: () => {
        debug.wizard.state("PlannerPreview handleBack called:", {
          localStatus,
          isGenerating: isGeneratingRef.current,
          hasPollingInterval: Boolean(pollIntervalRef.current),
          hasForceBack: Boolean(onForceBack),
        });

        // Check for active generation or polling
        const isActive =
          localStatus === "GENERATING" ||
          isGeneratingRef.current ||
          pollIntervalRef.current !== null;

        if (isActive) {
          debug.wizard.state("Showing back confirmation - generation active");
          setShowConfirmDialog(true);
          setPendingBack(true);
          return false; // Block navigation
        }

        // If no active generation, allow immediate back navigation
        if (onForceBack) {
          debug.wizard.state("No active generation, forcing back navigation");
          onForceBack();
        }
        return true;
      },
      isValid: () => localStatus === "COMPLETED" && localPreviewUrls.length > 0,
    }),
    [localStatus, localPreviewUrls.length, onForceBack]
  );

  const handleImageClick = () => {
    debug.wizard.state("Image clicked, preview urls:", {
      urls: localPreviewUrls,
      activeStep,
    });
    if (localPreviewUrls.length > 0) {
      setIsFullScreen(true);
    }
  };

  return (
    <>
      {/* Modal should be first so it's always rendered */}
      <HsModal
        open={showConfirmDialog}
        handleClose={handleCancelBack}
        title="Preview Generation in Progress"
      >
        <Box sx={{ p: 3 }}>
          <Typography sx={{ mb: 3 }}>
            Preview generation is in progress. Are you sure you want to go back?
            This will cancel the current preview generation.
          </Typography>
          <Stack direction="row" spacing={2} justifyContent="flex-end">
            <Button onClick={handleCancelBack} color="primary">
              Continue Generating
            </Button>
            <Button
              onClick={handleConfirmBack}
              variant="contained"
              color="secondary"
            >
              Go Back
            </Button>
          </Stack>
        </Box>
      </HsModal>

      {/* Error state */}
      {localError && (
        <Box>
          <Typography color="error">{localError}</Typography>
        </Box>
      )}

      {/* Loading state */}
      {localStatus !== "COMPLETED" || localPreviewUrls.length === 0 ? (
        <Box textAlign="center">
          <CircularProgress />
          <Typography variant="h6" mt={2}>
            Generating your planner preview...
          </Typography>
          <Typography variant="body1" mt={1}>
            {getWittyMessage()}
          </Typography>
        </Box>
      ) : (
        /* Preview content */
        <Box sx={{ position: "relative", width: "100%" }}>
          <Carousel
            index={activeStep}
            onChange={(newIndex) => setActiveStep(newIndex)}
            autoPlay={false}
            animation="slide"
            navButtonsAlwaysVisible
            indicators={false}
          >
            {localPreviewUrls.map((url, index) => (
              <Box
                key={url}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "400px",
                }}
              >
                <img
                  src={url}
                  alt={`Preview ${index + 1}`}
                  style={{
                    maxWidth: "100%",
                    maxHeight: "100%",
                    objectFit: "contain",
                  }}
                  onClick={handleImageClick}
                />
              </Box>
            ))}
          </Carousel>
        </Box>
      )}

      {/* Add Lightbox */}
      <LightboxPreview
        open={isFullScreen}
        onClose={() => setIsFullScreen(false)}
        urls={localPreviewUrls}
        activeStep={activeStep}
        onStepChange={setActiveStep}
      />
    </>
  );
});

export default PlannerPreview;

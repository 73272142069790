import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import React from "react";
import { useSelector } from "react-redux";
import {
  createBillingPortalSession,
  getSubscriptionDetails,
} from "../../api/subscriptionApi";
import StripePricingTable from "../../components/StripePricingTable";
import { PAID_PLAN_TYPES } from "../../config/productConstants";
import { useReportContext } from "../../contexts/report-context";
import { useAsyncOperation } from "../../hooks/useAsyncOperation";
import { formatLimit } from "../../utils/utils";
import GoogleCalendarSection from "./components/GoogleCalendarSection";

const AccountSettings = () => {
  const { selectedAccountId } = useSelector((state) => state.auth);
  const { setMessage } = useReportContext();

  const {
    execute: fetchSubscription,
    data: subscription,
    loading: loadingSubscription,
  } = useAsyncOperation(getSubscriptionDetails);

  const { execute: executeCreateSession, loading: creatingSession } =
    useAsyncOperation(createBillingPortalSession);

  React.useEffect(() => {
    if (selectedAccountId) {
      fetchSubscription(selectedAccountId);
    }
  }, [selectedAccountId, fetchSubscription]);

  const handleManageSubscription = async () => {
    if (!selectedAccountId) {
      setMessage({
        text: "No account selected",
        severity: "error",
        flag: true,
      });
      return;
    }

    try {
      const { url } = await executeCreateSession(selectedAccountId);
      if (!url) {
        throw new Error("No URL returned from billing portal");
      }
      window.location.href = url;
    } catch (error) {
      setMessage({
        text:
          error.message === "No subscription found for this account"
            ? "You don't have an active subscription to manage"
            : "Failed to access subscription management. Please try again later.",
        severity: "error",
        flag: true,
      });
    }
  };

  const isPayingCustomer =
    subscription?.currentPlan &&
    PAID_PLAN_TYPES.includes(subscription.currentPlan.planType);

  const hasBillingInfo =
    subscription?.currentPlan &&
    PAID_PLAN_TYPES.includes(subscription.currentPlan.planType);

  return (
    <Box sx={{ maxWidth: 1200, p: 3 }}>
      <Typography variant="h4" gutterBottom>
        Account Settings
      </Typography>

      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper sx={{ p: 3 }}>
            <Typography variant="h5" gutterBottom>
              Subscription Management
            </Typography>
            <Divider sx={{ my: 2 }} />
            {loadingSubscription ? (
              <Box sx={{ textAlign: "center", py: 2 }}>
                <CircularProgress size={24} />
              </Box>
            ) : subscription?.currentPlan ? (
              <>
                <Grid container spacing={3} sx={{ mb: 3 }}>
                  <Grid item xs={12} md={6}>
                    <Typography variant="subtitle1" gutterBottom>
                      Current Plan
                    </Typography>
                    <Typography variant="h6" color="primary" gutterBottom>
                      {subscription.currentPlan.planType}
                    </Typography>
                    {hasBillingInfo && (
                      <>
                        {subscription.monthlyAmount && (
                          <Typography variant="body1" color="text.secondary">
                            {subscription.currency} {subscription.monthlyAmount}
                            /month
                          </Typography>
                        )}
                        {subscription.currentPlan.renewalDate && (
                          <Typography
                            variant="body2"
                            color="text.secondary"
                            sx={{ mt: 1 }}
                          >
                            Next billing date:{" "}
                            {dayjs(subscription.currentPlan.renewalDate).format(
                              "MMMM D, YYYY"
                            )}
                          </Typography>
                        )}
                      </>
                    )}
                    {subscription.currentPlan.trialEndsAt && (
                      <Typography
                        variant="body2"
                        color="warning.main"
                        sx={{ mt: 1 }}
                      >
                        Trial ends:{" "}
                        {dayjs(subscription.currentPlan.trialEndsAt).format(
                          "MMMM D, YYYY"
                        )}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography variant="subtitle1" gutterBottom>
                      Usage
                    </Typography>
                    <Typography variant="body1">
                      Active Students: {subscription.activeStudents} /{" "}
                      {formatLimit(subscription.maxStudents)}
                    </Typography>
                    {subscription.nextPlan && (
                      <Typography
                        variant="body2"
                        color="info.main"
                        sx={{ mt: 1 }}
                      >
                        Changing to {subscription.nextPlan.planType} on{" "}
                        {dayjs(subscription.nextPlan.effectiveDate).format(
                          "MMMM D, YYYY"
                        )}
                      </Typography>
                    )}
                  </Grid>
                </Grid>
                {isPayingCustomer ? (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleManageSubscription}
                    disabled={creatingSession}
                    sx={{ minWidth: 200 }}
                  >
                    Manage Subscription
                  </Button>
                ) : (
                  <Box>
                    <Typography
                      variant="subtitle1"
                      gutterBottom
                      sx={{ mt: 4, mb: 2 }}
                    >
                      Available Plans
                    </Typography>
                    <Box
                      sx={{
                        my: 4,
                        py: 4,
                        backgroundColor: "#E3D9E3",
                        borderRadius: 2,
                        border: "2px solid #ccc",
                      }}
                    >
                      <StripePricingTable />
                    </Box>
                  </Box>
                )}
              </>
            ) : (
              <Typography color="error">
                Failed to load subscription details
              </Typography>
            )}
          </Paper>
        </Grid>

        <Grid item xs={12}>
          <Paper sx={{ p: 3 }}>
            <Typography variant="h5" gutterBottom>
              Calendar Settings
            </Typography>
            <Divider sx={{ my: 2 }} />
            <GoogleCalendarSection />
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AccountSettings;

import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Container,
  Grid,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import {
  createBillingPortalSession,
  getSubscriptionDetails,
} from "../../api/subscriptionApi";
import StripePricingTable from "../../components/StripePricingTable";
import { PAID_PLAN_TYPES } from "../../config/productConstants";
import { useReportContext } from "../../contexts/report-context";
import { useAsyncOperation } from "../../hooks/useAsyncOperation";

const SubscriptionPage = () => {
  const { selectedAccountId } = useSelector((state) => state.auth);
  const { setMessage } = useReportContext();

  const {
    execute: fetchSubscription,
    data: subscription,
    loading,
    error,
  } = useAsyncOperation(getSubscriptionDetails);

  const { execute: createPortalSession } = useAsyncOperation(
    createBillingPortalSession
  );

  useEffect(() => {
    if (selectedAccountId) {
      fetchSubscription(selectedAccountId);
    }
  }, [selectedAccountId, fetchSubscription]);

  const handleManageSubscription = async () => {
    try {
      const { url } = await createPortalSession(selectedAccountId);
      if (!url) {
        throw new Error("No URL returned from billing portal");
      }
      window.location.href = url;
    } catch (error) {
      setMessage({
        text:
          error.message === "No subscription found for this account"
            ? "You don't have an active subscription to manage"
            : "Failed to access billing portal. Please try again.",
        severity: "error",
        flag: true,
      });
    }
  };

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="400px"
      >
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box>
        <Typography color="error">
          Failed to load subscription details. Please try again later.
        </Typography>
      </Box>
    );
  }

  const isPayingCustomer =
    subscription?.currentPlan &&
    PAID_PLAN_TYPES.includes(subscription.currentPlan.planType);

  const hasBillingInfo =
    subscription?.currentPlan &&
    PAID_PLAN_TYPES.includes(subscription.currentPlan.planType);

  return (
    <Box sx={{ py: 4 }}>
      {isPayingCustomer ? (
        <Box>
          <Typography variant="h4" gutterBottom>
            Subscription Details
          </Typography>
          <Card elevation={3} sx={{ mb: 4 }}>
            <CardContent>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <Typography variant="h6" gutterBottom>
                    Current Plan
                  </Typography>
                  <Typography variant="body1" paragraph>
                    {subscription.currentPlan.planType}
                  </Typography>
                  {hasBillingInfo && (
                    <>
                      {subscription.monthlyAmount && (
                        <Typography variant="body1" paragraph>
                          {subscription.currency} {subscription.monthlyAmount}
                          /month
                        </Typography>
                      )}
                      {subscription.currentPlan.renewalDate && (
                        <Typography variant="body1">
                          Next billing date:{" "}
                          {dayjs(subscription.currentPlan.renewalDate).format(
                            "MMMM D, YYYY"
                          )}
                        </Typography>
                      )}
                    </>
                  )}
                  {subscription.currentPlan.trialEndsAt && (
                    <Typography variant="body1" color="warning.main">
                      Trial ends:{" "}
                      {dayjs(subscription.currentPlan.trialEndsAt).format(
                        "MMMM D, YYYY"
                      )}
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography variant="h6" gutterBottom>
                    Usage
                  </Typography>
                  <Typography variant="body1">
                    Active Students: {subscription.activeStudents}{" "}
                    {subscription.maxStudents > 1000000
                      ? "/ Unlimited"
                      : `/ ${subscription.maxStudents}`}
                  </Typography>
                  {subscription.nextPlan && (
                    <Box sx={{ mt: 2 }}>
                      <Typography variant="body1" color="info.main">
                        Changing to {subscription.nextPlan.planType} on{" "}
                        {dayjs(subscription.nextPlan.effectiveDate).format(
                          "MMMM D, YYYY"
                        )}
                      </Typography>
                    </Box>
                  )}
                </Grid>
              </Grid>
              <Box sx={{ mt: 3 }}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleManageSubscription}
                >
                  Manage Subscription
                </Button>
              </Box>
            </CardContent>
          </Card>
        </Box>
      ) : (
        // Pricing table for non-paying customers
        <Container maxWidth="md">
          <Typography
            variant="h4"
            align="center"
            gutterBottom
            sx={{
              mb: 4,
              fontWeight: "medium",
              color: "text.primary",
            }}
          >
            Choose Your Plan
          </Typography>
          <Box
            sx={{
              my: 4,
              py: 4,
              backgroundColor: "#E3D9E3",
              borderRadius: 2,
              border: "2px solid #ccc",
            }}
          >
            <StripePricingTable />
          </Box>
        </Container>
      )}
    </Box>
  );
};

export default SubscriptionPage;

import AccessTimeIcon from "@mui/icons-material/AccessTime";
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  useTheme,
} from "@mui/material";
import moment from "moment-timezone";
import React, { useEffect, useState } from "react";

const TimezoneSelectorModal = ({
  isOpen,
  handleClose,
  handleConfirmTimezone,
  dismissable = true,
}) => {
  const [selectedTimezone, setSelectedTimezone] = useState("");
  const theme = useTheme();

  // Get user's likely timezone
  useEffect(() => {
    const guessedTimezone = moment.tz.guess();
    setSelectedTimezone(guessedTimezone);
  }, []);

  // Group timezones by continent/region
  const groupedTimezones = moment.tz.names().reduce((acc, zone) => {
    const region = zone.split("/")[0];
    if (!acc[region]) {
      acc[region] = [];
    }
    acc[region].push(zone);
    return acc;
  }, {});

  const formatTimezone = (timezone) => {
    const now = moment().tz(timezone);
    const offset = now.format("Z");
    const time = now.format("h:mm A");
    return `(GMT${offset}) ${timezone.replace("_", " ")} - ${time}`;
  };

  const handleSave = () => {
    handleConfirmTimezone(selectedTimezone);
  };

  return (
    <Dialog
      open={isOpen}
      onClose={dismissable ? handleClose : undefined}
      maxWidth="sm"
      fullWidth
      PaperProps={{
        sx: {
          borderRadius: 2,
          p: 2,
        },
      }}
    >
      <DialogTitle
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 1,
          color: theme.palette.primary.main,
        }}
      >
        <AccessTimeIcon />
        <Typography variant="h5" component="span">
          Set Your Time Zone
        </Typography>
      </DialogTitle>

      <DialogContent>
        <Alert severity="info" sx={{ mb: 3 }}>
          Setting your time zone helps us provide accurate scheduling and
          ensures your planner displays the correct times for your location.
        </Alert>

        <FormControl fullWidth>
          <InputLabel id="timezone-select-label">Time Zone</InputLabel>
          <Select
            labelId="timezone-select-label"
            id="timezone-select"
            value={selectedTimezone}
            onChange={(e) => setSelectedTimezone(e.target.value)}
            label="Time Zone"
            sx={{ mb: 2 }}
          >
            {Object.entries(groupedTimezones).map(([region, zones]) => [
              <MenuItem
                key={region}
                disabled
                divider
                sx={{
                  backgroundColor: theme.palette.grey[100],
                  color: theme.palette.text.secondary,
                  fontWeight: "bold",
                }}
              >
                {region}
              </MenuItem>,
              ...zones.map((zone) => (
                <MenuItem key={zone} value={zone}>
                  {formatTimezone(zone)}
                </MenuItem>
              )),
            ])}
          </Select>
        </FormControl>

        {selectedTimezone && (
          <Box
            sx={{
              mt: 2,
              p: 2,
              bgcolor: theme.palette.grey[50],
              borderRadius: 1,
            }}
          >
            <Typography variant="subtitle2" color="textSecondary" gutterBottom>
              Current time in selected zone:
            </Typography>
            <Typography variant="h6" color="primary">
              {moment()
                .tz(selectedTimezone)
                .format("dddd, MMMM D, YYYY h:mm A")}
            </Typography>
          </Box>
        )}
      </DialogContent>

      <DialogActions sx={{ px: 3, pb: 2 }}>
        {dismissable && (
          <Button onClick={handleClose} color="inherit">
            Cancel
          </Button>
        )}
        <Button
          onClick={handleSave}
          variant="contained"
          disabled={!selectedTimezone}
        >
          Confirm Time Zone
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default TimezoneSelectorModal;

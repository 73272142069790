import { Box, Grid, Typography } from "@mui/material";
import dayjs from "dayjs";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  getChecklist,
  updateCalendarEvent,
  updateChecklistStatusBulk,
} from "../../api/calendarApi";
import { getUsers, ROLE_STUDENT } from "../../api/userApi";
import { useReportContext } from "../../contexts/report-context";
import { useAsyncOperation } from "../../hooks/useAsyncOperation";
import ChecklistTable from "./ChecklistTable";
import FilterControls from "./FilterControls";
import WeekNavigation from "./WeekNavigation";

const ChecklistView = ({ accountId, onError }) => {
  const { setMessage } = useReportContext();
  const [status, setStatus] = useState("PENDING");
  const statusRef = useRef(status);
  const [weekStart, setWeekStart] = useState(dayjs().startOf("week"));
  const [selectedRows, setSelectedRows] = useState([]);
  const [students, setStudents] = useState([]);
  const [selectedStudent, setSelectedStudent] = useState("");
  const selectedStudentRef = useRef(selectedStudent);
  const [viewMode, setViewMode] = useState("week");
  const viewModeRef = useRef(viewMode);

  useEffect(() => {
    statusRef.current = status;
  }, [status]);

  useEffect(() => {
    selectedStudentRef.current = selectedStudent;
  }, [selectedStudent]);

  useEffect(() => {
    viewModeRef.current = viewMode;
  }, [viewMode]);

  const groupByDate = useCallback((events) => {
    return events.reduce((acc, event) => {
      const date = dayjs(event.userStartTime).format("dddd, MMMM D, YYYY");
      if (!acc[date]) {
        acc[date] = [];
      }
      acc[date].push(event);
      return acc;
    }, {});
  }, []);

  const fetchChecklist = useCallback(
    async (signal) => {
      const currentStatus = statusRef.current;
      const currentStudent = selectedStudentRef.current;
      const currentViewMode = viewModeRef.current;

      const params = {
        accountId,
        checklist_status: currentStatus === "ALL" ? undefined : currentStatus,
        signal,
        participant_id: currentStudent || undefined,
      };

      if (currentViewMode === "week") {
        params.startDate = weekStart.toISOString();
        params.endDate = weekStart.add(6, "day").endOf("day").toISOString();
      }

      const response = await getChecklist(params);
      return response;
    },
    [accountId, weekStart]
  );

  const {
    data: checklistData,
    loading,
    error: checklistError,
    execute: refreshChecklist,
  } = useAsyncOperation(fetchChecklist);

  const checklistItems = useMemo(() => {
    if (!checklistData?.events) return {};
    return groupByDate(checklistData.events);
  }, [checklistData, groupByDate]);

  const handleStatusChange = useCallback((event) => {
    setStatus(event.target.value);
  }, []);

  const handleViewModeChange = useCallback((event, newMode) => {
    if (newMode !== null) {
      setViewMode(newMode);
    }
  }, []);

  useEffect(() => {
    const controller = new AbortController();

    refreshChecklist().catch((error) => {
      if (error.code !== "ERR_CANCELED") {
        setMessage({
          text: error.message || "Failed to fetch checklist items",
          severity: "error",
          flag: true,
        });
      }
    });

    return () => controller.abort();
  }, [
    status,
    selectedStudent,
    weekStart,
    viewMode,
    refreshChecklist,
    setMessage,
  ]);

  const { execute: executeUpdate } = useAsyncOperation(
    async (eventId, newStatus, signal) => {
      await updateCalendarEvent(
        accountId,
        eventId,
        { checklistStatus: newStatus },
        { rebalance: false },
        signal
      );
    }
  );

  const handleStatusUpdate = useCallback(
    async (eventId, newStatus) => {
      try {
        await executeUpdate(eventId, newStatus);
        await refreshChecklist();
      } catch (error) {
        if (error.code !== "ERR_CANCELED") {
          onError(error);
        }
      }
    },
    [executeUpdate, refreshChecklist, onError]
  );

  const { execute: executeBulkUpdate } = useAsyncOperation(
    async (eventIds, newStatus, signal) => {
      await updateChecklistStatusBulk(accountId, eventIds, newStatus, signal);
    }
  );

  const handleBulkAction = useCallback(
    async (action) => {
      try {
        const statusMap = {
          COMPLETE: "COMPLETED",
          SKIP: "SKIPPED",
          POSTPONE: "PENDING",
        };

        const checklistStatus = statusMap[action];
        if (!checklistStatus) {
          throw new Error("Invalid action");
        }

        const eventIds = selectedRows.map((row) => row.id);
        await executeBulkUpdate(eventIds, checklistStatus);
        await refreshChecklist();
        setSelectedRows([]);
      } catch (error) {
        if (error.code !== "ERR_CANCELED") {
          setMessage({
            text: error.message || "Failed to update checklist items",
            severity: "error",
            flag: true,
          });
        }
      }
    },
    [executeBulkUpdate, refreshChecklist, selectedRows, setMessage]
  );

  const fetchStudents = useCallback(
    async (signal) => {
      const students = await getUsers(accountId, ROLE_STUDENT, signal);
      return students;
    },
    [accountId]
  );

  const {
    data: studentsData,
    error: studentsError,
    execute: executeStudentsFetch,
  } = useAsyncOperation(fetchStudents);

  useEffect(() => {
    executeStudentsFetch().catch((error) => {
      if (error.code !== "ERR_CANCELED") {
        onError(error);
      }
    });
  }, [executeStudentsFetch, onError]);

  useEffect(() => {
    if (studentsData) {
      setStudents(studentsData);
    }
  }, [studentsData]);

  useEffect(() => {
    if (studentsError && studentsError.code !== "ERR_CANCELED") {
      onError(studentsError);
    }
  }, [studentsError, onError]);

  const handleStudentChange = useCallback((event) => {
    setSelectedStudent(event.target.value);
  }, []);

  useEffect(() => {
    if (checklistError && checklistError.code !== "ERR_CANCELED") {
      setMessage({
        text: checklistError.message || "Failed to fetch checklist items",
        severity: "error",
        flag: true,
      });
    }
  }, [checklistError, setMessage]);

  return (
    <Box>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12} sm={6}>
          <Typography variant="h2" gutterBottom>
            Checklist
          </Typography>
        </Grid>
        {viewMode === "week" && (
          <Grid item xs={12} sm={6}>
            <WeekNavigation
              currentWeekStart={weekStart}
              setCurrentWeekStart={setWeekStart}
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <FilterControls
            students={students}
            selectedStudent={selectedStudent}
            handleSelectStudent={handleStudentChange}
            selectedStatus={status}
            handleSelectStatus={handleStatusChange}
            viewMode={viewMode}
            onViewModeChange={handleViewModeChange}
            selectedRows={selectedRows}
            handleBulkAction={handleBulkAction}
          />
        </Grid>
        <Grid item xs={12}>
          <ChecklistTable
            checklistItems={checklistItems || {}}
            loading={loading}
            error={checklistError?.message}
            selectedRows={selectedRows}
            setSelectedRows={setSelectedRows}
            onStatusChange={handleStatusUpdate}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default ChecklistView;

import CloseIcon from "@mui/icons-material/Close";
import {
  Backdrop,
  Box,
  Fade,
  IconButton,
  Modal,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import React from "react";

const Header = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  padding: theme.spacing(2),
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  borderTopLeftRadius: "inherit", // Inherit border radius from parent
  borderTopRightRadius: "inherit", // Inherit border radius from parent
  [theme.breakpoints.down("sm")]: {
    padding: theme.spacing(1.5, 2),
  },
}));

const Body = styled(Box)(({ theme }) => ({
  padding: theme.spacing(4),
  paddingTop: theme.spacing(2),
  [theme.breakpoints.down("sm")]: {
    padding: theme.spacing(2),
  },
}));

const HsModal = ({
  open,
  handleClose,
  title,
  children,
  dismissable = true,
  maxWidth = "sm",
}) => {
  const handleModalClose = (event, reason) => {
    if (
      dismissable &&
      (reason === "backdropClick" || reason === "escapeKeyDown")
    ) {
      if (handleClose) handleClose(event, reason);
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleModalClose}
      closeAfterTransition
      disableEscapeKeyDown={!dismissable}
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={open}>
        <Box
          onClick={(e) => e.stopPropagation()}
          sx={(theme) => ({
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "auto",
            minWidth: 400,
            height: "auto",
            maxHeight: "90vh",
            maxWidth: {
              xs: maxWidth === "md" ? "800px" : "600px",
              sm: maxWidth === "md" ? "800px" : "600px",
            },
            bgcolor: "background.paper",
            border: "none",
            boxShadow: 24,
            p: 0,
            outline: 0,
            borderRadius: "16px",
            overflow: "auto",
            display: "flex",
            flexDirection: "column",
          })}
        >
          <Header>
            <Typography
              variant="h6"
              sx={{
                color: "white",
                fontWeight: "bold",
                fontSize: {
                  xs: "1.125rem",
                  sm: "1.25rem",
                },
              }}
            >
              {title}
            </Typography>
            {dismissable && handleClose && (
              <IconButton
                onClick={handleClose}
                sx={{
                  color: "white",
                  padding: { xs: 1, sm: 1.5 },
                }}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            )}
          </Header>
          <Body
            sx={{
              flex: 1,
              overflow: "auto",
              p: { xs: 2, sm: 3 },
            }}
          >
            {children}
          </Body>
        </Box>
      </Fade>
    </Modal>
  );
};

export default HsModal;

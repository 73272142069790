import AddIcon from "@mui/icons-material/Add";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Box, IconButton, TableCell, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { createLessonPlan, updateLessonPlan } from "../../api/lessonPlanApi";
import HsTable from "../../components/common/HsTable";
import { useReportContext } from "../../contexts/report-context";
import { useAsyncOperation } from "../../hooks/useAsyncOperation";
import { usePlanRestriction } from "../../hooks/usePlanRestriction";
import {
  addLessonPlan,
  setLessonPlans,
  updateLessonPlan as updateLessonPlanAction,
} from "../../redux/lessonPlanSlice";
import { useLessonPlanOperations } from "./apiCalls";
import CreateLessonPlanModal from "./CreateLessonPlanModal";

const ClassesPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const selectedAccountId = useSelector(
    (state) => state.auth.selectedAccountId
  );
  const { setMessage } = useReportContext();

  const [openClassModal, setOpenClassModal] = useState(false);
  const [selectedClass, setSelectedClass] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const theme = useTheme();

  const { fetchLessonPlans } = useLessonPlanOperations();
  const { handlePlanRestrictionError } = usePlanRestriction();

  const handleError = useCallback(
    (message, error) => {
      if (!handlePlanRestrictionError(error)) {
        console.error(message, error);
        setMessage({ text: message, severity: "error", flag: true });
      }
    },
    [setMessage, handlePlanRestrictionError]
  );

  const fetchData = useCallback(
    async (signal) => {
      const result = await fetchLessonPlans(page, rowsPerPage);
      dispatch(setLessonPlans(result.data));
      return result;
    },
    [page, rowsPerPage, dispatch, fetchLessonPlans]
  );

  const {
    data,
    loading,
    error,
    execute: refetchData,
  } = useAsyncOperation(fetchData);

  useEffect(() => {
    if (error) {
      handleError("Failed to fetch lesson plans", error);
    }
  }, [error, handleError]);

  useEffect(() => {
    refetchData();
  }, [refetchData, page, rowsPerPage]);

  const handleOpenCreateModal = () => {
    setSelectedClass(null);
    setOpenClassModal(true);
  };

  const handleCloseClassModal = () => {
    setSelectedClass(null);
    setOpenClassModal(false);
  };

  const handleCreateClass = async (classData) => {
    try {
      const lessonPlanData = {
        ...classData,
        accountId: selectedAccountId,
      };

      const newClass = await createLessonPlan(lessonPlanData);
      dispatch(addLessonPlan(newClass));
      refetchData();
      handleCloseClassModal();
      setMessage({
        text: "Lesson plan created successfully",
        severity: "success",
        flag: true,
      });
    } catch (error) {
      handleError("Failed to create lesson plan", error);
    }
  };

  const handleUpdateClass = async (id, classData) => {
    try {
      const updatedData = {
        ...classData,
        accountId: selectedAccountId,
      };
      const updatedClass = await updateLessonPlan(id, updatedData);
      dispatch(updateLessonPlanAction(updatedClass));
      refetchData();
      handleCloseClassModal();
      setMessage({
        text: "Lesson plan updated successfully",
        severity: "success",
        flag: true,
      });
    } catch (error) {
      handleError("Failed to update lesson plan", error);
    }
  };

  const handleRowClick = useCallback(
    (row) => navigate(`/lesson-plans/${row.id}`),
    [navigate]
  );

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (newRowsPerPage) => {
    setRowsPerPage(newRowsPerPage);
    setPage(0);
  };

  const columns = useMemo(
    () => [
      { id: "name", label: "Title" },
      { id: "subject", label: "Subject" },
      { id: "description", label: "Description" },
      { id: "durationPerLesson", label: "Duration" },
      { id: "action", label: "" },
    ],
    []
  );

  const renderDesktopRow = useCallback(
    (row, columns) => (
      <>
        {columns.map((column) => (
          <TableCell key={column.id}>
            {column.id === "durationPerLesson" ? (
              `${row[column.id]} min`
            ) : column.id === "action" ? (
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  handleRowClick(row);
                }}
              >
                <ArrowForwardIosIcon fontSize="small" />
              </IconButton>
            ) : (
              row[column.id]
            )}
          </TableCell>
        ))}
      </>
    ),
    [handleRowClick]
  );

  const renderMobileRow = useCallback(
    (row) => (
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
        }}
      >
        <Box>
          <Typography variant="body2">{row.name}</Typography>
          <Typography variant="body2" color="text.secondary">
            {row.subject ? `${row.subject}, ` : ""}
            {row.durationPerLesson} min
          </Typography>
        </Box>
        <ArrowForwardIosIcon fontSize="small" color="action" />
      </Box>
    ),
    []
  );

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 4,
        }}
      >
        <Typography variant="h2">Lesson Plan Directory</Typography>
        <IconButton
          onClick={handleOpenCreateModal}
          sx={{
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
            "&:hover": {
              backgroundColor: theme.palette.primary.dark,
            },
          }}
        >
          <AddIcon />
        </IconButton>
      </Box>

      <HsTable
        columns={columns}
        data={data?.data || []}
        totalCount={data?.totalCount || 0}
        page={page}
        rowsPerPage={rowsPerPage}
        loading={loading}
        error={error?.message}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleRowsPerPageChange}
        renderDesktopRow={renderDesktopRow}
        renderMobileRow={renderMobileRow}
        onRowClick={handleRowClick}
        showPagination={true}
        rowsPerPageOptions={[10, 25, 50]}
      />

      <CreateLessonPlanModal
        open={openClassModal}
        handleCreate={handleCreateClass}
        handleUpdate={handleUpdateClass}
        handleClose={handleCloseClassModal}
        data={selectedClass}
      />
    </Box>
  );
};

export default ClassesPage;

import apiUrl from "../config/config";
import store from "../redux/store";
import { api } from "./api";
console.log("apiUrl:", apiUrl);

export const sendMessage = async (message, signal) => {
  console.log("sendMessage called with:", { message, signal });
  try {
    const state = store.getState();
    const selectedAccountId = state.auth.selectedAccountId;
    console.log("selectedAccountId:", selectedAccountId);
    if (!selectedAccountId) {
      throw new Error("Please select an account before using the AI Assistant");
    }

    const params = new URLSearchParams({
      user_prompt: message,
      account_id: selectedAccountId,
    });

    console.log(
      "Making API request to:",
      `${apiUrl}/api/v1/ai/scheduler?${params}`
    );
    const response = await api.post(
      `${apiUrl}/api/v1/ai/scheduler?${params}`,
      null,
      {
        signal,
      }
    );
    console.log("API response received:", response);

    return {
      data: {
        response: response.data,
        timestamp: new Date().toISOString(),
      },
    };
  } catch (error) {
    console.log("Error in sendMessage:", error);
    if (error.name === "AbortError") {
      throw error;
    }

    // Handle specific error cases
    if (error.response?.status === 403) {
      throw new Error("You do not have permission to use the AI Assistant");
    }

    if (error.response?.status === 401) {
      throw new Error("Please log in to use the AI Assistant");
    }

    // If no account is selected, show a more user-friendly message
    if (
      error.message === "Please select an account before using the AI Assistant"
    ) {
      throw error;
    }

    throw new Error(
      error.response?.data?.message ||
        "Unable to process your request. Please try again."
    );
  }
};

import { Box, Button, CircularProgress, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { completeCheckout } from "../../api/checkoutApi";
import { useAsyncOperation } from "../../hooks/useAsyncOperation";
import { clearCart } from "../../redux/cartSlice";
import { clearCheckoutData } from "../../redux/checkoutSlice";

const PaymentSuccess = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const sessionId = searchParams.get("session_id");
  const theme = useTheme();

  const {
    execute: executeComplete,
    loading,
    error,
    data: order,
  } = useAsyncOperation(completeCheckout);

  useEffect(() => {
    const completeOrder = async () => {
      if (!sessionId) {
        navigate("/");
        return;
      }

      try {
        await executeComplete(sessionId);
        // Clear cart and checkout data after successful completion
        dispatch(clearCart());
        dispatch(clearCheckoutData());
      } catch (error) {
        console.error("Failed to complete order:", error);
      }
    };

    completeOrder();
  }, [sessionId, executeComplete, dispatch, navigate]);

  const calculateOrderTotal = (order) => {
    if (!order) return 0;
    return (
      (parseFloat(order.total) || 0) +
      (parseFloat(order.totalTax) || 0) +
      (parseFloat(order.shippingCost) || 0)
    );
  };

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "flex-start",
          p: 4,
          maxWidth: "800px",
        }}
      >
        <CircularProgress sx={{ color: theme.palette.primary.main }} />
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ p: 4, maxWidth: "800px" }}>
        <Typography color="error" variant="h6">
          There was an error processing your order: {error.message}
        </Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ p: 4, maxWidth: "800px" }}>
      <Typography variant="h4" gutterBottom color="primary">
        Thank you for your order!
      </Typography>
      {order && (
        <Box sx={{ mt: 3 }}>
          <Typography variant="h6" gutterBottom color="text.secondary">
            Order Details
          </Typography>
          <Box sx={{ mt: 2 }}>
            <Typography sx={{ mb: 1 }}>
              <strong>Order ID:</strong> {order.id}
            </Typography>
            <Typography sx={{ mb: 1 }}>
              <strong>Subtotal:</strong> ${parseFloat(order.total).toFixed(2)}
            </Typography>
            {order.totalTax > 0 && (
              <Typography sx={{ mb: 1 }}>
                <strong>Tax:</strong> ${parseFloat(order.totalTax).toFixed(2)}
              </Typography>
            )}
            {order.shippingCost > 0 && (
              <Typography sx={{ mb: 1 }}>
                <strong>Shipping:</strong> $
                {parseFloat(order.shippingCost).toFixed(2)}
              </Typography>
            )}
            <Typography sx={{ mb: 1 }}>
              <strong>Total:</strong> ${calculateOrderTotal(order).toFixed(2)}
            </Typography>
            <Typography sx={{ mb: 1 }}>
              <strong>Status:</strong> {order.status}
            </Typography>
          </Box>
          <Button
            variant="darkRounded"
            sx={{ mt: 3 }}
            onClick={() => navigate("/home")}
          >
            Return to Dashboard
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default PaymentSuccess;

import { Box, CircularProgress } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { setupPricingTable } from "../api/subscriptionApi";
import { CONFIG, frontendUrl } from "../config/config";
import { useAsyncOperation } from "../hooks/useAsyncOperation";

const StripePricingTable = () => {
  const { selectedAccountId } = useSelector((state) => state.auth);
  const [setupInfo, setSetupInfo] = useState(null);
  const { execute: executeSetup, loading } =
    useAsyncOperation(setupPricingTable);

  useEffect(() => {
    const setup = async () => {
      try {
        const info = await executeSetup(selectedAccountId);
        setSetupInfo(info);
      } catch (error) {
        console.error("Failed to setup pricing table:", error);
      }
    };

    if (selectedAccountId) {
      setup();
    }
  }, [selectedAccountId, executeSetup]);

  if (loading || !setupInfo) {
    return (
      <Box display="flex" justifyContent="center" p={4}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <stripe-pricing-table
      pricing-table-id={CONFIG.REACT_APP_STRIPE_PRICING_TABLE_ID}
      publishable-key={CONFIG.STRIPE_PUBLISHABLE_KEY}
      client-reference-id={setupInfo.accountId}
      customer-session-client-secret={setupInfo.customerSessionClientSecret}
      success-url={`${frontendUrl}/subscription-success`}
      cancel-url={`${frontendUrl}/subscription-error`}
    ></stripe-pricing-table>
  );
};

export default StripePricingTable;

import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import TwitterIcon from "@mui/icons-material/Twitter";
import YouTubeIcon from "@mui/icons-material/YouTube";
import {
  Box,
  Container,
  IconButton,
  Link,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React from "react";

const Footer = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const footerLinks = [
    { text: "How we can help", href: "#how-we-can-help" },
    { text: "Benefits", href: "#benefits" },
    { text: "Pricing", href: "#pricing" },
    { text: "Testimonials", href: "#testimonials" },
    { text: "Contact", href: "#contact" },
  ];

  const socialIcons = [
    { icon: <FacebookIcon />, href: "#" },
    { icon: <TwitterIcon />, href: "#" },
    { icon: <LinkedInIcon />, href: "#" },
    { icon: <YouTubeIcon />, href: "#" },
  ];

  const handleScrollTo = (elementId) => {
    const element = document.getElementById(elementId);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <Box
      component="footer"
      sx={{
        bgcolor: theme.palette.secondary.light,
        width: "100%",
        mt: "auto",
      }}
    >
      <Container maxWidth={false} disableGutters>
        <Box sx={{ p: 3 }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: isMobile ? "column" : "row",
              justifyContent: "space-between",
              alignItems: isMobile ? "flex-start" : "center",
            }}
          >
            <Typography
              variant="h6"
              color="text.primary"
              gutterBottom={isMobile}
              sx={{ fontWeight: "bold" }}
            >
              HomeschoolPlanner.com
            </Typography>

            {isMobile ? (
              <Box
                sx={{ display: "flex", flexDirection: "column", gap: 1, mb: 2 }}
              >
                {footerLinks.map((link, index) => (
                  <Link
                    key={index}
                    component="button"
                    onClick={() => handleScrollTo(link.href.slice(1))}
                    color="text.secondary"
                    sx={{
                      "&:hover": { color: "text.primary" },
                      textAlign: "left",
                      textDecoration: "none",
                    }}
                  >
                    {link.text}
                  </Link>
                ))}
              </Box>
            ) : (
              <Box sx={{ display: "flex", gap: 3 }}>
                {footerLinks.map((link, index) => (
                  <Link
                    key={index}
                    component="button"
                    onClick={() => handleScrollTo(link.href.slice(1))}
                    color="text.secondary"
                    sx={{
                      "&:hover": { color: "text.primary" },
                      textDecoration: "none",
                    }}
                  >
                    {link.text}
                  </Link>
                ))}
              </Box>
            )}

            <Box sx={{ display: "flex", gap: 1 }}>
              {socialIcons.map((social, index) => (
                <IconButton
                  key={index}
                  href={social.href}
                  color="primary"
                  aria-label={`social media ${index + 1}`}
                >
                  {social.icon}
                </IconButton>
              ))}
            </Box>
          </Box>

          <Box sx={{ mt: 2, textAlign: isMobile ? "left" : "center" }}>
            <Typography variant="body2" color="text.secondary">
              {"Copyright © "}
              {new Date().getFullYear()}
              {" All Rights Reserved."}
            </Typography>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default Footer;

import { Box, CircularProgress, Typography } from "@mui/material";
import React from "react";

const LoadingIndicator = ({ message = "Loading...", fullHeight = false }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: fullHeight ? "100vh" : "100%",
        minHeight: fullHeight ? "auto" : 400,
        p: 3,
      }}
    >
      <CircularProgress
        size={40}
        thickness={4}
        sx={{ mb: 2, color: "primary.main" }}
      />
      <Typography
        variant="h6"
        color="text.secondary"
        sx={{ fontWeight: "normal" }}
      >
        {message}
      </Typography>
    </Box>
  );
};

export default LoadingIndicator;

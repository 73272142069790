import { LoadingButton } from "@mui/lab";
import {
  Autocomplete,
  Box,
  Button,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Stack,
  TextField,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { Form, Formik } from "formik";
import React from "react";
import HsModal from "../../components/common/HsModal";
import { createClassValidationSchema } from "../../utils/validation";
import {
  LESSON_FREQUENCIES,
  NUMBER_OF_LESSONS_TYPES,
  SUBJECTS,
} from "./classConstants";

const getInitialValues = (data) => ({
  name: data?.name || "",
  subject: data?.subject || "",
  description: data?.description || "",
  durationPerLesson: data?.durationPerLesson || 60,
  numberOfLessons: data?.numberOfLessons || 0,
  lessonFrequency: data?.lessonFrequency || "DAILY",
  numberOfLessonsType: data?.isOngoing ? "Ongoing" : "Fixed Number",
  ...(data ? {} : { numberOfLessonsType: "Ongoing" }),
});

const transformFormValues = (values) => ({
  name: values.name,
  subject: values.subject || null,
  description: values.description || null,
  durationPerLesson: values.durationPerLesson,
  numberOfLessons:
    values.numberOfLessonsType === "Fixed Number"
      ? values.numberOfLessons
      : null,
  lessonFrequency: values.lessonFrequency,
  isOngoing: values.numberOfLessonsType === "Ongoing",
});

// Remove all styled components except for radio groups
const StyledFormControl = styled(FormControl)(({ theme }) => ({
  width: "100%",
  "& .MuiFormLabel-root": {
    marginBottom: theme.spacing(1),
  },
}));

const StyledRadioGroup = styled(RadioGroup)(({ theme }) => ({
  "& .MuiFormControlLabel-root": {
    marginBottom: theme.spacing(0.5),
  },
}));

const CreateLessonPlanModal = ({
  open,
  handleCreate,
  handleUpdate,
  handleClose,
  data,
}) => {
  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      const classData = transformFormValues(values);
      if (data && data.id) {
        await handleUpdate(data.id, classData);
      } else {
        await handleCreate(classData);
      }
    } catch (err) {
      console.error("Failed to submit lesson plan", err);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <HsModal
      open={open}
      handleClose={handleClose}
      title={`${data ? "Edit" : "Create"} Lesson Plan`}
      maxWidth="md"
    >
      <Formik
        initialValues={getInitialValues(data)}
        validationSchema={createClassValidationSchema}
        onSubmit={handleSubmit}
        enableReinitialize={true}
      >
        {({ isSubmitting, values, setFieldValue, touched, errors }) => (
          <Form>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextField
                  name="name"
                  label="Name"
                  required
                  fullWidth
                  size="small"
                  value={values.name}
                  onChange={(e) => setFieldValue("name", e.target.value)}
                  error={touched.name && Boolean(errors.name)}
                  helperText={touched.name && errors.name}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  name="description"
                  label="Description"
                  fullWidth
                  multiline
                  rows={4}
                  size="small"
                  value={values.description}
                  onChange={(e) => setFieldValue("description", e.target.value)}
                  error={touched.description && Boolean(errors.description)}
                  helperText={touched.description && errors.description}
                />
              </Grid>

              <Grid item xs={12}>
                <Autocomplete
                  options={SUBJECTS}
                  value={values.subject}
                  onChange={(_, newValue) => setFieldValue("subject", newValue)}
                  freeSolo
                  clearable
                  size="small"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Subject"
                      fullWidth
                      size="small"
                      error={touched.subject && Boolean(errors.subject)}
                      helperText={touched.subject && errors.subject}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  name="durationPerLesson"
                  label="Duration (minutes)"
                  fullWidth
                  size="small"
                  type="number"
                  value={values.durationPerLesson}
                  onChange={(e) =>
                    setFieldValue("durationPerLesson", e.target.value)
                  }
                  error={
                    touched.durationPerLesson &&
                    Boolean(errors.durationPerLesson)
                  }
                  helperText={
                    touched.durationPerLesson && errors.durationPerLesson
                  }
                />
              </Grid>

              <Grid item xs={12}>
                <Box sx={{ mt: 1 }}>
                  <FormLabel
                    component="legend"
                    sx={{
                      mb: 1,
                    }}
                  >
                    Frequency
                  </FormLabel>
                  <StyledRadioGroup
                    name="lessonFrequency"
                    value={values.lessonFrequency}
                    onChange={(e) =>
                      setFieldValue("lessonFrequency", e.target.value)
                    }
                  >
                    {LESSON_FREQUENCIES.map((frequency) => (
                      <FormControlLabel
                        key={frequency.value}
                        value={frequency.value}
                        control={<Radio color="primary" />}
                        label={frequency.label}
                      />
                    ))}
                  </StyledRadioGroup>
                </Box>
              </Grid>

              <Grid item xs={12} md={6}>
                <Box sx={{ mt: 1 }}>
                  <StyledFormControl>
                    <FormLabel component="legend">Number of Lessons</FormLabel>
                    <StyledRadioGroup
                      name="numberOfLessonsType"
                      value={values.numberOfLessonsType}
                      onChange={(e) =>
                        setFieldValue("numberOfLessonsType", e.target.value)
                      }
                    >
                      {NUMBER_OF_LESSONS_TYPES.map((type) => (
                        <FormControlLabel
                          key={type.value}
                          value={type.value}
                          control={<Radio color="primary" />}
                          label={type.label}
                        />
                      ))}
                    </StyledRadioGroup>
                    {values.numberOfLessonsType === "Fixed Number" && (
                      <Box sx={{ mt: 1 }}>
                        <TextField
                          name="numberOfLessons"
                          label="Number of Lessons"
                          fullWidth
                          size="small"
                          type="number"
                          value={values.numberOfLessons}
                          onChange={(e) =>
                            setFieldValue("numberOfLessons", e.target.value)
                          }
                          error={
                            touched.numberOfLessons &&
                            Boolean(errors.numberOfLessons)
                          }
                          helperText={
                            touched.numberOfLessons && errors.numberOfLessons
                          }
                        />
                      </Box>
                    )}
                  </StyledFormControl>
                </Box>
              </Grid>
            </Grid>

            <Divider sx={{ mt: 3 }} />
            <Stack
              direction="row"
              spacing={2}
              justifyContent="flex-end"
              sx={{ mt: 3 }}
            >
              <Button
                variant="outlined"
                onClick={handleClose}
                disabled={isSubmitting}
              >
                Cancel
              </Button>
              <LoadingButton
                type="submit"
                variant="contained"
                loading={isSubmitting}
                disabled={isSubmitting}
              >
                {data ? "Save Changes" : "Create Lesson Plan"}
              </LoadingButton>
            </Stack>
          </Form>
        )}
      </Formik>
    </HsModal>
  );
};

export default CreateLessonPlanModal;

import AddIcon from "@mui/icons-material/Add";
import DownloadIcon from "@mui/icons-material/Download";
import FileIcon from "@mui/icons-material/InsertDriveFile";
import RefreshIcon from "@mui/icons-material/Refresh";
import { Box, Button, Chip, Stack, TableCell, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  downloadReport,
  fetchReports,
  REPORT_STATUS,
} from "../../api/reportApi";
import HsTable from "../../components/common/HsTable";
import { useReportContext } from "../../contexts/report-context";
import { useAsyncOperation } from "../../hooks/useAsyncOperation";
import { debug } from "../../utils/debug";

const formatLocalDateTime = (utcDateString) => {
  if (!utcDateString) return "";

  try {
    // Parse the UTC date string and explicitly handle it as UTC
    const utcDate = new Date(utcDateString + "Z"); // Ensure UTC interpretation by appending Z if not present

    // Format in user's local timezone
    return utcDate.toLocaleString(undefined, {
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "numeric",
      minute: "2-digit",
      hour12: true,
      timeZoneName: "short",
    });
  } catch (error) {
    debug.error("Error formatting date:", error);
    return "";
  }
};

const getReportTypeLabel = (type) => {
  const labels = {
    ATTENDANCE_BY_EVENT: "Attendance Report (by Event)",
    ATTENDANCE_BY_DAY: "Attendance Report (by Day)",
    // Add other report types as needed
  };
  return labels[type] || type;
};

const ReportsPage = () => {
  const navigate = useNavigate();
  const { setMessage } = useReportContext();
  const selectedAccountId = useSelector(
    (state) => state.auth.selectedAccountId
  );

  debug.log("ReportsPage selectedAccountId:", selectedAccountId); // Track account ID

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);

  const theme = useTheme();

  const {
    data: reportsData,
    loading,
    error,
    execute: fetchReportsData,
  } = useAsyncOperation(async (params, signal) => {
    if (!params?.accountId) {
      return null;
    }
    try {
      const result = await fetchReports(
        params.accountId,
        {
          page: params.page,
          size: params.pageSize,
        },
        signal
      );
      return result;
    } catch (err) {
      // Transform network errors into user-friendly messages
      if (err?.code === "ERR_NETWORK") {
        throw new Error(
          "Unable to connect to server. Please check your connection and try again."
        );
      }
      throw err;
    }
  });

  useEffect(() => {
    debug.log("ReportsPage effect triggered", {
      selectedAccountId,
      page,
      pageSize,
      hasData: !!reportsData,
      isLoading: loading,
      hasError: !!error,
    });

    if (selectedAccountId) {
      debug.log("ReportsPage calling fetchReportsData");
      fetchReportsData({ accountId: selectedAccountId, page, pageSize });
    }
  }, [fetchReportsData, page, pageSize, selectedAccountId]);

  // Log state changes
  useEffect(() => {
    debug.log("ReportsPage data updated:", {
      hasData: !!reportsData,
      dataContent: reportsData?.content,
      loading,
      error,
    });
  }, [reportsData, loading, error]);

  // Handle report download
  const handleDownload = useCallback(
    async (report) => {
      if (!report.assetDownloadUrl) {
        setMessage({
          text: "Download URL not available",
          severity: "error",
          flag: true,
        });
        return;
      }

      try {
        // Extract format from report type or default to PDF
        const format = report.format?.toLowerCase() || "pdf";

        // Create a more descriptive filename with proper extension
        const timestamp = report.generatedDate
          ? new Date(report.generatedDate).toISOString().split("T")[0]
          : new Date().toISOString().split("T")[0];

        const filename = `${report.type.toLowerCase()}_report_${timestamp}.${format}`;

        await downloadReport(report.assetDownloadUrl, filename);
      } catch (error) {
        debug.error("Download error:", error);
        setMessage({
          text: "Failed to download report",
          severity: "error",
          flag: true,
        });
      }
    },
    [setMessage]
  );

  // Add refresh debouncing
  const lastRefreshTime = useRef(0);
  const refreshDebounceMs = 3000; // 3 seconds

  const handleRefresh = useCallback(() => {
    const now = Date.now();
    if (now - lastRefreshTime.current >= refreshDebounceMs) {
      debug.log("Refreshing reports data");
      fetchReportsData({ accountId: selectedAccountId, page, pageSize });
      lastRefreshTime.current = now;
    } else {
      debug.log("Refresh debounced");
    }
  }, [fetchReportsData, selectedAccountId, page, pageSize]);

  const columns = [
    {
      id: "type",
      label: "Type",
      flex: 1,
      valueGetter: (params) => getReportTypeLabel(params.row.type),
    },
    {
      id: "studentName",
      label: "Student",
      flex: 1,
      renderCell: (params) => (
        <Typography
          color={params.row.studentName ? "text.primary" : "text.secondary"}
        >
          {params.row.studentName || "—"}
        </Typography>
      ),
    },
    {
      id: "termName",
      label: "Term",
      flex: 1,
      renderCell: (params) => (
        <Typography
          color={params.row.termName ? "text.primary" : "text.secondary"}
        >
          {params.row.termName || "—"}
        </Typography>
      ),
    },
    {
      id: "status",
      label: "Status",
      flex: 1,
      renderCell: (params) => (
        <Typography
          color={
            params.value === REPORT_STATUS.FAILED
              ? "error"
              : params.value === REPORT_STATUS.COMPLETED
              ? "success"
              : "text.primary"
          }
        >
          {params.value}
        </Typography>
      ),
    },
    {
      id: "generatedDate",
      label: "Generated",
      flex: 1,
      valueGetter: (params) => formatLocalDateTime(params.row.generatedDate),
    },
    {
      id: "format",
      label: "Format",
      flex: 1,
      renderCell: (params) => (
        <Typography>{params.row.format?.toUpperCase() || "PDF"}</Typography>
      ),
    },
    {
      id: "actions",
      label: "Actions",
      flex: 1,
      renderCell: (params) => (
        <Button
          startIcon={<DownloadIcon />}
          onClick={() => handleDownload(params.row)}
          disabled={
            params.row.status !== REPORT_STATUS.COMPLETED ||
            !params.row.assetDownloadUrl
          }
        >
          Download
        </Button>
      ),
    },
  ];

  const getStatusColor = (status) => {
    switch (status) {
      case REPORT_STATUS.COMPLETED:
        return {
          color: theme.palette.success.main,
          bgcolor: theme.palette.success.lighter,
        };
      case REPORT_STATUS.FAILED:
        return {
          color: theme.palette.error.main,
          bgcolor: theme.palette.error.lighter,
        };
      case REPORT_STATUS.PROCESSING:
      case REPORT_STATUS.QUEUED:
        return {
          color: theme.palette.warning.main,
          bgcolor: theme.palette.warning.lighter,
        };
      default:
        return {
          color: theme.palette.text.secondary,
          bgcolor: theme.palette.grey[100],
        };
    }
  };

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          alignItems: { xs: "stretch", sm: "center" },
          justifyContent: "space-between",
          gap: { xs: 2, sm: 3 },
          mb: 4,
        }}
      >
        <Typography variant="h2">Reports</Typography>
        <Box
          sx={{
            display: "flex",
            gap: 2,
            flexDirection: { xs: "column", sm: "row" },
            width: { xs: "100%", sm: "auto" },
          }}
        >
          <Button
            variant="outlined"
            startIcon={<RefreshIcon />}
            onClick={handleRefresh}
            fullWidth={false}
            sx={{
              minWidth: { xs: "100%", sm: "auto" },
            }}
          >
            Refresh
          </Button>
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            onClick={() => navigate("/reports/generate")}
            fullWidth={false}
            sx={{
              minWidth: { xs: "100%", sm: "auto" },
            }}
          >
            Generate Report
          </Button>
        </Box>
      </Box>

      {reportsData?.content?.some(
        (report) => report.status === "QUEUED" || report.status === "PROCESSING"
      ) &&
        page === 0 && (
          <Box sx={{ mb: 2, p: 2, bgcolor: "info.light", borderRadius: 1 }}>
            <Typography>
              You have reports being generated. Click refresh to check their
              status.
            </Typography>
          </Box>
        )}

      <HsTable
        data={reportsData?.content || []}
        columns={columns}
        loading={loading}
        error={error?.message || error}
        page={page}
        rowsPerPage={pageSize}
        totalCount={reportsData?.totalElements || 0}
        onPageChange={setPage}
        onRowsPerPageChange={setPageSize}
        rowsPerPageOptions={[5, 10, 25, 50]}
        showPagination={true}
        renderDesktopRow={(row) => (
          <>
            {columns.map((column) => (
              <TableCell key={`${row.id}-${column.id}`}>
                {column.renderCell
                  ? column.renderCell({ row, value: row[column.id] })
                  : column.valueGetter
                  ? column.valueGetter({ row, value: row[column.id] })
                  : row[column.id]}
              </TableCell>
            ))}
          </>
        )}
        renderMobileRow={(row) => (
          <Box
            sx={{
              p: 2,
              borderBottom: `1px solid ${theme.palette.divider}`,
              "&:last-child": {
                borderBottom: "none",
              },
              bgcolor: "background.paper",
            }}
          >
            <Stack spacing={1.5}>
              {/* Report Type and Status */}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Box sx={{ display: "flex", alignItems: "center", gap: 1.5 }}>
                  <FileIcon color="primary" />
                  <Typography variant="subtitle1" color="text.primary">
                    {getReportTypeLabel(row.type)}
                  </Typography>
                </Box>
                <Chip
                  label={row.status}
                  size="small"
                  sx={{
                    ...getStatusColor(row.status),
                    fontWeight: 500,
                    minWidth: 90,
                    textAlign: "center",
                  }}
                />
              </Box>

              {/* Student and Term Info */}
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr",
                  gap: 2,
                  color: "text.secondary",
                }}
              >
                <Typography
                  variant="body2"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: 0.5,
                    color: row.studentName ? "text.primary" : "text.secondary",
                  }}
                >
                  Student: {row.studentName || "—"}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: 0.5,
                    color: row.termName ? "text.primary" : "text.secondary",
                  }}
                >
                  Term: {row.termName || "—"}
                </Typography>
              </Box>

              {/* Generated Date and Format */}
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: "1fr auto",
                  gap: 2,
                  alignItems: "center",
                  color: "text.secondary",
                  mt: 0.5,
                }}
              >
                <Typography variant="body2">
                  {formatLocalDateTime(row.generatedDate)}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{
                    px: 1.5,
                    py: 0.5,
                    bgcolor: "grey.50",
                    borderRadius: 1,
                    fontSize: "0.75rem",
                    fontWeight: 500,
                  }}
                >
                  {row.format?.toUpperCase() || "PDF"}
                </Typography>
              </Box>

              {/* Download Button */}
              <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                <Button
                  size="small"
                  startIcon={<DownloadIcon />}
                  onClick={() => handleDownload(row)}
                  disabled={
                    row.status !== REPORT_STATUS.COMPLETED ||
                    !row.assetDownloadUrl
                  }
                  sx={{
                    mt: 1,
                    minWidth: 110,
                    "&:not(:disabled)": {
                      color: theme.palette.primary.main,
                      borderColor: theme.palette.primary.main,
                    },
                  }}
                  variant="outlined"
                >
                  Download
                </Button>
              </Box>
            </Stack>
          </Box>
        )}
      />
    </Box>
  );
};

debug.log("ReportsPage module exports");

export default ReportsPage;

import React, { createContext, useContext, useMemo } from "react";
import { useQuickstartWizardState } from "../hooks/useQuickstartWizardState";

export const QuickstartWizardContext = createContext(null);

export const QuickstartWizardProvider = ({ children, steps }) => {
  const {
    activeStep,
    wizardData,
    stepValidation,
    setActiveStep,
    updateWizardData,
    updateStepValidation,
    validateStep,
    handleStepChange,
    clearWizardData,
  } = useQuickstartWizardState(steps);

  const value = useMemo(
    () => ({
      activeStep,
      wizardData,
      stepValidation,
      setActiveStep,
      updateWizardData,
      updateStepValidation,
      validateStep,
      handleStepChange,
      clearWizardData,
    }),
    [
      activeStep,
      wizardData,
      stepValidation,
      setActiveStep,
      updateWizardData,
      updateStepValidation,
      validateStep,
      handleStepChange,
      clearWizardData,
    ]
  );

  // Don't render children until state is initialized
  if (!value) {
    return null;
  }

  return (
    <QuickstartWizardContext.Provider value={value}>
      {children}
    </QuickstartWizardContext.Provider>
  );
};

export const useQuickstartWizardContext = () => {
  const context = useContext(QuickstartWizardContext);
  if (!context) {
    throw new Error(
      "useQuickstartWizardContext must be used within a QuickstartWizardProvider"
    );
  }
  return context;
};

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  Alert,
  Box,
  Breadcrumbs,
  Button,
  Chip,
  CircularProgress,
  Grid,
  Link,
  Paper,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getOrder } from "../../api/ordersApi";
import { useAsyncOperation } from "../../hooks/useAsyncOperation";

const OrderDetails = () => {
  const { orderId } = useParams();
  const navigate = useNavigate();

  const {
    data: order,
    loading,
    error,
    execute: fetchOrder,
  } = useAsyncOperation(getOrder, {
    onError: (error) => {
      console.error("Failed to fetch order:", error);
    },
  });

  useEffect(() => {
    const loadOrder = async () => {
      if (orderId) {
        try {
          await fetchOrder(orderId);
        } catch (error) {
          console.error("Error loading order:", error);
        }
      }
    };

    loadOrder();
  }, [orderId, fetchOrder]);

  const getStatusColor = (status) => {
    switch (status?.toLowerCase()) {
      case "delivered":
        return "success";
      case "processing":
      case "pending":
        return "warning";
      case "cancelled":
        return "error";
      default:
        return "default";
    }
  };

  const formatCurrency = (amount) => {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    }).format(amount);
  };

  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
    });
  };

  const formatAddress = (address) => {
    const parts = [
      address.street1,
      address.street2,
      `${address.city}, ${address.stateCode} ${address.postcode}`,
      address.countryCode,
    ].filter(Boolean);
    return parts;
  };

  const calculateOrderTotal = (order) => {
    return order.total + order.shippingCost + order.totalTax;
  };

  if (loading) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ mt: 2 }}>
        <Alert severity="error">{error.message}</Alert>
      </Box>
    );
  }

  if (!order) {
    return null;
  }

  return (
    <Box>
      <Box sx={{ mb: 4 }}>
        <Button
          startIcon={<ArrowBackIcon />}
          onClick={() => navigate("/orders")}
          sx={{ mb: 2 }}
        >
          Back to Orders
        </Button>
        <Breadcrumbs>
          <Link
            color="inherit"
            onClick={() => navigate("/orders")}
            sx={{ cursor: "pointer" }}
          >
            Orders
          </Link>
          <Typography color="text.primary">Order {order.id}</Typography>
        </Breadcrumbs>
      </Box>

      <Grid container spacing={3}>
        <Grid item xs={12} md={8}>
          <Paper elevation={0} sx={{ p: 3, mb: 3 }}>
            <Typography variant="h5" gutterBottom>
              Order Details
            </Typography>
            <Box sx={{ mt: 2 }}>
              <Typography variant="body2" color="text.secondary">
                Order ID
              </Typography>
              <Typography variant="body1">{order.id}</Typography>
            </Box>
            <Box sx={{ mt: 2 }}>
              <Typography variant="body2" color="text.secondary">
                Order Date
              </Typography>
              <Typography variant="body1">
                {formatDate(order.createdAt)}
              </Typography>
            </Box>
            <Box sx={{ mt: 2 }}>
              <Typography variant="body2" color="text.secondary">
                Status
              </Typography>
              <Chip
                label={order.status}
                color={getStatusColor(order.status)}
                size="small"
                sx={{ mt: 0.5 }}
              />
            </Box>
            {order.printOnDemandOrder?.trackingNumber && (
              <Box sx={{ mt: 2 }}>
                <Typography variant="body2" color="text.secondary">
                  Tracking Number
                </Typography>
                <Button
                  variant="text"
                  href={`https://track.printify.com/${order.printOnDemandOrder.trackingNumber}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  sx={{ mt: 0.5, p: 0 }}
                >
                  {order.printOnDemandOrder.trackingNumber}
                </Button>
              </Box>
            )}
          </Paper>
        </Grid>

        <Grid item xs={12} md={4}>
          <Paper elevation={0} sx={{ p: 3, mb: 3 }}>
            <Typography variant="h5" gutterBottom>
              Shipping Address
            </Typography>
            {order.shippingAddress && (
              <Box sx={{ mt: 2 }}>
                <Typography variant="body1" sx={{ fontWeight: 500 }}>
                  {order.shippingAddress.name}
                </Typography>
                {order.shippingAddress.isBusiness && (
                  <Typography variant="body2" color="text.secondary">
                    Business Address
                  </Typography>
                )}
                {formatAddress(order.shippingAddress).map((line, index) => (
                  <Typography key={index} variant="body1">
                    {line}
                  </Typography>
                ))}
                {order.shippingAddress.phoneNumber && (
                  <Typography variant="body1" sx={{ mt: 1 }}>
                    {order.shippingAddress.phoneNumber}
                  </Typography>
                )}
              </Box>
            )}
          </Paper>
        </Grid>

        <Grid item xs={12}>
          <Paper elevation={0} sx={{ p: 3 }}>
            <Typography variant="h5" gutterBottom>
              Order Items
            </Typography>
            <Grid container spacing={3}>
              {order.items.map((item) => (
                <Grid item xs={12} key={item.id}>
                  <Paper elevation={0} sx={{ p: 2, bgcolor: "grey.50" }}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <Typography variant="subtitle1">{item.name}</Typography>
                        <Typography variant="body2" color="text.secondary">
                          {item.productType}
                          {item.numberOfPages &&
                            ` - ${item.numberOfPages} pages`}
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sm={2}>
                        <Typography variant="body2" color="text.secondary">
                          Quantity
                        </Typography>
                        <Typography variant="body1">{item.quantity}</Typography>
                      </Grid>
                      <Grid item xs={6} sm={4} sx={{ textAlign: "right" }}>
                        <Typography variant="body2" color="text.secondary">
                          Price
                        </Typography>
                        <Typography variant="subtitle1">
                          {formatCurrency(item.totalPrice)}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
              ))}
              <Grid item xs={12}>
                <Box sx={{ mt: 2, textAlign: "right" }}>
                  <Typography variant="body2" sx={{ mb: 1 }}>
                    Subtotal: {formatCurrency(order.total)}
                  </Typography>
                  <Typography variant="body2" sx={{ mb: 1 }}>
                    Shipping: {formatCurrency(order.shippingCost)}
                  </Typography>
                  <Typography variant="body2" sx={{ mb: 1 }}>
                    Tax: {formatCurrency(order.totalTax)}
                  </Typography>
                  <Typography variant="h6">
                    Total: {formatCurrency(calculateOrderTotal(order))}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default OrderDetails;

import apiUrl from "../config/config.js";
import { api } from "./api.js";

export const getStudentsByTermId = async (termId, accountId, signal) => {
  const response = await api.get(
    `${apiUrl}/api/v1/student/term/${termId}/students`,
    {
      params: {
        account_id: accountId,
      },
      signal,
    }
  );
  return response.data;
};

/**
 * Fetches terms associated with a student based on their class instances.
 * Returns a list of TermSummary objects containing id, name, startDate, and endDate.
 * Terms are sorted by most recent first and limited to 100 results.
 */
export const getStudentTerms = async (studentId, accountId, signal) => {
  const response = await api.get(
    `${apiUrl}/api/v1/terms/by-student/${studentId}`,
    {
      params: {
        account_id: accountId,
      },
      signal,
    }
  );

  // Transform dates from ISO strings to Date objects for consistency
  return response.data.map((term) => ({
    ...term,
    startDate: new Date(term.startDate),
    endDate: new Date(term.endDate),
  }));
};

import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  TableCell,
  Typography,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  deleteClassLesson,
  getLessonPlanLessons,
} from "../../api/classLessonsApi";
import {
  getClassInstances,
  getLessonPlanById,
  removeLessonPlanFromTerm,
  updateLessonPlan,
} from "../../api/lessonPlanApi";
import { fetchActiveAndFutureTerms } from "../../api/termApi";
import { ROLE_STUDENT, getUsers } from "../../api/userApi";
import HsModal from "../../components/common/HsModal";
import HsTable from "../../components/common/HsTable";
import LoadingIndicator from "../../components/common/LoadingIndicator";
import { useReportContext } from "../../contexts/report-context";
import { useAsyncOperation } from "../../hooks/useAsyncOperation";
import { usePlanRestriction } from "../../hooks/usePlanRestriction";
import { updateLessonPlan as updateLessonPlanAction } from "../../redux/lessonPlanSlice";
import DeleteClassEnrollmentModal from "../users/DeleteClassEnrollmentModal";
import { removeLessonPlan } from "./apiCalls";
import ApplyToTermModal from "./ApplyToTermModal";
import CreateLessonModal from "./CreateLessonModal";
import CreateLessonPlanModal from "./CreateLessonPlanModal";

const LessonPlansDetailsPage = () => {
  const { id } = useParams();
  const { setMessage } = useReportContext();
  const dispatch = useDispatch();
  const selectedAccountId = useSelector(
    (state) => state.auth.selectedAccountId
  );
  const navigate = useNavigate();

  const [selectedClassLesson, setSelectedClassLesson] = useState(null);
  const [selectedClassInstance, setSelectedClassInstance] = useState(null);
  const [openApplyModal, setOpenApplyModal] = useState(false);
  const [openRemoveModal, setOpenRemoveModal] = useState(false);
  const [openLessonModal, setOpenLessonModal] = useState(false);
  const [openClassModal, setOpenClassModal] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedInstance, setSelectedInstance] = useState(null);
  const [showDeleteEnrollmentModal, setShowDeleteEnrollmentModal] =
    useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [anchorElActions, setAnchorElActions] = useState(null);

  const {
    data: lessonPlanDetails,
    execute: fetchLessonPlanDetails,
    loading: lessonPlanLoading,
    error: lessonPlanError,
  } = useAsyncOperation(getLessonPlanById);

  const {
    data: classLessons,
    execute: fetchClassLessons,
    loading: lessonsLoading,
  } = useAsyncOperation(getLessonPlanLessons);

  const {
    data: classInstances,
    execute: fetchClassInstances,
    loading: instancesLoading,
  } = useAsyncOperation(getClassInstances);

  const {
    data: terms,
    execute: fetchTerms,
    loading: termsLoading,
  } = useAsyncOperation(fetchActiveAndFutureTerms);

  const {
    data: students,
    execute: fetchStudents,
    loading: studentsLoading,
  } = useAsyncOperation(getUsers);

  const { execute: executeRemoveLessonPlan } = useAsyncOperation(
    removeLessonPlanFromTerm
  );

  const { execute: executeUpdateLessonPlan } =
    useAsyncOperation(updateLessonPlan);

  const { handlePlanRestrictionError } = usePlanRestriction();

  const fetchData = useCallback(
    async (signal) => {
      if (!id || !selectedAccountId) return;

      try {
        const results = await Promise.all([
          fetchLessonPlanDetails(id, signal),
          fetchClassLessons(id, signal),
          fetchClassInstances(id, 0, 10, signal),
          fetchTerms(signal),
          fetchStudents(selectedAccountId, ROLE_STUDENT, signal),
        ]);

        if (!results[0]) {
          throw new Error("Lesson plan not found");
        }
      } catch (error) {
        if (error.name !== "AbortError") {
          console.error("Error fetching data:", error);
          setMessage({
            text: error.message || "Failed to fetch class details",
            severity: "error",
            flag: true,
          });
        }
      }
    },
    [
      id,
      selectedAccountId,
      fetchLessonPlanDetails,
      fetchClassLessons,
      fetchClassInstances,
      fetchTerms,
      fetchStudents,
      setMessage,
    ]
  );

  const { execute: executeDataFetch } = useAsyncOperation(fetchData);

  useEffect(() => {
    if (id && selectedAccountId) {
      executeDataFetch();
    }
  }, [id, selectedAccountId, executeDataFetch]);

  const isLoading =
    lessonPlanLoading ||
    lessonsLoading ||
    instancesLoading ||
    termsLoading ||
    studentsLoading;

  useEffect(() => {
    if (lessonPlanError) {
      setMessage({
        text: lessonPlanError.message || "Failed to load lesson plan",
        severity: "error",
        flag: true,
      });
    }
  }, [lessonPlanError, setMessage]);

  const handleOpenApplyModal = () => setOpenApplyModal(true);
  const handleCloseApplyModal = () => setOpenApplyModal(false);

  const handleOpenRemoveConfirmModal = useCallback((rowData) => {
    setSelectedClassInstance(rowData);
    setOpenRemoveModal(true);
  }, []);

  const handleCloseRemoveConfirmModal = () => {
    setSelectedClassInstance(null);
    setOpenRemoveModal(false);
  };

  const handleApplyClassToTerm = () => {
    fetchData();
    setOpenApplyModal(false);
  };

  const handleOpenLessonModal = () => setOpenLessonModal(true);
  const handleCloseLessonModal = () => {
    setSelectedClassLesson(null);
    setOpenLessonModal(false);
  };

  const handleCreateLesson = () => {
    fetchData();
    handleCloseLessonModal();
  };

  const handleOpenLessonEditModal = useCallback((rowData) => {
    setSelectedClassLesson(rowData);
    setOpenLessonModal(true);
  }, []);

  const handleOpenEditModal = () => setOpenClassModal(true);
  const handleCloseClassModal = () => setOpenClassModal(false);

  const handleError = useCallback(
    (message, error) => {
      if (!handlePlanRestrictionError(error)) {
        setMessage({
          text: message,
          severity: "error",
          flag: true,
        });
      }
    },
    [handlePlanRestrictionError, setMessage]
  );

  const handleUpdateLessonPlan = async (id, updatedData) => {
    try {
      const dataWithAccountId = {
        ...updatedData,
        accountId: selectedAccountId,
      };
      const updatedClass = await executeUpdateLessonPlan(id, dataWithAccountId);
      dispatch(updateLessonPlanAction(updatedClass));
      fetchLessonPlanDetails(id);
      handleCloseClassModal();
      setMessage({
        text: "Lesson plan updated successfully",
        severity: "success",
        flag: true,
      });
    } catch (error) {
      handleError("Failed to update lesson plan", error);
    }
  };

  const handleRemoveLesson = useCallback(
    async (id) => {
      try {
        await deleteClassLesson(id);
        fetchData();
        setMessage({
          text: "Removed the lesson successfully!",
          severity: "success",
          flag: true,
        });
      } catch (err) {
        setMessage({
          text: "Failed to remove the lesson",
          severity: "error",
          flag: true,
        });
      }
    },
    [fetchData, setMessage]
  );

  const handleMenuClick = useCallback((event, instance) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setSelectedInstance(instance);
  }, []);

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleOpenActionsMenu = (event) => {
    setAnchorElActions(event.currentTarget);
  };

  const handleCloseActionsMenu = () => {
    setAnchorElActions(null);
  };

  const handleEditClick = () => {
    handleCloseActionsMenu();
    handleOpenEditModal();
  };

  const handleDeleteClick = () => {
    handleMenuClose();
    setShowDeleteEnrollmentModal(true);
  };

  const handleCloseEnrollmentDeleteModal = () => {
    if (!instancesLoading) {
      setShowDeleteEnrollmentModal(false);
      setSelectedInstance(null);
    }
  };

  const handleOpenLessonPlanDeleteModal = () => setOpenDeleteModal(true);
  const handleCloseLessonPlanDeleteModal = () => setOpenDeleteModal(false);

  const handleDeleteLessonPlan = async () => {
    try {
      await removeLessonPlan(id);
      setMessage({
        text: "Lesson plan deleted successfully",
        severity: "success",
        flag: true,
      });
      navigate("/lesson-plans");
    } catch (error) {
      handleError("Failed to delete lesson plan", error);
    }
  };

  const lessonColumns = [
    { id: "title", label: "Title" },
    { id: "description", label: "Description" },
    { id: "workItems", label: "Work Items" },
    { id: "lessonNumber", label: "Lesson Number" },
    { id: "durationInMinutes", label: "Duration (minutes)" },
    { id: "action", label: "Action" },
  ];

  const enrollmentColumns = [
    { id: "studentName", label: "Student" },
    { id: "termName", label: "Term" },
    { id: "action", label: "" },
  ];

  const renderLessonDesktopRow = useCallback(
    (row, columns) => (
      <>
        {columns.map((column) => (
          <TableCell key={column.id}>
            {column.id === "workItems" ? (
              <Box>
                {row[column.id]?.map((item, index) => (
                  <Typography
                    key={index}
                    variant="body2"
                    color="text.secondary"
                  >
                    • {item.description}
                  </Typography>
                ))}
              </Box>
            ) : column.id === "action" ? (
              <Stack direction="row" spacing={1}>
                <Button
                  variant="outlined"
                  onClick={() => handleOpenLessonEditModal(row)}
                >
                  Edit
                </Button>
                <Button
                  variant="outlined"
                  color="error"
                  onClick={() => handleRemoveLesson(row.id)}
                >
                  Delete
                </Button>
              </Stack>
            ) : (
              row[column.id]
            )}
          </TableCell>
        ))}
      </>
    ),
    [handleOpenLessonEditModal, handleRemoveLesson]
  );

  const renderEnrollmentDesktopRow = useCallback(
    (row, columns) => (
      <>
        {columns.map((column) => (
          <TableCell key={column.id}>
            {column.id === "action" ? (
              <IconButton
                size="small"
                onClick={(e) => handleMenuClick(e, row)}
                sx={{ ml: "auto" }}
              >
                <MoreVertIcon fontSize="small" />
              </IconButton>
            ) : (
              row[column.id]
            )}
          </TableCell>
        ))}
      </>
    ),
    [handleMenuClick]
  );

  const handleRemoveFromTerm = async (termId, studentId) => {
    try {
      await executeRemoveLessonPlan(id, termId, studentId, selectedAccountId);

      await fetchData();

      setMessage({
        text: "Successfully removed class from term",
        severity: "success",
        flag: true,
      });
    } catch (err) {
      setMessage({
        text: err.message || "Failed to remove class from term",
        severity: "error",
        flag: true,
      });
    }
  };

  if (isLoading) {
    return <LoadingIndicator message="Loading class details..." />;
  }

  if (!lessonPlanDetails) {
    return (
      <Box sx={{ p: 3 }}>
        <Typography color="error" variant="h6" align="center">
          Unable to load lesson plan details. Please try again later.
        </Typography>
      </Box>
    );
  }

  return (
    <Box>
      <Box
        sx={{
          mb: 4,
          display: "flex",
          alignItems: "center",
          gap: 2,
        }}
      >
        <Typography variant="h4" sx={{ flex: 1 }}>
          {lessonPlanDetails.name}
        </Typography>
        <IconButton onClick={handleOpenActionsMenu} sx={{ ml: "auto" }}>
          <MoreVertIcon />
        </IconButton>
      </Box>

      {/* Actions Menu */}
      <Menu
        anchorEl={anchorElActions}
        open={Boolean(anchorElActions)}
        onClose={handleCloseActionsMenu}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuItem onClick={handleEditClick}>
          <EditIcon fontSize="small" sx={{ mr: 1 }} />
          Edit Lesson Plan
        </MenuItem>
        <Divider />
        <MenuItem onClick={handleDeleteClick} sx={{ color: "error.main" }}>
          <DeleteIcon fontSize="small" sx={{ mr: 1 }} />
          Delete Lesson Plan
        </MenuItem>
      </Menu>

      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography variant="bodyFV">
            {lessonPlanDetails.description}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="bodyFV">
            Subject: {lessonPlanDetails.subject}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="bodyFV">
            Duration: {lessonPlanDetails.durationPerLesson} minutes
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="bodyFV">
            Frequency: {lessonPlanDetails.lessonFrequency}
            {lessonPlanDetails.isOngoing ? " - Ongoing" : ""}
          </Typography>
        </Grid>
      </Grid>

      {/* Lessons Section */}
      {!lessonPlanDetails.isOngoing && (
        <Grid container spacing={2} sx={{ mt: 3 }}>
          <Grid item xs={12}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="h6">Lessons</Typography>
              <Button variant="contained" onClick={handleOpenLessonModal}>
                Create Lesson
              </Button>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <HsTable
              columns={lessonColumns}
              data={classLessons?.content || []}
              totalCount={classLessons?.totalElements || 0}
              page={0}
              rowsPerPage={(classLessons?.content || []).length}
              loading={!classLessons}
              onPageChange={() => {}}
              onRowsPerPageChange={() => {}}
              renderDesktopRow={renderLessonDesktopRow}
              showPagination={false}
              onRowClick={null}
              disableRowHover={true}
              emptyMessage="No lessons created yet"
            />
          </Grid>
        </Grid>
      )}

      {/* Enrollment Section */}
      <Grid container spacing={2} sx={{ mt: 3 }}>
        <Grid item xs={12}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h6">Enrollment</Typography>
            <Button variant="contained" onClick={handleOpenApplyModal}>
              Enroll Student
            </Button>
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <HsTable
            columns={enrollmentColumns}
            data={
              classInstances?.content?.flatMap((instance) =>
                instance.students.map((student) => ({
                  id: `${instance.termSummary.id}-${student.id}`,
                  studentId: student.id,
                  studentName: `${student.firstName} ${student.lastName}`,
                  termId: instance.termSummary.id,
                  termName: instance.termSummary.name,
                }))
              ) || []
            }
            totalCount={(classInstances?.content || []).length}
            page={0}
            rowsPerPage={(classInstances?.content || []).length}
            loading={!classInstances}
            onPageChange={() => {}}
            onRowsPerPageChange={() => {}}
            renderDesktopRow={renderEnrollmentDesktopRow}
            showPagination={false}
            onRowClick={null}
            disableRowHover={true}
            emptyMessage="No students enrolled yet"
          />
        </Grid>
      </Grid>

      {/* Menu for enrollment actions */}
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        PaperProps={{
          sx: {
            mt: 1,
            "& .MuiMenuItem-root": {
              minHeight: 48,
              px: 2,
            },
          },
        }}
      >
        <MenuItem
          onClick={handleDeleteClick}
          sx={{
            color: "error.main",
            "&:hover": {
              backgroundColor: "error.lighter",
            },
            minWidth: 200,
          }}
        >
          <DeleteIcon sx={{ fontSize: 20, mr: 1 }} />
          Un-enroll From Class
        </MenuItem>
      </Menu>

      <CreateLessonPlanModal
        open={openClassModal}
        handleUpdate={handleUpdateLessonPlan}
        handleClose={handleCloseClassModal}
        data={lessonPlanDetails}
      />
      <ApplyToTermModal
        open={openApplyModal}
        lessonPlanId={id}
        lessonPlanDetails={lessonPlanDetails}
        terms={terms?.data || []}
        students={students || []}
        handleClose={handleCloseApplyModal}
        handleApply={handleApplyClassToTerm}
      />
      <CreateLessonModal
        open={openLessonModal}
        lessonPlanId={id}
        data={selectedClassLesson}
        handleCreate={handleCreateLesson}
        handleClose={handleCloseLessonModal}
      />

      {showDeleteEnrollmentModal && selectedInstance && (
        <DeleteClassEnrollmentModal
          open={showDeleteEnrollmentModal}
          handleClose={handleCloseEnrollmentDeleteModal}
          classInstance={{
            name: lessonPlanDetails.name,
            lessonPlanSummary: {
              id: id,
            },
            termSummary: {
              id: selectedInstance.termId,
              name: selectedInstance.termName,
            },
          }}
          studentId={selectedInstance.studentId}
          onSuccess={async () => {
            await fetchData();
            setShowDeleteEnrollmentModal(false);
            setSelectedInstance(null);
          }}
        />
      )}

      <HsModal
        open={openDeleteModal}
        handleClose={handleCloseLessonPlanDeleteModal}
        title="Delete Lesson Plan"
      >
        <Box sx={{ display: "flex", flexDirection: "column", gap: 3 }}>
          <Typography>
            Are you sure you want to delete this lesson plan? This action cannot
            be undone.
          </Typography>

          {classInstances?.content?.length > 0 && (
            <Typography color="error">
              Warning: This lesson plan has active enrollments. Deleting it will
              result in permanent data loss and possibly broken enrollments.
            </Typography>
          )}

          <Box>
            <Divider sx={{ mb: 3 }} />
            <Box sx={{ display: "flex", gap: 2, justifyContent: "flex-end" }}>
              <Button onClick={handleCloseLessonPlanDeleteModal}>Cancel</Button>
              <Button
                variant="contained"
                color="error"
                onClick={handleDeleteLessonPlan}
              >
                Delete Lesson Plan
              </Button>
            </Box>
          </Box>
        </Box>
      </HsModal>
    </Box>
  );
};

export default LessonPlansDetailsPage;

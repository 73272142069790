import { Box, Grid, Paper, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { usePlannerWizardContext } from "../../contexts/planner-wizard-context";

const PlannerIntroduction = () => {
  const { updateStepValidation, activeStep } = usePlannerWizardContext();

  // Set validation to true when component mounts or when active step changes
  useEffect(() => {
    if (activeStep === 0) {
      updateStepValidation(0, true); // Explicitly set step index 0 to valid
    }
  }, [updateStepValidation, activeStep]);

  return (
    <Box>
      <Grid container spacing={4} alignItems="center">
        <Grid item xs={12} md={6}>
          <Box sx={{ position: "relative" }}>
            <Paper
              elevation={3}
              sx={{
                borderRadius: 2,
                overflow: "hidden",
                aspectRatio: "8.5/11",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                position: "relative",
                p: 0,
              }}
            >
              <Box
                component="img"
                src="/assets/images/girl_reading.jpg"
                alt="Custom Homeschool Planner"
                sx={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  borderRadius: "inherit",
                }}
              />
            </Paper>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="h4" gutterBottom color="primary" sx={{ mb: 3 }}>
            Your Custom Homeschool Planner
          </Typography>
          <Typography variant="h6" gutterBottom color="secondary">
            Beautifully Crafted Just for Your Student
          </Typography>
          <Box sx={{ my: 3 }}>
            <Typography variant="body1" paragraph>
              Transform your homeschool planning into a beautiful, tangible
              experience with our premium custom-printed planner. Each planner
              is individually crafted to reflect your student's unique learning
              journey.
            </Typography>
            <Typography variant="body1" paragraph>
              <strong>What makes our planner special:</strong>
            </Typography>
            <Box component="ul" sx={{ pl: 2 }}>
              <Typography component="li" sx={{ mb: 1 }}>
                Personalized cover featuring your student's photo and
                information
              </Typography>
              <Typography component="li" sx={{ mb: 1 }}>
                Premium quality paper that's perfect for writing
              </Typography>
              <Typography component="li" sx={{ mb: 1 }}>
                Professional coil binding for durability and easy page turning
              </Typography>
              <Typography component="li" sx={{ mb: 1 }}>
                Custom content aligned with your student's classes and term
                schedule
              </Typography>
              <Typography component="li" sx={{ mb: 1 }}>
                Thoughtfully designed layouts for effective planning
              </Typography>
            </Box>
          </Box>
          <Box sx={{ mt: 4, textAlign: "center" }}>
            <Typography variant="subtitle1" color="primary" sx={{ mb: 2 }}>
              Ready to create your custom planner?
            </Typography>
            <Typography variant="body2" color="text.secondary" sx={{ mb: 3 }}>
              We'll guide you through a simple process to create the perfect
              planner for your student.
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default PlannerIntroduction;

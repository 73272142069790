// SubscriptionError.js
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { Box, Button, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

const SubscriptionError = () => {
  const navigate = useNavigate();

  const handleTryAgain = () => {
    // Navigate back to the subscription page or home page
    navigate("/login"); // or wherever your subscription process starts
  };

  const handleContactSupport = () => {
    // You can replace this with your actual support email or page
    window.location.href = "mailto:support@homeschoolplanner.com";
  };

  return (
    <Box sx={{ p: 4, maxWidth: "800px" }}>
      <ErrorOutlineIcon color="error" sx={{ fontSize: 60, mb: 2 }} />
      <Typography variant="h4" gutterBottom>
        Subscription Error
      </Typography>
      <Typography variant="body1" paragraph>
        We're sorry, but there was an error processing your subscription. This
        could be due to a temporary issue or a problem with your payment method.
      </Typography>
      <Typography variant="body1" paragraph>
        You can try again or contact our support team for assistance.
      </Typography>
      <Box sx={{ mt: 4 }}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleTryAgain}
          sx={{ mr: 2 }}
        >
          Try Again
        </Button>
        <Button
          variant="outlined"
          color="secondary"
          onClick={handleContactSupport}
        >
          Contact Support
        </Button>
      </Box>
    </Box>
  );
};

export default SubscriptionError;

import CloseIcon from "@mui/icons-material/Close";
import MinimizeIcon from "@mui/icons-material/Remove";
import SendIcon from "@mui/icons-material/Send";
import SmartToyIcon from "@mui/icons-material/SmartToy";
import {
  Box,
  IconButton,
  InputAdornment,
  Paper,
  Stack,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { sendMessage } from "../../api/aiAssistantApi";
import {
  addMessage,
  minimizeAssistant,
  setError,
  setLoading,
  setTyping,
} from "../../redux/aiAssistantSlice";

const ChatWindow = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { messages, isLoading, isTyping } = useSelector(
    (state) => state.aiAssistant
  );
  const [input, setInput] = useState("");
  const messagesEndRef = useRef(null);
  const abortControllerRef = useRef(null);

  // Cleanup any pending requests when component unmounts
  useEffect(() => {
    return () => {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
    };
  }, []);

  // Scroll to bottom when new messages arrive
  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  // Add greeting message when chat opens
  useEffect(() => {
    if (messages.length === 0) {
      dispatch(
        addMessage({
          type: "assistant",
          content:
            "Hi! I'm your AI Scheduling Assistant. I can help you manage your calendar and schedule events. For example, 'My daughter has a doctor's appointment next Tuesday at 1:00PM. Add it to my calendar.'",
          timestamp: new Date().toISOString(),
        })
      );
    }
  }, [dispatch, messages.length]);

  const handleSend = async () => {
    if (!input.trim()) return;

    // Cancel any existing request
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }

    // Create new AbortController for this request
    abortControllerRef.current = new AbortController();

    const userMessage = {
      type: "user",
      content: input.trim(),
      timestamp: new Date().toISOString(),
    };

    dispatch(addMessage(userMessage));
    setInput("");
    dispatch(setLoading(true));
    dispatch(setTyping(true));

    try {
      console.log("Sending message to API:", input);
      const response = await sendMessage(
        input,
        abortControllerRef.current.signal
      );
      console.log("API Response:", response);

      dispatch(setTyping(false));
      dispatch(
        addMessage({
          type: "assistant",
          content: response.data.response,
          timestamp: response.data.timestamp,
        })
      );
    } catch (error) {
      console.error("API Error:", error);
      // Don't show error for cancelled requests
      if (error.name === "AbortError") {
        return;
      }
      dispatch(setError(error.message));
      dispatch(
        addMessage({
          type: "assistant",
          content: error.message,
          timestamp: new Date().toISOString(),
          isError: true,
        })
      );
    } finally {
      dispatch(setLoading(false));
      dispatch(setTyping(false));
      abortControllerRef.current = null;
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      handleSend();
    }
    if (e.key === "Escape") {
      dispatch(minimizeAssistant());
    }
  };

  return (
    <Paper
      elevation={6}
      sx={{
        position: "fixed",
        bottom: theme.spacing(3),
        right: theme.spacing(3),
        width: 400,
        height: 600,
        display: "flex",
        flexDirection: "column",
        borderRadius: 2,
        overflow: "hidden",
        transition: "all 0.3s ease-in-out",
        zIndex: theme.zIndex.drawer + 2,
        [theme.breakpoints.down("sm")]: {
          width: "calc(100% - 32px)",
          height: "calc(100% - 100px)",
          bottom: 16,
          right: 16,
        },
      }}
    >
      {/* Header */}
      <Box
        sx={{
          bgcolor: theme.palette.purple.main,
          p: 2,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Stack direction="row" spacing={1} alignItems="center">
          <SmartToyIcon sx={{ color: "white" }} />
          <Typography
            variant="subtitle1"
            sx={{ color: "white", fontWeight: "bold" }}
          >
            AI Scheduling Assistant
          </Typography>
        </Stack>
        <Stack direction="row" spacing={1}>
          <IconButton
            size="small"
            onClick={() => dispatch(minimizeAssistant())}
            sx={{ color: "white" }}
          >
            <MinimizeIcon />
          </IconButton>
          <IconButton
            size="small"
            onClick={() => dispatch(minimizeAssistant())}
            sx={{ color: "white" }}
          >
            <CloseIcon />
          </IconButton>
        </Stack>
      </Box>

      {/* Messages */}
      <Box
        sx={{
          flex: 1,
          overflowY: "auto",
          p: 2,
          bgcolor: "background.default",
        }}
      >
        {messages.map((message, index) => (
          <Box
            key={index}
            sx={{
              mb: 2,
              display: "flex",
              flexDirection: "column",
              alignItems: message.type === "user" ? "flex-end" : "flex-start",
            }}
          >
            <Paper
              sx={{
                p: 2,
                maxWidth: "80%",
                bgcolor:
                  message.type === "user"
                    ? theme.palette.purple.main
                    : message.isError
                    ? theme.palette.error.light
                    : "background.paper",
                color: message.type === "user" ? "white" : "text.primary",
                borderRadius: 2,
              }}
            >
              <Typography variant="body2">{message.content}</Typography>
            </Paper>
          </Box>
        ))}
        {isTyping && (
          <Box sx={{ display: "flex", gap: 1, p: 2 }}>
            <span className="typing-dot" />
            <span className="typing-dot" />
            <span className="typing-dot" />
          </Box>
        )}
        <div ref={messagesEndRef} />
      </Box>

      {/* Input */}
      <Box sx={{ p: 2, bgcolor: "background.paper" }}>
        <TextField
          fullWidth
          multiline
          maxRows={4}
          placeholder="Type your message..."
          value={input}
          onChange={(e) => setInput(e.target.value)}
          onKeyDown={handleKeyPress}
          disabled={isLoading}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={handleSend}
                  disabled={isLoading || !input.trim()}
                >
                  <SendIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Box>
    </Paper>
  );
};

export default ChatWindow;

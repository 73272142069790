import React, { createContext, useContext, useState } from "react";

const defaultDrawerContext = {
  isOpen: false,
  toggleDrawer: () => {},
  setIsOpen: () => {},
};

export const DrawerContext = createContext(defaultDrawerContext);

export const DrawerProvider = ({ children }) => {
  const [isOpen, setIsOpen] = useState(true);

  const toggleDrawer = () => setIsOpen(!isOpen);

  return (
    <DrawerContext.Provider value={{ isOpen, toggleDrawer, setIsOpen }}>
      {children}
    </DrawerContext.Provider>
  );
};

export const useDrawerContext = () => useContext(DrawerContext);

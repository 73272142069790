import {
  Alert,
  Box,
  Button,
  CircularProgress,
  DialogContent,
  Divider,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { removeLessonPlanFromTerm } from "../../api/lessonPlanApi";
import HsModal from "../../components/common/HsModal";
import { useAsyncOperation } from "../../hooks/useAsyncOperation";
import { debug } from "../../utils/debug";

const DeleteClassEnrollmentModal = ({
  open,
  handleClose,
  classInstance,
  studentId,
  onSuccess,
}) => {
  const selectedAccountId = useSelector(
    (state) => state.auth.selectedAccountId
  );
  const { execute: executeRemove, loading } = useAsyncOperation(
    removeLessonPlanFromTerm
  );

  const handleConfirm = async () => {
    try {
      await executeRemove(
        classInstance.lessonPlanSummary.id,
        classInstance.termSummary.id,
        studentId,
        selectedAccountId
      );

      await onSuccess();
      handleClose();
    } catch (error) {
      debug.error("DeleteClassEnrollmentModal: Error in handleConfirm", error);
    }
  };

  return (
    <HsModal
      open={open}
      handleClose={handleClose}
      title="Un-enroll from Class"
      maxWidth="sm"
    >
      <DialogContent>
        <Stack spacing={3}>
          <Typography>
            Are you sure you want to un-enroll from{" "}
            <strong>{classInstance.name}</strong> for the term{" "}
            <strong>{classInstance.termSummary.name}</strong>?
          </Typography>

          <Alert severity="warning">
            <Typography variant="body2" sx={{ fontWeight: 500 }}>
              This action will:
            </Typography>
            <Box component="ul" sx={{ mt: 1, mb: 0, pl: 2 }}>
              <li>Remove the student from this class for the selected term</li>
              <li>
                Permanently delete all calendar events associated with this
                class for this term
              </li>
              <li>This action cannot be undone</li>
            </Box>
          </Alert>

          <Divider />
          <Stack direction="row" spacing={2} justifyContent="flex-end">
            <Button variant="outlined" onClick={handleClose}>
              Cancel
            </Button>
            <Button
              variant="contained"
              color="error"
              onClick={handleConfirm}
              disabled={loading}
            >
              {loading ? <CircularProgress size={24} /> : "Un-enroll"}
            </Button>
          </Stack>
        </Stack>
      </DialogContent>
    </HsModal>
  );
};

export default DeleteClassEnrollmentModal;

import { clearAuthInfo, setAuthInfo } from "../redux/authSlice";
import store from "../redux/store";
import { debug } from "../utils/debug";
import { parseJwt } from "../utils/utils";
import { doPerformRefresh } from "./api";

class AuthManager {
  static #consecutiveErrors = 0;
  static #MAX_CONSECUTIVE_ERRORS = 5;

  static validateToken(token) {
    if (!token) {
      return false;
    }

    try {
      const decoded = parseJwt(token);
      if (!decoded || !decoded.exp) {
        return false;
      }

      const expirationTime = decoded.exp * 1000; // Convert to milliseconds
      const currentTime = Date.now();
      const timeUntilExpiry = expirationTime - currentTime;

      // Token is expired
      if (timeUntilExpiry <= 0) {
        return false;
      }

      // Token needs refresh (15 minutes)
      if (timeUntilExpiry <= 15 * 60 * 1000) {
        return "needs_refresh";
      }

      return true;
    } catch (error) {
      debug.auth.error("Token validation failed:", error);
      return false;
    }
  }

  static async getValidToken() {
    const state = store.getState();
    const { token } = state.auth;

    const tokenStatus = this.validateToken(token);

    if (tokenStatus === false) {
      this.logout();
      return null;
    }

    if (tokenStatus === "needs_refresh") {
      try {
        const newAuthInfo = await doPerformRefresh();
        store.dispatch(setAuthInfo(newAuthInfo));
        return newAuthInfo.token;
      } catch (error) {
        debug.auth.error("Token refresh failed:", error);
        this.logout();
        return null;
      }
    }

    return token;
  }

  static handleResponseError(error) {
    // Only count API errors, not network errors
    if (error.response) {
      this.#consecutiveErrors++;

      if (this.#consecutiveErrors >= this.#MAX_CONSECUTIVE_ERRORS) {
        debug.auth.error("Too many consecutive errors, logging out");
        this.logout();
        return;
      }
    }
  }

  static clearConsecutiveErrors() {
    this.#consecutiveErrors = 0;
  }

  static async logout() {
    try {
      store.dispatch(clearAuthInfo());
      window.location.href = "/";
    } catch (error) {
      debug.auth.error("Logout failed:", error);
      // Force reload even if logout fails
      window.location.href = "/";
    }
  }
}

export default AuthManager;

import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { doPerformRefresh } from "../api/api";
import { getCart } from "../api/cartApi";
import { isPublicRoute } from "../config/routeConstants";
import { clearAuthInfo, initializeAuth, setAuthInfo } from "../redux/authSlice";
import { setCart } from "../redux/cartSlice";
import { debug } from "../utils/debug";

export const useLoadAuth = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [isInitialized, setIsInitialized] = useState(false);
  const authState = useSelector((state) => state.auth);

  const initAuth = useCallback(async () => {
    if (isInitialized) return;

    await dispatch(initializeAuth());

    const url = new URL(window.location.href);
    const token = url.searchParams.get("token");

    if (token) {
      try {
        url.searchParams.delete("token");
        window.history.replaceState({}, document.title, url.toString());

        const authInfo = await doPerformRefresh(token);
        debug.auth.log(
          "Token refresh result:",
          authInfo ? "success" : "failed"
        );

        if (authInfo && authInfo.token) {
          await dispatch(setAuthInfo(authInfo));

          // Fetch cart data after successful login
          try {
            const cartData = await getCart();
            dispatch(setCart(cartData));
          } catch (error) {
            debug.auth.error("Failed to fetch cart data:", error);
          }

          navigate("/home", { replace: true });
        } else {
          debug.auth.log("No valid auth info, navigating to landing");
          await dispatch(clearAuthInfo());
          navigate("/");
        }
      } catch (error) {
        debug.auth.error("Auth initialization failed:", error);
        navigate("/");
      }
    }

    setIsInitialized(true);
    setIsLoading(false);
  }, [dispatch, navigate, isInitialized]);

  const loadCartData = useCallback(async () => {
    if (authState.loggedIn) {
      try {
        const cartData = await getCart();
        dispatch(setCart(cartData));
      } catch (error) {
        debug.auth.error("Failed to fetch cart data:", error);
      }
    }
  }, [authState.loggedIn, dispatch]);

  useEffect(() => {
    initAuth();
  }, [initAuth]);

  useEffect(() => {
    if (isInitialized && authState.loggedIn) {
      loadCartData();
    }
  }, [isInitialized, authState.loggedIn, loadCartData]);

  useEffect(() => {
    if (isInitialized) {
      if (!authState.loggedIn) {
        if (!isPublicRoute(window.location.pathname)) {
          debug.auth.log(
            "Navigating to landing from useLoadAuth useEffect:",
            window.location.pathname
          );
          navigate("/");
        }
      } else {
        // If logged in and on a non-protected route, redirect to home
        if (["/", "/login", "/register"].includes(window.location.pathname)) {
          navigate("/home");
        }
      }
    }
  }, [isInitialized, authState, navigate]);

  return { isLoading, isInitialized, ...authState };
};

import {
  Box,
  Button,
  CircularProgress,
  Grid,
  MenuItem,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateUserProfile } from "../../../api/userApi";
import { TIMEZONES } from "../../../config/constants";
import { useReportContext } from "../../../contexts/report-context";
import { useAsyncOperation } from "../../../hooks/useAsyncOperation";
import { setAuthInfo } from "../../../redux/authSlice";
import { formatPhoneNumber } from "../../../utils/utils";
import { profileValidationSchema } from "../../../utils/validation";
import ProfileImage from "./ProfileImage";

const ProfileDetails = ({ user }) => {
  const [editMode, setEditMode] = useState(false);
  const [currentUser, setCurrentUser] = useState(user);
  const { setMessage } = useReportContext();
  const { selectedAccountId } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const { execute: executeUpdateProfile, loading: updatingProfile } =
    useAsyncOperation(updateUserProfile);

  useEffect(() => {
    setCurrentUser(user);
  }, [user]);

  if (!user) {
    return null;
  }

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      const formattedValues = {
        ...values,
        phoneNumber: values.phoneNumber
          ? formatPhoneNumber(values.phoneNumber, "E.164")
          : null,
      };

      const updatedUser = await executeUpdateProfile(
        user.id,
        formattedValues,
        selectedAccountId
      );
      setEditMode(false);
      setMessage({
        text: "Profile updated successfully",
        severity: "success",
        flag: true,
      });
      dispatch(setAuthInfo({ loggedInUser: updatedUser }));
    } catch (error) {
      setMessage({
        text: "Failed to update profile",
        severity: "error",
        flag: true,
      });
    } finally {
      setSubmitting(false);
    }
  };

  const renderField = (label, value) => (
    <Box mb={1}>
      <Typography variant="subtitle1" fontWeight="bold">
        {label}
      </Typography>
      <Typography>
        {label === "Phone Number" && value
          ? formatPhoneNumber(value, "display")
          : value || "Not provided"}
      </Typography>
    </Box>
  );

  return (
    <Box sx={{ width: "100%", padding: 0 }}>
      <Grid
        container
        direction={isMobile ? "column" : "row"}
        spacing={0}
        sx={{ width: "100%", margin: 0 }}
      >
        <Grid
          item
          sx={{
            width: isMobile ? "100%" : "auto",
            flexShrink: 0,
            marginBottom: isMobile ? 2 : 0,
            paddingRight: isMobile ? 0 : 2,
          }}
        >
          <ProfileImage user={currentUser} />
        </Grid>
        <Grid
          item
          xs={12}
          sm
          sx={{
            display: "flex",
            flexDirection: "column",
            paddingLeft: isMobile ? 0 : theme.spacing(3),
          }}
        >
          <Formik
            initialValues={{
              ...user,
              timezone: user.timezone || "America/New_York", // Default timezone
            }}
            validationSchema={profileValidationSchema}
            onSubmit={handleSubmit}
            enableReinitialize
          >
            {({
              isSubmitting,
              touched,
              errors,
              values,
              dirty,
              handleSubmit,
            }) => (
              <Form
                style={{
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                }}
                onSubmit={handleSubmit}
              >
                <Box flexGrow={1} sx={{ minHeight: 220 }}>
                  <Grid container spacing={2}>
                    {editMode ? (
                      <>
                        <Grid item xs={12} sm={6}>
                          <Field name="firstName">
                            {({ field }) => (
                              <TextField
                                {...field}
                                label="First Name"
                                fullWidth
                                error={touched.firstName && !!errors.firstName}
                                helperText={
                                  touched.firstName && errors.firstName
                                }
                              />
                            )}
                          </Field>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Field name="lastName">
                            {({ field }) => (
                              <TextField
                                {...field}
                                label="Last Name"
                                fullWidth
                                error={touched.lastName && !!errors.lastName}
                                helperText={touched.lastName && errors.lastName}
                              />
                            )}
                          </Field>
                        </Grid>
                      </>
                    ) : (
                      <Grid item xs={12}>
                        {renderField(
                          "Name",
                          `${user.firstName} ${user.lastName}`
                        )}
                      </Grid>
                    )}
                    <Grid item xs={12}>
                      {editMode ? (
                        <Field name="email">
                          {({ field }) => (
                            <TextField
                              {...field}
                              label="Email"
                              fullWidth
                              error={touched.email && !!errors.email}
                              helperText={touched.email && errors.email}
                            />
                          )}
                        </Field>
                      ) : (
                        renderField("Email", user.email)
                      )}
                    </Grid>
                    <Grid item xs={12}>
                      {editMode ? (
                        <Field name="phoneNumber">
                          {({ field }) => (
                            <TextField
                              {...field}
                              label="Phone Number"
                              fullWidth
                              error={
                                touched.phoneNumber && !!errors.phoneNumber
                              }
                              helperText={
                                (touched.phoneNumber && errors.phoneNumber) ||
                                "Enter number in format: (123) 456-7890 or +44 20 7123 4567"
                              }
                              placeholder="+1 (123) 456-7890"
                            />
                          )}
                        </Field>
                      ) : (
                        renderField("Phone Number", user.phoneNumber)
                      )}
                    </Grid>
                    <Grid item xs={12}>
                      {editMode ? (
                        <Field name="timezone">
                          {({ field }) => (
                            <TextField
                              {...field}
                              select
                              label="Timezone"
                              fullWidth
                              error={touched.timezone && !!errors.timezone}
                              helperText={touched.timezone && errors.timezone}
                            >
                              {Object.entries(
                                TIMEZONES.reduce((groups, timezone) => {
                                  const group = timezone.group;
                                  if (!groups[group]) groups[group] = [];
                                  groups[group].push(timezone);
                                  return groups;
                                }, {})
                              ).map(([group, timezones]) => [
                                <MenuItem key={group} disabled divider>
                                  {group}
                                </MenuItem>,
                                ...timezones.map((timezone) => (
                                  <MenuItem
                                    key={timezone.value}
                                    value={timezone.value}
                                    sx={{ pl: 4 }} // Indent timezone options
                                  >
                                    {timezone.label}
                                  </MenuItem>
                                )),
                              ])}
                            </TextField>
                          )}
                        </Field>
                      ) : (
                        renderField(
                          "Timezone",
                          TIMEZONES.find((tz) => tz.value === user.timezone)
                            ?.label || "Eastern Time (ET)"
                        )
                      )}
                    </Grid>
                  </Grid>
                </Box>
                <Box mt={2} alignSelf="flex-start">
                  <Button
                    type={editMode ? "submit" : "button"}
                    variant="contained"
                    color="primary"
                    onClick={() => !editMode && setEditMode(true)}
                    disabled={editMode && (!dirty || isSubmitting)}
                    sx={{ whiteSpace: "nowrap", width: 200 }}
                  >
                    {editMode ? (
                      updatingProfile ? (
                        <CircularProgress size={24} />
                      ) : (
                        "Save"
                      )
                    ) : (
                      "Edit Details"
                    )}
                  </Button>
                </Box>
              </Form>
            )}
          </Formik>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ProfileDetails;

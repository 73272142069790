import { useSelector } from "react-redux";
import {
  deleteLessonPlan,
  getLessonPlans,
  searchLessonPlans as searchLessonPlansApi,
} from "../../api/lessonPlanApi";
import {
  fetchActiveTerms,
  fetchFutureTerms,
  fetchPastTerms,
} from "../../api/termApi";
import { ROLE_STUDENT, getUsers } from "../../api/userApi";
import { useAsyncOperation } from "../../hooks/useAsyncOperation";

export const useLessonPlanOperations = () => {
  const { execute: executeFetch } = useAsyncOperation(getLessonPlans);
  const { execute: executeSearch } = useAsyncOperation(searchLessonPlansApi);
  const selectedAccountId = useSelector(
    (state) => state.auth.selectedAccountId
  );

  const fetchLessonPlans = async (page = 0, rowsPerPage = 10, signal) => {
    try {
      const data = await executeFetch(
        selectedAccountId,
        page,
        rowsPerPage,
        signal
      );
      return {
        data: data.content || [],
        totalCount: data.totalElements || 0,
      };
    } catch (error) {
      if (error.name !== "AbortError") {
        console.error("Error fetching lesson plans:", error);
      }
      return { data: [], totalCount: 0 };
    }
  };

  const searchLessonPlans = async (
    page = 0,
    rowsPerPage = 10,
    searchParam,
    signal
  ) => {
    try {
      const data = await executeSearch(
        selectedAccountId,
        searchParam,
        page,
        rowsPerPage,
        signal
      );
      return {
        data: data.content || [],
        totalCount: data.totalElements || 0,
      };
    } catch (error) {
      if (error.name !== "AbortError") {
        console.error("Error searching lesson plans:", error);
      }
      return { data: [], totalCount: 0 };
    }
  };

  return {
    fetchLessonPlans,
    searchLessonPlans,
  };
};

export const fetchAllSchoolTerms = async (signal) => {
  const [activeTermsResponse, futureTermsResponse, pastTermsResponse] =
    await Promise.all([
      fetchActiveTerms(0, 100, signal),
      fetchFutureTerms(0, 100, signal),
      fetchPastTerms(0, 100, signal),
    ]);

  return [
    ...activeTermsResponse.data,
    ...futureTermsResponse.data,
    ...pastTermsResponse.data,
  ];
};

export const fetchStudents = async (accountId, signal) => {
  return await getUsers(accountId, ROLE_STUDENT, signal);
};

export const removeLessonPlan = async (id, signal) => {
  await deleteLessonPlan(id, signal);
};

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DeleteIcon from "@mui/icons-material/Delete";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getCart, removeFromCart, updateCartItem } from "../api/cartApi";
import { useAsyncOperation } from "../hooks/useAsyncOperation";
import { removeItem, setCart, updateItem } from "../redux/cartSlice";
import LoadingIndicator from "./common/LoadingIndicator";

const EmptyCart = ({ navigate }) => (
  <Box
    sx={{
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      py: { xs: 6, sm: 8 },
      px: 3,
      textAlign: "center",
      backgroundColor: "background.paper",
      borderRadius: 2,
      border: "1px dashed",
      borderColor: "divider",
    }}
  >
    <Box
      sx={{
        width: { xs: "120px", sm: "160px" },
        height: { xs: "120px", sm: "160px" },
        mb: 3,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "primary.lighter",
        borderRadius: "50%",
      }}
    >
      <ShoppingCartIcon
        sx={{
          fontSize: { xs: "60px", sm: "80px" },
          color: "primary.main",
        }}
      />
    </Box>
    <Typography
      variant="h5"
      color="text.primary"
      gutterBottom
      sx={{ fontWeight: 500 }}
    >
      Your cart is empty
    </Typography>

    <Button
      variant="contained"
      onClick={() => navigate("/home")}
      startIcon={<ArrowBackIcon />}
    >
      Return to Homepage
    </Button>
  </Box>
);

const ShoppingCart = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { items, total } = useSelector((state) => state.cart);

  const { execute: fetchCart, loading, error } = useAsyncOperation(getCart);

  useEffect(() => {
    const loadCart = async () => {
      try {
        const cartData = await fetchCart();
        dispatch(setCart(cartData));
      } catch (error) {
        console.error("Failed to fetch cart data:", error);
      }
    };

    loadCart();
  }, [fetchCart, dispatch]);

  const handleQuantityChange = async (item, newQuantity) => {
    if (newQuantity < 1) return;
    try {
      const updatedItem = { ...item, quantity: newQuantity };
      await updateCartItem(item.id, updatedItem);
      dispatch(updateItem(updatedItem));
    } catch (error) {
      console.error("Failed to update item quantity:", error);
    }
  };

  const handleRemoveItem = async (itemId) => {
    try {
      await removeFromCart(itemId);
      dispatch(removeItem(itemId));
    } catch (error) {
      console.error("Failed to remove item from cart:", error);
    }
  };

  const handleProceedToCheckout = () => {
    navigate("/checkout");
  };

  if (loading) {
    return <LoadingIndicator message="Loading your cart..." />;
  }

  if (error) {
    return (
      <Box sx={{ p: 4, maxWidth: "800px", margin: "0 auto" }}>
        <Typography color="error" align="center">
          Error loading cart: {error.message}
        </Typography>
      </Box>
    );
  }

  const renderMobileCart = () => (
    <Box sx={{ width: "100%" }}>
      {items.map((item) => (
        <Card key={item.id} sx={{ mb: 2 }}>
          <CardContent>
            <Box
              sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}
            >
              <Typography variant="subtitle1" component="div">
                {item.name}
              </Typography>
              <IconButton
                onClick={() => handleRemoveItem(item.id)}
                size="small"
                color="error"
              >
                <DeleteIcon />
              </IconButton>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Box>
                <Typography variant="body2" color="text.secondary" gutterBottom>
                  Price: ${item.price.toFixed(2)}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Subtotal: ${(item.price * item.quantity).toFixed(2)}
                </Typography>
              </Box>
              <TextField
                type="number"
                value={item.quantity}
                onChange={(e) =>
                  handleQuantityChange(item, parseInt(e.target.value))
                }
                inputProps={{ min: 1 }}
                size="small"
                sx={{ width: 70 }}
                label="Qty"
              />
            </Box>
          </CardContent>
        </Card>
      ))}
    </Box>
  );

  const renderDesktopCart = () => (
    <TableContainer component={Paper} sx={{ mt: 3 }}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Product</TableCell>
            <TableCell align="right">Price</TableCell>
            <TableCell align="right">Quantity</TableCell>
            <TableCell align="right">Subtotal</TableCell>
            <TableCell align="right">Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {items.map((item) => (
            <TableRow key={item.id}>
              <TableCell>{item.name}</TableCell>
              <TableCell align="right">${item.price.toFixed(2)}</TableCell>
              <TableCell align="right">
                <TextField
                  type="number"
                  value={item.quantity}
                  onChange={(e) =>
                    handleQuantityChange(item, parseInt(e.target.value))
                  }
                  inputProps={{ min: 1 }}
                  size="small"
                  sx={{ width: 70 }}
                />
              </TableCell>
              <TableCell align="right">
                ${(item.price * item.quantity).toFixed(2)}
              </TableCell>
              <TableCell align="right">
                <IconButton
                  onClick={() => handleRemoveItem(item.id)}
                  color="error"
                >
                  <DeleteIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );

  return (
    <Box
      sx={{
        p: { xs: 2, sm: 4 },
        maxWidth: "800px",
        margin: "0 auto",
        width: "100%",
      }}
    >
      <Typography variant="h4" gutterBottom color="primary">
        Your Shopping Cart
      </Typography>

      {items.length === 0 ? (
        <EmptyCart navigate={navigate} />
      ) : (
        <>
          {isMobile ? renderMobileCart() : renderDesktopCart()}

          <Divider sx={{ my: 3 }} />

          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", sm: "row" },
              justifyContent: "flex-end",
              alignItems: { xs: "stretch", sm: "center" },
              gap: 2,
            }}
          >
            <Typography variant="h6">Total: ${total.toFixed(2)}</Typography>
            <Button
              variant="contained"
              onClick={handleProceedToCheckout}
              sx={{ minWidth: 200 }}
            >
              Proceed to Checkout
            </Button>
          </Box>
        </>
      )}
    </Box>
  );
};

export default ShoppingCart;

import InfoIcon from "@mui/icons-material/Info";
import { LoadingButton } from "@mui/lab";
import {
  Alert,
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import { Field, Form, Formik } from "formik";
import React, { useMemo, useState } from "react";
import { applyLessonPlanToTerm } from "../../api/lessonPlanApi";
import HsModal from "../../components/common/HsModal";
import { useReportContext } from "../../contexts/report-context";
import { useAsyncOperation } from "../../hooks/useAsyncOperation";
import { enrollUserValidationSchema } from "../../utils/validation";

const PastLessonStatusSelector = ({ value, onChange, termStartDate }) => {
  const isPastTerm = dayjs(termStartDate).isBefore(dayjs(), "day");
  const isPartialTerm =
    dayjs(termStartDate).isBefore(dayjs(), "day") &&
    dayjs(termStartDate).isAfter(dayjs().subtract(1, "month"), "day");

  if (!isPastTerm) return null;

  return (
    <Box
      sx={{
        bgcolor: "background.gray",
        borderRadius: 2,
        p: 2,
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center", mb: 1.5 }}>
        <Typography variant="subtitle1" color="text.secondary" sx={{ mr: 1 }}>
          {isPartialTerm
            ? "You are scheduling this class in the middle of a term"
            : "You are scheduling this class in a past term"}
        </Typography>
        <Tooltip title="Choose how to handle lessons that occurred before today's date">
          <InfoIcon fontSize="small" color="action" />
        </Tooltip>
      </Box>

      <FormControl component="fieldset">
        <Typography variant="body2" color="text.secondary" sx={{ mb: 1 }}>
          Mark past lessons as:
        </Typography>
        <RadioGroup
          value={value || "COMPLETED"}
          onChange={(e) => onChange(e.target.value)}
        >
          <FormControlLabel
            value="COMPLETED"
            control={<Radio />}
            label={
              <Tooltip title="Mark all past lessons as finished">
                <Typography variant="body2">Completed (Default)</Typography>
              </Tooltip>
            }
          />
          <FormControlLabel
            value="PENDING"
            control={<Radio />}
            label={
              <Tooltip title="Keep past lessons in pending state">
                <Typography variant="body2">Pending</Typography>
              </Tooltip>
            }
          />
          <FormControlLabel
            value="EXCUSED"
            control={<Radio />}
            label={
              <Tooltip title="Mark past lessons as excused">
                <Typography variant="body2">Excused</Typography>
              </Tooltip>
            }
          />
          <FormControlLabel
            value="SKIPPED"
            control={<Radio />}
            label={
              <Tooltip title="Mark past lessons as skipped">
                <Typography variant="body2">Skipped</Typography>
              </Tooltip>
            }
          />
        </RadioGroup>
        <FormHelperText>
          This will affect all lessons scheduled before{" "}
          {dayjs().format("MMM D, YYYY")}
        </FormHelperText>
      </FormControl>
    </Box>
  );
};

const ApplyToTermModal = ({
  open,
  handleApply,
  handleClose,
  lessonPlanId,
  lessonPlanDetails,
  terms,
  students,
}) => {
  const { setMessage } = useReportContext();
  const { execute: executeApplyLessonPlan, loading } = useAsyncOperation(
    applyLessonPlanToTerm
  );
  const [pastLessonStatus, setPastLessonStatus] = useState("COMPLETED");

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      const { termId, studentId } = values;
      const payload = {
        termId,
        studentId,
        markPriorLessons:
          pastLessonStatus !== "PENDING" ? pastLessonStatus : undefined,
      };

      await executeApplyLessonPlan(lessonPlanId, payload);
      handleApply();
      setMessage({
        text: "Applied the lesson plan successfully!",
        severity: "success",
        flag: true,
      });
      handleClose();
    } catch (err) {
      setMessage({
        text: err.response?.data?.message || "Failed to apply the lesson plan",
        severity: "error",
        flag: true,
      });
    }
    setSubmitting(false);
  };

  const selectedTerm = useMemo(() => {
    return (formikProps) => {
      if (!formikProps.values.termId) return null;
      return terms.find((term) => term.id === formikProps.values.termId);
    };
  }, [terms]);

  const shouldShowPriorLessonsField = useMemo(() => {
    return (term) => {
      if (!term) return false;
      const now = new Date();
      const termStart = new Date(term.startDate);
      return termStart < now;
    };
  }, []);

  return (
    <HsModal
      open={open}
      handleClose={handleClose}
      title="Schedule Class"
      maxWidth="sm"
    >
      <Formik
        onSubmit={handleSubmit}
        initialValues={{
          termId: "",
          studentId: "",
        }}
        validationSchema={enrollUserValidationSchema}
      >
        {({ isSubmitting, values, touched, errors, setFieldValue }) => {
          const term = selectedTerm({ values });
          const showPriorLessonsField = shouldShowPriorLessonsField(term);

          return (
            <Form>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Field name="studentId">
                    {({ field, meta }) => (
                      <TextField
                        {...field}
                        select
                        label="Student"
                        fullWidth
                        size="small"
                        error={meta.touched && meta.error}
                        helperText={
                          meta.touched && meta.error ? meta.error : ""
                        }
                        required
                        SelectProps={{
                          MenuProps: {
                            PaperProps: {
                              sx: {
                                maxHeight: 400,
                              },
                            },
                          },
                        }}
                      >
                        {students.map((option) => (
                          <MenuItem key={option.id} value={option.id}>
                            {option.firstName} {option.lastName}
                          </MenuItem>
                        ))}
                      </TextField>
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12}>
                  <Field name="termId">
                    {({ field, meta }) => (
                      <TextField
                        {...field}
                        select
                        label="School Term"
                        fullWidth
                        size="small"
                        error={meta.touched && meta.error}
                        helperText={
                          meta.touched && meta.error ? meta.error : ""
                        }
                        required
                        SelectProps={{
                          MenuProps: {
                            PaperProps: {
                              sx: {
                                maxHeight: 400,
                              },
                            },
                          },
                        }}
                      >
                        {terms.map((option) => (
                          <MenuItem key={option.id} value={option.id}>
                            {option.name}
                          </MenuItem>
                        ))}
                      </TextField>
                    )}
                  </Field>
                </Grid>
                {showPriorLessonsField && (
                  <Grid item xs={12}>
                    <PastLessonStatusSelector
                      value={pastLessonStatus}
                      onChange={setPastLessonStatus}
                      termStartDate={
                        terms.find((t) => t.id === values.termId)?.startDate
                      }
                    />
                  </Grid>
                )}
                <Grid item xs={12}>
                  <Stack direction={"row"} spacing={1} alignItems={"center"}>
                    <Alert severity="info">
                      Class Title: {lessonPlanDetails.name}
                      <br />
                      Duration: {lessonPlanDetails.durationPerLesson} minutes :{" "}
                      {lessonPlanDetails.lessonFrequency}
                    </Alert>
                  </Stack>
                </Grid>
                <Grid item xs={12}>
                  <Stack
                    direction="row"
                    justifyContent="flex-end"
                    spacing={2}
                    sx={{ mt: 2 }}
                  >
                    <Button variant="outlined" onClick={handleClose}>
                      Cancel
                    </Button>
                    <LoadingButton
                      type="submit"
                      variant="contained"
                      loading={loading}
                      disabled={
                        !values.termId || Object.keys(errors).length > 0
                      }
                    >
                      Schedule
                    </LoadingButton>
                  </Stack>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </HsModal>
  );
};

export default ApplyToTermModal;

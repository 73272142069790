import { Box, Button, Stack, Typography } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import {
  QuickstartWizardProvider,
  useQuickstartWizardContext,
} from "../../contexts/quickstart-wizard-context";
import HsModal from "../common/HsModal";
import HsWizard from "../common/HsWizard";
import QuickstartLessonPlans from "./steps/QuickstartLessonPlans";
import QuickstartStudents from "./steps/QuickstartStudents";
import QuickstartTerms from "./steps/QuickstartTerms";

const WizardWithContext = ({
  open,
  handleClose,
  wizardSteps,
  error,
  setError,
  handleComplete,
}) => {
  const { clearWizardData } = useQuickstartWizardContext();
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);

  const handleCloseRequest = () => {
    setShowConfirmDialog(true);
  };

  const handleConfirmClose = () => {
    clearWizardData?.();
    handleClose();
    setShowConfirmDialog(false);
  };

  const handleCancelClose = () => {
    setShowConfirmDialog(false);
  };

  return (
    <>
      <HsWizard
        open={open}
        handleClose={handleCloseRequest}
        title={
          <Box sx={{ textAlign: "center", mb: 2 }}>
            <Typography variant="h4" color="primary" gutterBottom>
              Welcome to Your Homeschool Planner!
            </Typography>
            <Typography variant="subtitle1" color="text.secondary">
              Let's get you set up in three easy steps
            </Typography>
          </Box>
        }
        steps={wizardSteps}
        onComplete={handleComplete}
        error={error}
        setError={setError}
        contextType="quickstart"
        finishButtonText="Finish"
      />

      <HsModal
        open={showConfirmDialog}
        handleClose={handleCancelClose}
        title="Exit Setup?"
      >
        <Box sx={{ p: 3 }}>
          <Typography sx={{ mb: 3 }}>
            Are you sure you want to exit the setup process? You can always
            complete it later.
          </Typography>
          <Stack direction="row" spacing={2} justifyContent="flex-end">
            <Button onClick={handleCancelClose} color="primary">
              Continue Setup
            </Button>
            <Button
              onClick={handleConfirmClose}
              variant="contained"
              color="secondary"
            >
              Exit Setup
            </Button>
          </Stack>
        </Box>
      </HsModal>
    </>
  );
};

const QuickstartWizardModal = ({ open, handleClose }) => {
  const [error, setError] = useState(null);
  const [isInitialized, setIsInitialized] = useState(false);

  useEffect(() => {
    setIsInitialized(true);
  }, []);

  const handleComplete = useCallback(
    async (wizardData) => {
      try {
        handleClose();
        return true;
      } catch (error) {
        setError("Failed to complete wizard");
        return false;
      }
    },
    [handleClose]
  );

  if (!isInitialized) {
    return null;
  }

  const wizardSteps = [
    {
      label: "Add Student",
      content: <QuickstartStudents />,
    },
    {
      label: "School Term",
      content: <QuickstartTerms />,
    },
    {
      label: "Create Lesson Plan",
      content: <QuickstartLessonPlans />,
    },
  ];

  return (
    <QuickstartWizardProvider steps={wizardSteps}>
      <WizardWithContext
        open={open}
        handleClose={handleClose}
        wizardSteps={wizardSteps}
        error={error}
        setError={setError}
        handleComplete={handleComplete}
      />
    </QuickstartWizardProvider>
  );
};

export default QuickstartWizardModal;

import InfoIcon from "@mui/icons-material/Info";
import {
  Alert,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Paper,
  Radio,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useGoogleLogin } from "@react-oauth/google";
import debounce from "lodash/debounce";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import {
  connectGoogleCalendar,
  disconnectGoogleCalendar,
  getGoogleCalendars,
  reconnectGoogleCalendar,
  selectGoogleCalendars,
  updateCalendarSettings,
} from "../../../api/calendarApi";
import { useReportContext } from "../../../contexts/report-context";
import { useAsyncOperation } from "../../../hooks/useAsyncOperation";
import { useCalendarConnection } from "../../../hooks/useCalendarConnection";
import {
  setAuthInfo,
  updateCalendarSyncSettings,
} from "../../../redux/authSlice";

const GoogleCalendarSection = () => {
  const {
    isConnected: initialConnected,
    needsReconnect,
    defaultSyncCalendarId: initialSyncCalendarId,
  } = useCalendarConnection();

  // Update isConnected when needsReconnect changes
  const [isConnected, setIsConnected] = useState(
    initialConnected && !needsReconnect
  );

  const [calendars, setCalendars] = useState([]);
  const [syncEnabled, setSyncEnabled] = useState(!!initialSyncCalendarId);
  const [syncToCalendarId, setSyncToCalendarId] = useState(
    initialSyncCalendarId
  );
  const { setMessage } = useReportContext();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const dispatch = useDispatch();

  const { execute: executeFetchCalendars, loading: fetchingCalendars } =
    useAsyncOperation(getGoogleCalendars);
  const { execute: executeConnectCalendar, loading: connectingCalendar } =
    useAsyncOperation(connectGoogleCalendar);
  const { execute: executeDisconnectCalendar, loading: disconnectingCalendar } =
    useAsyncOperation(disconnectGoogleCalendar);
  const { execute: executeSelectCalendars } = useAsyncOperation(
    selectGoogleCalendars
  );
  const { execute: executeUpdateSettings } = useAsyncOperation(
    updateCalendarSettings
  );
  const { execute: executeReconnectCalendar } = useAsyncOperation(
    reconnectGoogleCalendar
  );

  // Show reconnect prompt if needed
  useEffect(() => {
    if (needsReconnect) {
      setMessage({
        text: "Your Google Calendar connection needs to be renewed. Please reconnect your calendar.",
        severity: "warning",
        flag: true,
      });
    }
  }, [needsReconnect, setMessage]);

  // Initialize connection state from auth
  useEffect(() => {
    setIsConnected(initialConnected && !needsReconnect);
  }, [initialConnected, needsReconnect]);

  const debouncedUpdateCalendars = useMemo(
    () =>
      debounce(async (calendarsToUpdate) => {
        try {
          await executeSelectCalendars(calendarsToUpdate);
          setMessage({
            text: "Calendars updated successfully",
            severity: "success",
            flag: true,
          });
        } catch (error) {
          setMessage({
            text: "Failed to update calendars",
            severity: "error",
            flag: true,
          });
        }
      }, 500),
    [executeSelectCalendars, setMessage]
  );

  useEffect(() => {
    return () => {
      debouncedUpdateCalendars.cancel();
    };
  }, [debouncedUpdateCalendars]);

  const handleSyncToCalendarSelect = useCallback(
    async (calendarId) => {
      // Check if the selected calendar is in the list of selected calendars
      const targetCalendar = calendars.find((cal) => cal.id === calendarId);
      if (!targetCalendar?.selected) {
        setMessage({
          text: "Please select the calendar for import before setting it as default",
          severity: "warning",
          flag: true,
        });
        return;
      }

      try {
        await executeUpdateSettings("GOOGLE", calendarId);
        setSyncToCalendarId(calendarId);

        dispatch(
          updateCalendarSyncSettings({ defaultSyncCalendarId: calendarId })
        );

        setMessage({
          text: "Default calendar updated successfully",
          severity: "success",
          flag: true,
        });
      } catch (error) {
        setMessage({
          text: "Failed to update default calendar",
          severity: "error",
          flag: true,
        });
      }
    },
    [executeUpdateSettings, setMessage, dispatch, calendars]
  );

  const handleSelectCalendar = useCallback(
    (calendar) => {
      // Prevent unselecting a calendar if it's the current sync target
      if (calendar.id === syncToCalendarId && calendar.selected) {
        setMessage({
          text: "Cannot unselect the default sync calendar. Please choose a different default calendar first.",
          severity: "warning",
          flag: true,
        });
        return;
      }

      const updatedCalendars = calendars.map((cal) => ({
        ...cal,
        selected: cal.id === calendar.id ? !cal.selected : cal.selected,
      }));

      // If unselecting a calendar, and it was the sync target, clear the sync target
      if (
        calendar.id === syncToCalendarId &&
        !updatedCalendars.find((c) => c.id === calendar.id).selected
      ) {
        setSyncToCalendarId(null);
        setSyncEnabled(false);
      }

      setCalendars(updatedCalendars);
      debouncedUpdateCalendars(updatedCalendars);
    },
    [calendars, debouncedUpdateCalendars, syncToCalendarId, setMessage]
  );

  const fetchGoogleCalendars = useCallback(async () => {
    try {
      const result = await executeFetchCalendars();

      if (result.status === "CONNECTED" && result.calendars?.length > 0) {
        setCalendars(result.calendars);
        setIsConnected(true);

        // Use the initial sync calendar ID from auth if available
        if (initialSyncCalendarId) {
          setSyncToCalendarId(initialSyncCalendarId);
          setSyncEnabled(true);
        }
      } else if (result.status === "NEEDS_RECONNECT") {
        setCalendars([]);
        setIsConnected(false);
        setSyncToCalendarId(null);
      } else {
        setCalendars([]);
        setIsConnected(false);
        setSyncToCalendarId(null);
      }
    } catch (error) {
      console.error("Error fetching Google Calendars:", error);
      setIsConnected(false);
      setSyncToCalendarId(null);
      setMessage({
        text: "Failed to fetch Google Calendars. Please try again later.",
        severity: "error",
        flag: true,
      });
    }
  }, [executeFetchCalendars, setMessage, initialSyncCalendarId]);

  useEffect(() => {
    fetchGoogleCalendars();
  }, [fetchGoogleCalendars]);

  const handleConnectGoogleCalendar = useGoogleLogin({
    onSuccess: async (codeResponse) => {
      try {
        await executeConnectCalendar(codeResponse.code);
        await fetchGoogleCalendars();
        setMessage({
          text: "Connected to Google Calendar successfully",
          severity: "success",
          flag: true,
        });
      } catch (error) {
        setMessage({
          text: "Failed to connect to Google Calendar",
          severity: "error",
          flag: true,
        });
      }
    },
    flow: "auth-code",
    scope: "https://www.googleapis.com/auth/calendar",
  });

  const handleDisconnectGoogleCalendar = async () => {
    try {
      await executeDisconnectCalendar();
      setIsConnected(false);
      setCalendars([]);
      setMessage({
        text: "Disconnected from Google Calendar successfully",
        severity: "success",
        flag: true,
      });
    } catch (error) {
      setMessage({
        text: "Failed to disconnect from Google Calendar",
        severity: "error",
        flag: true,
      });
    }
  };

  const handleSyncToggle = async (event) => {
    const newSyncEnabled = event.target.checked;

    if (newSyncEnabled) {
      // Find the first selected calendar to use as default
      const firstSelectedCalendar = calendars.find((cal) => cal.selected);

      if (!firstSelectedCalendar) {
        setMessage({
          text: "Please select at least one calendar before enabling sync",
          severity: "warning",
          flag: true,
        });
        return;
      }

      try {
        await executeUpdateSettings("GOOGLE", firstSelectedCalendar.id);
        setSyncEnabled(true);
        setSyncToCalendarId(firstSelectedCalendar.id);

        dispatch(
          updateCalendarSyncSettings({
            defaultSyncCalendarId: firstSelectedCalendar.id,
          })
        );

        setMessage({
          text: "Calendar sync enabled",
          severity: "success",
          flag: true,
        });
      } catch (error) {
        setMessage({
          text: "Failed to enable sync settings",
          severity: "error",
          flag: true,
        });
      }
    } else {
      try {
        await executeUpdateSettings("GOOGLE", null);
        setSyncEnabled(false);
        setSyncToCalendarId(null);

        dispatch(updateCalendarSyncSettings({ defaultSyncCalendarId: null }));

        setMessage({
          text: "Calendar sync disabled",
          severity: "success",
          flag: true,
        });
      } catch (error) {
        setMessage({
          text: "Failed to update sync settings",
          severity: "error",
          flag: true,
        });
      }
    }
  };

  const handleReconnectGoogleCalendar = useGoogleLogin({
    onSuccess: async (codeResponse) => {
      try {
        const response = await executeReconnectCalendar(codeResponse.code);

        // Update global auth state with the response
        dispatch(
          setAuthInfo({
            token: response.token,
            loggedInUser: response.user,
            loggedInUserAccounts: response.accounts,
            showQuickStart: response.showQuickStart,
            calendarConnections: response.calendarConnections,
            // The calendar connection status is now in the response structure
            googleCalendarConnected:
              response.calendarConnections?.GOOGLE?.connected || false,
            reconnectGoogleCalendar:
              response.calendarConnections?.GOOGLE?.needsReconnect || false,
            defaultSyncCalendarId:
              response.calendarConnections?.GOOGLE?.defaultSyncCalendarId ||
              null,
          })
        );

        // Update local state
        if (response.calendarConnections?.GOOGLE) {
          const googleStatus = response.calendarConnections.GOOGLE;
          setIsConnected(googleStatus.connected || false);

          // Fetch fresh calendar data instead of using the response
          // since we want to get the full calendar list
          await fetchGoogleCalendars();

          if (googleStatus.defaultSyncCalendarId) {
            setSyncEnabled(true);
            setSyncToCalendarId(googleStatus.defaultSyncCalendarId);
          }
        }

        setMessage({
          text: "Successfully reconnected to Google Calendar",
          severity: "success",
          flag: true,
        });
      } catch (error) {
        setMessage({
          text: "Failed to reconnect to Google Calendar",
          severity: "error",
          flag: true,
        });
      }
    },
    flow: "auth-code",
    scope: "https://www.googleapis.com/auth/calendar",
  });

  return (
    <Box>
      <Box sx={{ mb: 3 }}>
        {fetchingCalendars || connectingCalendar || disconnectingCalendar ? (
          <Box display="flex" justifyContent="center" my={2}>
            <CircularProgress size={24} />
          </Box>
        ) : needsReconnect ? (
          <Box>
            <Alert severity="warning" sx={{ mb: 2 }}>
              Your Google Calendar connection needs to be renewed. Please
              reconnect to continue syncing.
            </Alert>
            <Box display="flex" gap={2}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => handleReconnectGoogleCalendar()}
                sx={{ minWidth: 200 }}
              >
                Reconnect Calendar
              </Button>
              <Button
                variant="outlined"
                color="secondary"
                onClick={handleDisconnectGoogleCalendar}
                sx={{ minWidth: 200 }}
              >
                Disconnect Calendar
              </Button>
            </Box>
          </Box>
        ) : isConnected ? (
          <Button
            variant="contained"
            color="secondary"
            onClick={handleDisconnectGoogleCalendar}
            disabled={disconnectingCalendar}
            sx={{ minWidth: 200 }}
          >
            Disconnect Calendar
          </Button>
        ) : (
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleConnectGoogleCalendar()}
            disabled={connectingCalendar}
            sx={{ minWidth: 200 }}
          >
            Connect Calendar
          </Button>
        )}
      </Box>

      {calendars.length > 0 && isConnected && !needsReconnect && (
        <>
          <Box sx={{ mb: 3, mt: 4 }}>
            <FormControlLabel
              control={
                <Switch
                  checked={syncEnabled}
                  onChange={handleSyncToggle}
                  color="primary"
                  disabled={calendars.every((cal) => !cal.selected)}
                />
              }
              label={
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Typography>Sync new events to Google Calendar</Typography>
                  <Tooltip title="When enabled, new events created in the app will automatically sync to your selected default Google Calendar">
                    <InfoIcon
                      sx={{ ml: 1, fontSize: 18, color: "action.active" }}
                    />
                  </Tooltip>
                </Box>
              }
            />
          </Box>

          {syncEnabled && !syncToCalendarId && (
            <Alert severity="info" sx={{ mb: 2 }}>
              Please select a default calendar to sync new events to
            </Alert>
          )}

          {syncEnabled && calendars.every((cal) => !cal.selected) && (
            <Alert
              severity="info"
              sx={{
                mb: 2,
                mt: 2,
              }}
            >
              Please select at least one calendar to import events from before
              choosing a default sync calendar.
            </Alert>
          )}

          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Import From</TableCell>
                  {syncEnabled && (
                    <TableCell>
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        Default Calendar
                        <Tooltip title="Select one calendar to sync new events to">
                          <InfoIcon
                            sx={{ ml: 1, fontSize: 18, color: "action.active" }}
                          />
                        </Tooltip>
                      </Box>
                    </TableCell>
                  )}
                  <TableCell>Calendar Name</TableCell>
                  {!isMobile && <TableCell>Timezone</TableCell>}
                </TableRow>
              </TableHead>
              <TableBody>
                {calendars.map((calendar) => (
                  <TableRow
                    key={calendar.id}
                    sx={
                      calendar.id === syncToCalendarId
                        ? {
                            backgroundColor: theme.palette.action.selected,
                          }
                        : undefined
                    }
                  >
                    <TableCell>
                      <Checkbox
                        checked={calendar.selected}
                        onChange={() => handleSelectCalendar(calendar)}
                      />
                    </TableCell>
                    {syncEnabled && (
                      <TableCell>
                        <Radio
                          checked={calendar.id === syncToCalendarId}
                          onChange={() =>
                            handleSyncToCalendarSelect(calendar.id)
                          }
                          disabled={!calendar.selected}
                          sx={{
                            "&.Mui-disabled": {
                              opacity: 0.5,
                            },
                          }}
                        />
                      </TableCell>
                    )}
                    <TableCell>
                      {isMobile ? (
                        <Box>
                          <Typography>{calendar.name}</Typography>
                          <Typography variant="body2" color="text.secondary">
                            {calendar.timezone}
                          </Typography>
                        </Box>
                      ) : (
                        calendar.name
                      )}
                    </TableCell>
                    {!isMobile && <TableCell>{calendar.timezone}</TableCell>}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
    </Box>
  );
};

export default GoogleCalendarSection;

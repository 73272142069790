import {
  Box,
  FormControl,
  FormGroup,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React from "react";
import InstructionDay from "./InstructionDay";
import { DAYS_OF_WEEK } from "./constants";

const Step2 = React.memo(
  ({
    instructionHours,
    handleSelectInstructionDay,
    handleAddDayInstructionHour,
    handleChangeTime,
    handleRemoveDayInstructionHour,
    validationErrors,
  }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    return (
      <Box sx={{ p: 3 }}>
        <Typography variant="h6" gutterBottom sx={{ mb: 3 }}>
          Instruction Hours
        </Typography>

        <FormControl
          component="fieldset"
          sx={{
            width: "100%",
            "& .MuiFormGroup-root": {
              gap: isMobile ? 1 : 2,
            },
          }}
        >
          <FormGroup>
            {DAYS_OF_WEEK.map((day) => (
              <InstructionDay
                key={day}
                day={day}
                isSelected={instructionHours[day]?.isSelected || false}
                value={instructionHours[day]?.value || []}
                handleSelectInstructionDay={handleSelectInstructionDay}
                handleAddDayInstructionHour={handleAddDayInstructionHour}
                handleChangeTime={handleChangeTime}
                handleRemoveDayInstructionHour={handleRemoveDayInstructionHour}
                errors={validationErrors[day] || []}
                isMobile={isMobile}
              />
            ))}
          </FormGroup>
        </FormControl>
      </Box>
    );
  }
);

export default Step2;

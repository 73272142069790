import apiUrl from "../config/config.js";
import { api } from "./api";

export const getCalendarEvents = async (
  accountId,
  start_date = getOneMonthAgo(),
  end_date = getOneMonthAhead(),
  direction = "ASC",
  signal
) => {
  const response = await api.get(`${apiUrl}/api/v1/calendar/events`, {
    params: {
      account_id: accountId,
      start_date,
      end_date,
      direction,
    },
    signal,
  });
  return response.data;
};

export const createCalendarEvent = async (
  accountId,
  data,
  options = {},
  signal
) => {
  const response = await api.post(`${apiUrl}/api/v1/calendar/event`, data, {
    params: {
      account_id: accountId,
      rebalance: options.rebalance || false,
    },
    signal,
  });
  return response.data;
};

export const updateCalendarEvent = async (
  accountId,
  eventId,
  data,
  options = { rebalance: false },
  signal
) => {
  const response = await api.put(
    `${apiUrl}/api/v1/calendar/event/${eventId}`,
    data,
    {
      params: {
        account_id: accountId,
        rebalance: options.rebalance,
      },
      signal,
    }
  );
  return response.data;
};

export const connectGoogleCalendar = async (authCode, signal) => {
  const response = await api.post(
    `${apiUrl}/api/v1/calendar-connect/google?authCode=${authCode}`,
    {},
    { signal }
  );
  return response.data;
};

export const disconnectGoogleCalendar = async (signal) => {
  const response = await api.delete(
    `${apiUrl}/api/v1/calendar-connect/google`,
    { signal }
  );
  return response.data;
};

export const getGoogleCalendars = async (signal) => {
  const response = await api.get(
    `${apiUrl}/api/v1/calendar-connect/google/calendars`,
    { signal }
  );

  const { status, message, data } = response.data;

  // If we have a successful response with calendars
  if (status === "CONNECTED" && data?.calendars) {
    return {
      status,
      message,
      calendars: data.calendars.map((cal) => ({
        id: cal.id,
        name: cal.name,
        timezone: cal.timezone,
        description: cal.description,
        selected: cal.selected || false,
        isDefaultSyncCalendar: data.defaultSyncCalendarId === cal.id,
      })),
    };
  }

  // Handle disconnected or needs reconnect states
  return {
    status,
    message: message || "No calendars available",
    calendars: [],
  };
};

export const selectGoogleCalendars = async (calendars, signal) => {
  // Send all calendars with their selected state
  const response = await api.post(
    `${apiUrl}/api/v1/calendar-connect/google/calendar-selection`,
    calendars.map((cal) => ({
      id: cal.id,
      selected: cal.selected,
      name: cal.name,
      timezone: cal.timezone,
    })),
    { signal }
  );
  return response.data;
};

export const updateChecklistStatus = async (id, status, signal) => {
  const response = await api.patch(
    `${apiUrl}/api/v1/calendar/event/${id}/checklist-status`,
    { checklistStatus: status },
    { signal }
  );
  return response.data;
};

export const updateChecklistStatusBulk = async (
  accountId,
  eventIds,
  checklistStatus,
  signal
) => {
  const response = await api.put(
    `${apiUrl}/api/v1/calendar/events/checklist-status`,
    {
      eventIds,
      checklistStatus,
    },
    {
      params: { account_id: accountId },
      signal,
    }
  );
  return response.data;
};

export const getChecklist = async ({
  accountId,
  checklist_status,
  startDate,
  endDate,
  signal,
  direction = "ASC",
  participant_id,
}) => {
  if (!accountId) {
    throw new Error("accountId is required");
  }

  const params = {
    account_id: accountId,
    direction,
    checklist_items_only: true,
  };

  // Only add checklist_status if it's provided
  if (checklist_status) {
    params.checklist_status = checklist_status;
  }

  // Only include date params if they are provided
  if (startDate && endDate) {
    params.start_date = startDate;
    params.end_date = endDate;
  }

  // Only add participant_id if it's provided and not "ALL"
  if (participant_id && participant_id !== "ALL") {
    params.participant_id = participant_id;
  }

  const response = await api.get(`${apiUrl}/api/v1/calendar/events`, {
    params,
    signal,
  });
  return response.data;
};

export const updateCalendarSync = async (settings) => {
  const payload = settings.syncToCalendarId
    ? {
        provider: "GOOGLE",
        defaultSyncCalendarId: settings.syncToCalendarId,
        syncEnabled: settings.syncEnabled,
      }
    : settings;

  const response = await api.post("/calendar/sync-settings", payload);
  return response.data;
};

export const updateCalendarSettings = async (
  provider,
  defaultSyncCalendarId,
  signal
) => {
  const params = new URLSearchParams();
  if (defaultSyncCalendarId) {
    params.append("default_sync_calendar_id", defaultSyncCalendarId);
  }

  const response = await api.post(
    `${apiUrl}/api/v1/calendar-connect/${provider}/calendar-settings?${params.toString()}`,
    {},
    { signal }
  );
  return response.data;
};

export const reconnectGoogleCalendar = async (authCode, signal) => {
  const response = await api.post(
    `${apiUrl}/api/v1/calendar-connect/reconnect/google?authCode=${authCode}`,
    {},
    { signal }
  );
  // The response now contains the full auth response object
  return response.data.data; // Extract the SigninResponseDto from AuthResponse wrapper
};

const getOneMonthAgo = () => {
  const today = new Date();
  const oneMonthAgo = new Date(
    today.getFullYear(),
    today.getMonth() - 1,
    today.getDate()
  );
  return oneMonthAgo.toISOString();
};

const getOneMonthAhead = () => {
  const today = new Date();
  const oneMonthAhead = new Date(
    today.getFullYear(),
    today.getMonth() + 1,
    today.getDate()
  );
  return oneMonthAhead.toISOString();
};

import AddIcon from "@mui/icons-material/Add";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { Box, Button, Grid, IconButton, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Views } from "react-big-calendar";
import { useSelector } from "react-redux";
import { getCalendarEvents, updateCalendarEvent } from "../../api/calendarApi";
import LoadingIndicator from "../../components/common/LoadingIndicator";
import { useReportContext } from "../../contexts/report-context";
import { useAsyncOperation } from "../../hooks/useAsyncOperation";
import CalendarView from "./CalendarView";
import CreateCalendarEventModal from "./CreateEventModal";
import EventDetailsViewModal from "./components/EventDetailsViewModal";

const CalendarEventsPage = () => {
  const [events, setEvents] = useState([]);
  const { setMessage } = useReportContext();
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [openDetailsModal, setOpenDetailsModal] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const theme = useTheme();
  const [currentView, setCurrentView] = useState(Views.MONTH);
  const [dateRange, setDateRange] = useState({
    start: new Date(),
    end: new Date(),
  });
  const [currentDate, setCurrentDate] = useState(new Date());
  const initialLoadComplete = useRef(false);

  const selectedAccountId = useSelector(
    (state) => state.auth.selectedAccountId
  );

  const {
    execute: fetchEvents,
    loading,
    error: fetchError,
    abort: abortFetch,
  } = useAsyncOperation(getCalendarEvents);

  const loadEvents = useCallback(
    async (start, end) => {
      if (!start || !end) return;

      try {
        const response = await fetchEvents(
          selectedAccountId,
          start.toISOString(),
          end.toISOString(),
          "ASC"
        );
        if (response?.events) {
          const mappedEvents = response.events.map((event) => ({
            ...event,
            title: event.title,
            start: new Date(event.userStartTime),
            end: new Date(event.userEndTime),
          }));
          setEvents(mappedEvents);
        }
      } catch (error) {
        if (error.code !== "ERR_CANCELED") {
          setMessage({
            text: error.message || "Failed to fetch events",
            severity: "error",
            flag: true,
          });
        }
      }
    },
    [fetchEvents, setMessage, selectedAccountId]
  );

  const handleRangeChange = useCallback(
    (range) => {
      let start, end;

      if (Array.isArray(range)) {
        // Week view
        start = new Date(range[0].setHours(0, 0, 0, 0));
        end = new Date(range[range.length - 1].setHours(23, 59, 59, 999));
      } else if (range.start && range.end) {
        // Month view
        start = new Date(range.start.setHours(0, 0, 0, 0));
        end = new Date(range.end.setHours(23, 59, 59, 999));
      } else {
        // Day view or Agenda navigation
        start = new Date(range);
        start.setHours(0, 0, 0, 0);

        if (currentView === Views.AGENDA) {
          // For Agenda, fetch one month of data starting from the current position
          end = new Date(start);
          end.setMonth(end.getMonth() + 1);
        } else {
          // For Day view, keep existing behavior
          end = new Date(start);
        }
        end.setHours(23, 59, 59, 999);
      }

      setDateRange({ start, end });

      if (selectedAccountId) {
        loadEvents(start, end);
      }
    },
    [selectedAccountId, loadEvents, currentView, currentDate]
  );

  const handleViewChange = useCallback((newView) => {
    setCurrentView(newView);
  }, []);

  useEffect(() => {
    if (selectedAccountId && !initialLoadComplete.current) {
      const start = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        1,
        0,
        0,
        0,
        0
      );

      const end = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth() + 1,
        0,
        23,
        59,
        59,
        999
      );

      setDateRange({ start, end });
      loadEvents(start, end);
      initialLoadComplete.current = true;
    }
  }, [loadEvents, selectedAccountId, currentDate]);

  useEffect(() => {
    if (fetchError && fetchError.code !== "ERR_CANCELED") {
      setMessage({
        text: fetchError.message || "Failed to fetch events",
        severity: "error",
        flag: true,
      });
    }
  }, [fetchError, setMessage]);

  useEffect(() => {
    return () => {
      abortFetch();
    };
  }, [abortFetch]);

  const handleOpenCreateModal = () => setOpenCreateModal(true);
  const handleCloseCreateModal = () => setOpenCreateModal(false);

  const handleOpenDetailsModal = (event) => {
    setSelectedEvent(event);
    setOpenDetailsModal(true);
  };

  const handleCloseDetailsModal = () => setOpenDetailsModal(false);

  const handleNewEventSuccess = () => {
    loadEvents();
    handleCloseCreateModal();
  };

  const handleNavigate = useCallback(
    (action) => {
      const newDate = new Date(currentDate);

      switch (action) {
        case "PREV":
          if (currentView === Views.MONTH || currentView === Views.AGENDA) {
            // Set to 1st of current month, then subtract a month
            newDate.setDate(1);
            newDate.setMonth(currentDate.getMonth() - 1);
          } else if (currentView === Views.WEEK) {
            const day = newDate.getDay();
            const diff = newDate.getDate() - day;
            newDate.setDate(diff - 7);
          } else {
            newDate.setDate(currentDate.getDate() - 1);
          }
          break;
        case "NEXT":
          if (currentView === Views.MONTH || currentView === Views.AGENDA) {
            // Set to 1st of current month, then add a month
            newDate.setDate(1);
            newDate.setMonth(currentDate.getMonth() + 1);
          } else if (currentView === Views.WEEK) {
            const day = newDate.getDay();
            const diff = newDate.getDate() - day;
            newDate.setDate(diff + 7);
          } else {
            newDate.setDate(currentDate.getDate() + 1);
          }
          break;
        case "TODAY":
          return setCurrentDate(new Date());
        default:
          return;
      }
      setCurrentDate(newDate);
    },
    [currentDate, currentView]
  );

  const handleEventUpdate = async (updatedEvent) => {
    try {
      // Extract rebalance from the updated event data
      const { rebalance, ...eventData } = updatedEvent;

      await updateCalendarEvent(selectedAccountId, updatedEvent.id, eventData, {
        rebalance: rebalance ?? false, // Use the rebalance value from form, default to false if not provided
      });

      setMessage({
        text: "Event updated successfully",
        severity: "success",
        flag: true,
      });
      // Refresh events after update
      loadEvents(dateRange.start, dateRange.end);
    } catch (error) {
      setMessage({
        text: "Failed to update event",
        severity: "error",
        flag: true,
      });
    }
  };

  const refreshCalendar = useCallback(() => {
    // Your existing fetch events logic
    fetchEvents(dateRange.start, dateRange.end);
  }, [fetchEvents, dateRange.start, dateRange.end]);

  if (loading) {
    return <LoadingIndicator message="Loading your calendar..." />;
  }
  if (fetchError) {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "50vh",
          textAlign: "center",
          p: 3,
        }}
      >
        <ErrorOutlineIcon
          sx={{
            fontSize: 64,
            color: "error.main",
            mb: 2,
          }}
        />
        <Typography variant="h5" color="error" gutterBottom>
          Unable to Load Calendar
        </Typography>
        <Typography
          variant="body1"
          color="text.secondary"
          sx={{ maxWidth: 500 }}
        >
          {fetchError.message ||
            "There was a problem loading your calendar events. Please try again later."}
        </Typography>
        <Button
          variant="contained"
          onClick={() => loadEvents(dateRange.start, dateRange.end)}
          sx={{ mt: 3 }}
        >
          Try Again
        </Button>
      </Box>
    );
  }

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 4,
        }}
      >
        <Typography variant="h2">Calendar</Typography>
        <IconButton
          onClick={handleOpenCreateModal}
          sx={{
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
            "&:hover": {
              backgroundColor: theme.palette.primary.dark,
            },
          }}
        >
          <AddIcon />
        </IconButton>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <CalendarView
            events={events}
            handleOpenDetailsModal={handleOpenDetailsModal}
            loading={loading}
            error={fetchError?.message}
            onRangeChange={handleRangeChange}
            onView={handleViewChange}
            onNavigate={handleNavigate}
            currentDate={currentDate}
            defaultView={currentView}
          />
        </Grid>
      </Grid>
      <CreateCalendarEventModal
        open={openCreateModal}
        handleSuccess={handleNewEventSuccess}
        handleFailure={handleCloseCreateModal}
        handleCancel={handleCloseCreateModal}
        onEventCreated={refreshCalendar}
      />
      <EventDetailsViewModal
        open={openDetailsModal}
        event={selectedEvent}
        handleClose={handleCloseDetailsModal}
        onEventUpdate={handleEventUpdate}
      />
    </Box>
  );
};

export default CalendarEventsPage;

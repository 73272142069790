import { Box, Button, Typography } from "@mui/material";
import React from "react";
import { debug } from "../utils/debug";

class NetworkErrorBoundary extends React.Component {
  state = { hasError: false, error: null };

  componentDidMount() {
    window.addEventListener(
      "unhandledrejection",
      this.handleUnhandledRejection
    );
  }

  componentWillUnmount() {
    window.removeEventListener(
      "unhandledrejection",
      this.handleUnhandledRejection
    );
  }

  handleUnhandledRejection = (event) => {
    // Catch any network or connectivity related error
    if (this.isNetworkError(event.reason)) {
      debug.error("Network error caught by boundary:", event.reason);
      this.setState({ hasError: true, error: event.reason });
      event.preventDefault(); // Prevent the error from propagating
    }
  };

  isNetworkError = (error) => {
    // Check for any indication of network/connectivity issues
    return (
      error?.message?.toLowerCase().includes("network") ||
      error?.message?.toLowerCase().includes("connection") ||
      error?.code?.includes("NETWORK") ||
      error?.code?.includes("CONNECTION") ||
      error?.name === "NetworkError" ||
      error?.response?.status === 0 || // No response from server
      error?.request?.status === 0 || // No response from server
      !navigator.onLine // Browser is offline
    );
  };

  componentDidCatch(error, errorInfo) {
    if (this.isNetworkError(error)) {
      debug.error("Network error caught by boundary:", { error, errorInfo });
      this.setState({ hasError: true, error });
    }
  }

  handleRetry = () => {
    // Check if we're back online
    if (navigator.onLine) {
      this.setState({ hasError: false, error: null });
      window.location.reload();
    } else {
      // Still offline, show message
      this.setState({
        error: new Error("Still offline. Please check your connection."),
      });
    }
  };

  static getDerivedStateFromError(error) {
    // Only update state for network errors
    if (
      error?.message?.toLowerCase().includes("network") ||
      error?.message?.toLowerCase().includes("connection") ||
      error?.code?.includes("NETWORK") ||
      error?.code?.includes("CONNECTION") ||
      error?.name === "NetworkError" ||
      error?.response?.status === 0 ||
      error?.request?.status === 0 ||
      !navigator.onLine
    ) {
      return { hasError: true, error };
    }
    return null;
  }

  render() {
    if (this.state.hasError) {
      return (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: "100vh",
            textAlign: "center",
            p: 3,
          }}
        >
          <Typography variant="h4" gutterBottom>
            Connection Error
          </Typography>
          <Typography variant="body1" sx={{ mb: 4 }}>
            We're having trouble connecting to our servers. Please check your
            internet connection and try again.
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={this.handleRetry}
            size="large"
          >
            Retry Connection
          </Button>
        </Box>
      );
    }

    return this.props.children;
  }
}

export default NetworkErrorBoundary;

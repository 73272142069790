import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  StepLabel as MuiStepLabel,
  Step,
  StepConnector,
  Stepper,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { PlannerWizardContext } from "../../contexts/planner-wizard-context";
import { QuickstartWizardContext } from "../../contexts/quickstart-wizard-context";
import { WizardContext } from "../../contexts/wizard-context";

const StepperWrapper = styled(Box)(({ theme }) => ({
  width: "100%",
  overflowX: "hidden",
  marginBottom: theme.spacing(4),
}));

const CompactStepper = styled(Stepper)(({ theme }) => ({
  "& .MuiStepLabel-label": {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  "& .MuiStepIcon-root": {
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.25rem",
    },
  },
  "& .MuiStepConnector-line": {
    [theme.breakpoints.down("sm")]: {
      minHeight: 8,
    },
  },
}));

const StyledStepLabel = styled(MuiStepLabel)(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    padding: 0,
    "& .MuiStepLabel-iconContainer": {
      paddingRight: 0,
    },
  },
}));

const MobileStepInfo = styled(Box)(({ theme }) => ({
  textAlign: "center",
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(1),
}));

const HsWizard = ({
  open,
  handleClose,
  title,
  steps,
  onComplete,
  maxVisibleSteps = steps.length,
  finishButtonText = "Add to Cart",
  loading = false,
  error = null,
  setError,
  contextType,
}) => {
  const contextValue = useContext(
    contextType === "planner"
      ? PlannerWizardContext
      : contextType === "quickstart"
      ? QuickstartWizardContext
      : WizardContext
  );

  const wizardState = contextValue;

  useEffect(() => {
    if (!wizardState) {
      return;
    }
  }, [wizardState]);

  // Destructure wizardState early to access all needed properties
  const {
    activeStep = 0,
    handleStepChange,
    wizardData = {},
    updateWizardData = () => {},
    updateStepValidation = () => {},
    stepValidation = {},
    isSubmitting: stateIsSubmitting = false,
    validateStep,
  } = wizardState;

  // Initialize state if not provided
  const [internalWizardData, setInternalWizardData] = useState(wizardData);
  const [internalStepValidation, setInternalStepValidation] =
    useState(stepValidation);

  const handleUpdateWizardData = useCallback(
    (data) => {
      setInternalWizardData((prev) => ({ ...prev, ...data }));
      updateWizardData(data);
    },
    [updateWizardData]
  );

  const handleUpdateStepValidation = useCallback(
    (stepIndex, isValid) => {
      setInternalStepValidation((prev) => ({ ...prev, [stepIndex]: isValid }));
      updateStepValidation(stepIndex, isValid);
    },
    [updateStepValidation]
  );

  // Use internal or external state
  const effectiveWizardData = wizardData || internalWizardData;
  const effectiveStepValidation = stepValidation || internalStepValidation;

  // Wrap wizardSteps in useMemo with better validation
  const wizardSteps = useMemo(() => {
    const stepsToUse = steps || wizardState.steps;
    if (!stepsToUse || !Array.isArray(stepsToUse)) {
      return [];
    }
    return stepsToUse;
  }, [steps, wizardState.steps]);

  // Wrap validation in useMemo
  const isValidSteps = useMemo(() => {
    return wizardSteps.length > 0 && wizardSteps.every((step) => step != null);
  }, [wizardSteps]);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [internalError, setInternalError] = useState(null);

  // Calculate safe active step early
  const safeActiveStep = useMemo(() => {
    if (!isValidSteps || !wizardSteps.length) return 0;
    return Math.min(
      Math.max(0, activeStep),
      Math.max(0, wizardSteps.length - 1)
    );
  }, [activeStep, isValidSteps, wizardSteps]);

  const handleNext = async () => {
    // If step has a handleNext function, call it
    if (wizardSteps[activeStep]?.ref?.current?.handleNext) {
      const canProceed = await wizardSteps[activeStep].ref.current.handleNext();

      if (!canProceed) {
        return;
      }
    }

    if (activeStep === wizardSteps.length - 1) {
      onComplete?.(wizardData);
    } else {
      handleStepChange("next");
    }
  };

  const handleBack = useCallback(() => {
    // Check if current step has a handleBack method
    if (wizardSteps[activeStep]?.ref?.current?.handleBack) {
      const canProceed = wizardSteps[activeStep].ref.current.handleBack();
      if (!canProceed) {
        return;
      }
    }

    handleStepChange("back");
    if (setError) {
      setError(null);
    }
  }, [activeStep, wizardSteps, handleStepChange, setError]);

  // Move getStepContent into useCallback
  const getStepContent = useCallback(
    (step, index) => {
      if (!step) return null;
      if (step.component) {
        const Component = step.component;
        return (
          <Component
            ref={step.ref}
            {...step.props}
            wizardData={effectiveWizardData}
            updateWizardData={handleUpdateWizardData}
            updateStepValidation={handleUpdateStepValidation}
            stepIndex={index}
            stepValidation={effectiveStepValidation}
            validateStep={validateStep}
          />
        );
      }
      return step.content;
    },
    [
      effectiveWizardData,
      handleUpdateWizardData,
      handleUpdateStepValidation,
      effectiveStepValidation,
      validateStep,
    ]
  );

  // Update the currentStepContent useMemo to use memoized getStepContent
  const currentStepContent = useMemo(() => {
    const currentStep = wizardSteps[safeActiveStep];
    return getStepContent(currentStep, safeActiveStep);
  }, [wizardSteps, safeActiveStep, getStepContent]);

  // Update isStepValid to include validateStep in dependencies
  const isStepValid = useCallback(
    (step, stepIndex) => {
      if (!step) return false;

      let isValid = false;
      let validationSource = "";

      // Check ref.isValid first
      if (step.ref?.current?.isValid) {
        isValid = step.ref.current.isValid();
        validationSource = "ref.isValid";
      }
      // Then check step.isValid
      else if (typeof step.isValid === "function") {
        isValid = step.isValid(effectiveWizardData);
        validationSource = "step.isValid";
      }
      // Finally fall back to stepValidation
      else {
        isValid = effectiveStepValidation[stepIndex] ?? false;
        validationSource = "stepValidation";
      }

      return isValid;
    },
    [effectiveStepValidation, effectiveWizardData]
  );

  // Add debug logging for next button state
  const isNextButtonDisabled = useMemo(() => {
    const currentStep = wizardSteps[safeActiveStep];
    const hasCurrentStep = Boolean(currentStep);
    const isLoading = loading || stateIsSubmitting;
    const isValid = isStepValid(currentStep, safeActiveStep);

    return !hasCurrentStep || isLoading || !isValid;
  }, [wizardSteps, safeActiveStep, loading, stateIsSubmitting, isStepValid]);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  // Function to get visible steps based on current step and screen size
  const getVisibleSteps = useCallback(() => {
    if (!isMobile) return wizardSteps;

    const totalSteps = wizardSteps.length;
    const currentIndex = safeActiveStep;

    // Show all steps if 3 or fewer
    if (totalSteps <= 3) return wizardSteps;

    const visibleSteps = [];

    // Always show first step
    visibleSteps.push(wizardSteps[0]);

    // Show ellipsis after first step if not at beginning
    if (currentIndex > 1) {
      visibleSteps.push({ id: "ellipsis-start", isEllipsis: true });
    }

    // Always show current step
    if (currentIndex > 0 && currentIndex < totalSteps - 1) {
      visibleSteps.push(wizardSteps[currentIndex]);
    }

    // Show ellipsis before last step if not at end
    if (currentIndex < totalSteps - 2) {
      visibleSteps.push({ id: "ellipsis-end", isEllipsis: true });
    }

    // Always show last step
    visibleSteps.push(wizardSteps[totalSteps - 1]);

    return visibleSteps;
  }, [wizardSteps, safeActiveStep, isMobile]);

  // Early returns with better error messages
  if (!open) return null;

  if (!isValidSteps) {
    return null;
  }

  const currentStep = wizardSteps[safeActiveStep];

  if (!currentStep) {
    return null;
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="md"
      fullWidth
      PaperProps={{
        sx: {
          maxHeight: "90vh",
          display: "flex",
          flexDirection: "column",
        },
      }}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Box
          sx={{
            width: "100%",
            mt: 2,
            display: "flex",
            flexDirection: "column",
            gap: 2,
          }}
        >
          <StepperWrapper>
            {isMobile && (
              <MobileStepInfo>
                <Typography variant="body2" color="text.secondary" gutterBottom>
                  Step {safeActiveStep + 1} of {wizardSteps.length}
                </Typography>
                <Typography
                  variant="subtitle1"
                  color="primary"
                  sx={{
                    fontWeight: 500,
                    whiteSpace: "normal",
                    maxWidth: "100%",
                    px: 2,
                  }}
                >
                  {wizardSteps[safeActiveStep]?.label}
                </Typography>
              </MobileStepInfo>
            )}
            <CompactStepper
              activeStep={safeActiveStep}
              alternativeLabel={!isMobile}
              connector={isMobile ? <StepConnector /> : undefined}
            >
              {getVisibleSteps().map((step, index) => (
                <Step key={step.id || index}>
                  {step.isEllipsis ? (
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        px: 1,
                      }}
                    >
                      <Typography
                        variant="body2"
                        sx={{
                          color: "text.secondary",
                          fontSize: "1rem",
                          lineHeight: 1,
                        }}
                      >
                        •••
                      </Typography>
                    </Box>
                  ) : (
                    <StyledStepLabel>{!isMobile && step.label}</StyledStepLabel>
                  )}
                </Step>
              ))}
            </CompactStepper>
          </StepperWrapper>

          {(error || internalError) && (
            <Typography color="error" sx={{ mb: 2 }}>
              {error || internalError}
            </Typography>
          )}

          <Box sx={{ flex: 1 }}>{currentStepContent}</Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              mt: "auto",
              pt: 2,
            }}
          >
            <Button
              onClick={handleBack}
              disabled={safeActiveStep === 0 || loading || isSubmitting}
            >
              Back
            </Button>
            <Button
              variant="contained"
              onClick={handleNext}
              disabled={isNextButtonDisabled}
            >
              {loading || isSubmitting ? (
                <CircularProgress size={24} />
              ) : safeActiveStep === wizardSteps.length - 1 ? (
                finishButtonText
              ) : (
                "Next"
              )}
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default HsWizard;

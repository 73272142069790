import apiUrl from "../config/config.js";
import { api } from "./api.js";

const processTermsResponse = (response) => {
  return {
    data: response.data.content,
    totalCount: response.data.totalElements,
    currentPage: response.data.page,
    pageSize: response.data.size,
    totalPages: response.data.totalPages,
  };
};

export const fetchActiveTerms = async (page = 0, size = 10, signal) => {
  const url = `${apiUrl}/api/v1/terms/active`;
  return doFetchTerms(url, page, size, "ASC", signal);
};

export const fetchPastTerms = async (page = 0, size = 10, signal) => {
  const url = `${apiUrl}/api/v1/terms/past`;
  return doFetchTerms(url, page, size, "ASC", signal);
};

export const fetchFutureTerms = async (page = 0, size = 10, signal) => {
  const url = `${apiUrl}/api/v1/terms/future`;
  return doFetchTerms(url, page, size, "ASC", signal);
};

const doFetchTerms = async (
  url,
  page = 0,
  size = 10,
  sortDirection = "ASC",
  signal
) => {
  try {
    const response = await api.get(url, {
      params: {
        page,
        size,
        sortDirection,
      },
      signal,
    });
    return processTermsResponse(response);
  } catch (error) {
    throw error;
  }
};

export const createTerm = async (
  accountId,
  startDate,
  endDate,
  instructionHours,
  name,
  signal
) => {
  try {
    const response = await api.post(
      `${apiUrl}/api/v1/terms?account_id=${accountId}`,
      {
        startDate,
        endDate,
        name,
        instructionHours,
      },
      { signal }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateTerm = async (
  id,
  startDate,
  endDate,
  instructionHours,
  name,
  signal
) => {
  try {
    const response = await api.put(
      `${apiUrl}/api/v1/terms/${id}`,
      {
        startDate,
        endDate,
        name,
        instructionHours,
      },
      { signal }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deleteTerm = async (id, signal) => {
  try {
    const response = await api.delete(`${apiUrl}/api/v1/terms/${id}`, {
      signal,
    });
    return response.data;
  } catch (error) {
    // Transform error messages for better UX
    if (error?.response?.status === 404) {
      throw new Error("Term not found");
    } else if (error?.response?.status === 403) {
      throw new Error("You don't have permission to delete this term");
    } else if (error?.code === "ERR_NETWORK") {
      throw new Error(
        "Network error. Please check your connection and try again."
      );
    }
    throw error;
  }
};

export const updateInstructionHours = async (schoolTermId, data, signal) => {
  try {
    const response = await api.post(
      `${apiUrl}/api/v1/terms/instruction-hours/${schoolTermId}`,
      data,
      { signal }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchActiveAndFutureTerms = async (signal) => {
  try {
    const [activeTermsResponse, futureTermsResponse] = await Promise.all([
      api.get(`${apiUrl}/api/v1/terms/active`, { signal }),
      api.get(`${apiUrl}/api/v1/terms/future`, { signal }),
    ]);

    const activeTerms = processTermsResponse(activeTermsResponse);
    const futureTerms = processTermsResponse(futureTermsResponse);

    return {
      data: [...activeTerms.data, ...futureTerms.data],
      totalCount: activeTerms.totalCount + futureTerms.totalCount,
    };
  } catch (error) {
    throw error;
  }
};

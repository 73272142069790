// authApi.js
import { createAsyncThunk } from "@reduxjs/toolkit";
import apiUrl from "../config/config.js";
import { clearAuthInfo } from "../redux/authSlice";
import { debug } from "../utils/debug";
import { parseJwt } from "../utils/utils";
import { api } from "./api";

const processAuthResponse = (responseData) => {
  // Extract data from the AuthResponse wrapper if present
  const authData = responseData.data || responseData;

  debug.auth.log("Processing auth response", { authData });

  const { token, user, accounts, showQuickStart, calendarConnections } =
    authData;

  const decodedToken = parseJwt(token);
  const loggedInUserRoles = decodedToken.roles || [];

  // Process calendar connections maintaining backward compatibility
  const processedCalendarConnections = {};
  if (calendarConnections?.GOOGLE) {
    const googleStatus = calendarConnections.GOOGLE;
    processedCalendarConnections.GOOGLE = {
      connected: googleStatus.connected || false,
      needsReconnect: googleStatus.needsReconnect || false,
      defaultSyncCalendarId: googleStatus.defaultSyncCalendarId || null,
    };
  }

  // Add logging to see what we're returning
  const result = {
    token,
    loggedInUser: user || {},
    loggedInUserRoles,
    loggedInUserAccounts: accounts || [],
    showQuickStart,
    calendarConnections: processedCalendarConnections,
    // Maintain existing fields for backward compatibility
    googleCalendarConnected:
      processedCalendarConnections.GOOGLE?.connected || false,
    reconnectGoogleCalendar:
      processedCalendarConnections.GOOGLE?.needsReconnect || false,
    defaultSyncCalendarId:
      processedCalendarConnections.GOOGLE?.defaultSyncCalendarId || null,
  };

  debug.auth.log("Processed Auth Result:", result);
  return result;
};

export const doPerformLogin = async (credentials) => {
  try {
    const response = await api.post(
      `${apiUrl}/api/v1/auth/login`,
      JSON.stringify(credentials)
    );
    return processAuthResponse(response.data);
  } catch (error) {
    // Check for unverified user status
    if (error.response?.data?.status === "USER_EXISTS_UNVERIFIED") {
      const verificationError = new Error("User exists but is not verified");
      verificationError.response = {
        data: {
          status: "USER_EXISTS_UNVERIFIED",
        },
        config: {
          data: JSON.stringify(credentials),
        },
      };
      throw verificationError;
    }
    throw error;
  }
};

export const verifyEmail = async (verificationToken) => {
  const response = await api.post(`${apiUrl}/api/v1/auth/verify-email`, {
    verificationToken,
  });

  const { status, data } = response.data;
  return {
    status,
    authInfo: data?.authInfo ? processAuthResponse(data.authInfo) : null,
  };
};

/**
 * Handles Google sign-in authentication, optionally with an invite flow
 * @param {Object} credentials - Google OAuth credentials
 * @param {string} credentials.credential - The Google OAuth credential token
 * @param {boolean} credentials.rememberMe - Whether to remember the login
 * @param {string} [inviteId] - Optional invite ID for accepting invitations
 * @returns {Promise<Object>} Processed authentication response
 */
export const signInByGoogle = async (credentials, inviteId) => {
  // Construct the URL with optional invite parameter
  const baseUrl = `${apiUrl}/api/v1/auth/google-signin`;
  const url = inviteId ? `${baseUrl}?state=${inviteId}` : baseUrl;

  const response = await api.post(
    url,
    JSON.stringify({
      credential: credentials.credential,
      rememberMe: credentials.rememberMe ?? true,
    })
  );

  return processAuthResponse(response.data);
};

export const registerInitial = async (registrationData) => {
  const response = await api.post(
    "/api/v1/auth/register-initial",
    registrationData
  );
  return response.data;
};

export const logout = createAsyncThunk(
  "auth/logout",
  async (_, { dispatch }) => {
    try {
      await api.post(`${apiUrl}/api/v1/auth/logout`, {});
    } catch (error) {
      // Remove redundant logging
    } finally {
      dispatch(clearAuthInfo());
    }
  }
);

export const acceptInvite = async (credentials) => {
  try {
    const url = `${apiUrl}/api/v1/auth/accept-invite`;
    const response = await api.post(url, JSON.stringify(credentials));

    if (response.status === 202) {
      return response.data;
    } else {
      throw new Error(`Accept invite failed: ${response.statusText}`);
    }
  } catch (error) {
    throw new Error(`Accept invite error: ${error.message}`);
  }
};

export const updatePlanType = async (accountId, planType) => {
  try {
    const response = await api.post(
      `${apiUrl}/api/v1/accounts/${accountId}/update-plan`,
      { planType }
    );

    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error(`Update plan type failed: ${response.statusText}`);
    }
  } catch (error) {
    throw new Error(`Update plan type error: ${error.message}`);
  }
};

export const resendVerification = async (email) => {
  try {
    const response = await api.post(
      `${apiUrl}/api/v1/auth/resend-verification`,
      { email }
    );
    return response.data;
  } catch (error) {
    if (error.response?.status === 429) {
      // Rate limited - Create a proper Error object
      const nextAllowedTime = error.response.data.data.nextAllowedResendTime;
      const rateLimitError = new Error(error.response.data.data.message);
      rateLimitError.type = "RATE_LIMITED";
      rateLimitError.nextAllowedTime = nextAllowedTime;
      throw rateLimitError;
    }
    throw error;
  }
};

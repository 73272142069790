import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import { Box, IconButton, Stack, Typography, useTheme } from "@mui/material";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import dayjs from "dayjs";
import React, { useCallback } from "react";
import { getFullDate } from "../utils";

const InstructionHour = React.memo(
  ({
    day,
    index,
    hour,
    handleChangeTime,
    handleRemoveDayInstructionHour,
    error,
    isMobile,
  }) => {
    const theme = useTheme();
    const handleTimeChange = useCallback(
      (field, value) => {
        if (value && value.isValid()) {
          handleChangeTime(day, index, field, value.format("HH:mm"));
        }
      },
      [day, index, handleChangeTime]
    );

    const timePickerProps = {
      ampm: true,
      views: ["hours", "minutes"],
      slotProps: {
        textField: {
          size: "small",
          error: error?.startTime || error?.endTime,
          helperText: error?.startTime || error?.endTime,
          sx: {
            "& .MuiOutlinedInput-root": {
              borderRadius: 1,
              backgroundColor: theme.palette.background.paper,
            },
            "& .MuiInputLabel-root": {
              fontSize: isMobile ? "0.875rem" : "inherit",
            },
          },
        },
      },
    };

    return (
      <Box
        sx={{
          backgroundColor: theme.palette.background.paper,
          borderRadius: 1,
          p: 1.5,
          boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.1)",
        }}
      >
        <Stack
          direction={isMobile ? "column" : "row"}
          spacing={2}
          alignItems={isMobile ? "stretch" : "center"}
        >
          <TimePicker
            label="Start Time"
            value={hour.startTime ? dayjs(getFullDate(hour.startTime)) : null}
            onChange={(val) => handleTimeChange("startTime", val)}
            {...timePickerProps}
          />

          <Typography
            variant="body2"
            sx={{
              color: theme.palette.text.secondary,
              textAlign: isMobile ? "center" : "inherit",
              py: isMobile ? 0.5 : 0,
            }}
          >
            to
          </Typography>

          <TimePicker
            label="End Time"
            value={hour.endTime ? dayjs(getFullDate(hour.endTime)) : null}
            onChange={(val) => handleTimeChange("endTime", val)}
            {...timePickerProps}
          />

          <IconButton
            aria-label="remove time range"
            size="small"
            onClick={() => handleRemoveDayInstructionHour(day, index)}
            sx={{
              alignSelf: isMobile ? "center" : "auto",
              color: theme.palette.error.main,
              "&:hover": {
                backgroundColor: theme.palette.error.light,
              },
            }}
          >
            <RemoveCircleIcon fontSize="small" />
          </IconButton>
        </Stack>
      </Box>
    );
  }
);

export default InstructionHour;

import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Grid,
  Radio,
  Typography,
  useTheme,
} from "@mui/material";
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { usePlannerWizardContext } from "../../contexts/planner-wizard-context";

const themes = [
  { id: 1, name: "Floral" },
  { id: 2, name: "Classic" },
  { id: 3, name: "Modern" },
  { id: 4, name: "Minimalist" },
];

const ThemeSelection = forwardRef((props, ref) => {
  const {
    wizardData,
    updateWizardData,
    updateStepValidation,
    resetPreviewGeneration,
  } = usePlannerWizardContext();

  const [selectedTheme, setSelectedTheme] = useState(
    wizardData.themeId || null
  );
  const theme = useTheme();

  // Expose validation method through ref
  useImperativeHandle(
    ref,
    () => ({
      isValid: () => Boolean(selectedTheme),
    }),
    [selectedTheme]
  );

  const handleThemeSelect = useCallback(
    (themeId) => {
      updateWizardData({
        themeId,
        // Reset preview-related data when theme changes
        previewUrls: null,
        status: "PENDING",
        reportId: null,
        pageCount: null,
        itemPrice: null,
        generatedFor: null,
      });
      setSelectedTheme(themeId);
    },
    [updateWizardData]
  );

  // Update validation state when theme selection changes
  useEffect(() => {
    const isValid = Boolean(selectedTheme);
    updateStepValidation(isValid);
  }, [selectedTheme, updateStepValidation]);

  // Initialize from wizard data
  useEffect(() => {
    if (wizardData.themeId && !selectedTheme) {
      setSelectedTheme(wizardData.themeId);
    }
  }, [wizardData.themeId, selectedTheme]);

  return (
    <Box>
      <Typography variant="h6" gutterBottom>
        Select a Theme for Your Planner
      </Typography>
      <Grid container spacing={3}>
        {themes.map((themeOption) => (
          <Grid item xs={12} sm={6} key={themeOption.id}>
            <Card
              sx={{
                cursor: "pointer",
                border: selectedTheme === themeOption.id ? "2px solid" : "none",
                borderColor: "primary.main",
                height: "100%",
                display: "flex",
                flexDirection: "column",
              }}
              onClick={() => handleThemeSelect(themeOption.id)}
            >
              <CardMedia
                component="img"
                sx={{
                  width: "100%",
                  height: "auto",
                  aspectRatio: "8.5 / 11",
                  objectFit: "cover",
                }}
                image={`/assets/images/${themeOption.name.toLowerCase()}-theme-image.png`}
                alt={themeOption.name}
              />
              <CardContent
                sx={{ flexGrow: 1, display: "flex", alignItems: "center" }}
              >
                <Radio
                  checked={selectedTheme === themeOption.id}
                  onChange={() => handleThemeSelect(themeOption.id)}
                />
                <Typography variant="subtitle1">{themeOption.name}</Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
});

export default ThemeSelection;

// src/config/config.js

const env = process.env.REACT_APP_ENV || "dev";

const config = {
  local: {
    apiUrl: "http://localhost:8081",
    frontendUrl: "http://localhost:3000",
  },
  dev: {
    apiUrl: "https://dev-api.homeschoolplanner.com",
    frontendUrl: "https://dev-app.homeschoolplanner.com",
  },
  stg: {
    apiUrl: "https://stg-api.homeschoolplanner.com",
    frontendUrl: "https://stg-app.homeschoolplanner.com",
  },
  prd: {
    apiUrl: "https://api.homeschoolplanner.com",
    frontendUrl: "https://app.homeschoolplanner.com",
  },
};

export const { apiUrl, frontendUrl } = config[env];
export default apiUrl;

// Centralize all environment variable access
export const CONFIG = {
  STRIPE_PUBLISHABLE_KEY: process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY,
  NODE_ENV: process.env.NODE_ENV,
  REACT_APP_GOOGLE_CLIENT_ID: process.env.REACT_APP_GOOGLE_CLIENT_ID,
  REACT_APP_GOOGLE_MAPS_API_KEY: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  REACT_APP_STRIPE_PRICING_TABLE_ID:
    process.env.REACT_APP_STRIPE_PRICING_TABLE_ID,
  REACT_APP_INVITE_ONLY: process.env.REACT_APP_INVITE_ONLY,
  REACT_APP_PUBLIC_URL: process.env.PUBLIC_URL,
  REACT_APP_ENABLE_AUTH_LOGS: process.env.REACT_APP_ENABLE_AUTH_LOGS,
  REACT_APP_ENABLE_CALENDAR_LOGS: process.env.REACT_APP_ENABLE_CALENDAR_LOGS,
  REACT_APP_ENABLE_AI_ASSISTANT: true,
};

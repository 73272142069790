import { Box, Button, Typography } from "@mui/material";
import React from "react";
import { debug } from "../utils/debug";

class ErrorBoundary extends React.Component {
  state = {
    hasError: false,
    error: null,
    errorInfo: null,
  };

  componentDidMount() {
    // Catch all unhandled promise rejections
    window.addEventListener(
      "unhandledrejection",
      this.handleUnhandledRejection
    );
  }

  componentWillUnmount() {
    window.removeEventListener(
      "unhandledrejection",
      this.handleUnhandledRejection
    );
  }

  handleUnhandledRejection = (event) => {
    debug.error("Error caught by boundary:", event.reason);
    this.setState({
      hasError: true,
      error: event.reason,
    });
    event.preventDefault(); // Prevent the error from propagating
  };

  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  componentDidCatch(error, errorInfo) {
    debug.error("Error caught by boundary:", { error, errorInfo });
    this.setState({ errorInfo });
  }

  isNetworkError = (error) => {
    return (
      error?.message?.toLowerCase().includes("network") ||
      error?.message?.toLowerCase().includes("connection") ||
      error?.code?.includes("NETWORK") ||
      error?.code?.includes("CONNECTION") ||
      error?.name === "NetworkError" ||
      error?.response?.status === 0 ||
      error?.request?.status === 0 ||
      !navigator.onLine
    );
  };

  getErrorMessage = (error) => {
    if (this.isNetworkError(error)) {
      return "We're having trouble connecting to our servers. Please check your internet connection and try again.";
    }

    if (error?.response?.status === 403) {
      return "You don't have permission to perform this action.";
    }

    if (error?.response?.status === 401) {
      return "Your session has expired. Please log in again.";
    }

    // Get message from error object, with fallbacks
    return (
      error?.response?.data?.message ||
      error?.message ||
      "Something went wrong. Please try again."
    );
  };

  handleRetry = () => {
    if (navigator.onLine) {
      this.setState({ hasError: false, error: null, errorInfo: null });
      window.location.reload();
    }
  };

  render() {
    if (this.state.hasError) {
      const isNetworkError = this.isNetworkError(this.state.error);

      return (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: "100vh",
            textAlign: "center",
            p: 3,
          }}
        >
          <Typography variant="h4" gutterBottom>
            {isNetworkError ? "Connection Error" : "Something Went Wrong"}
          </Typography>
          <Typography variant="body1" sx={{ mb: 4 }}>
            {this.getErrorMessage(this.state.error)}
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={this.handleRetry}
            size="large"
          >
            {isNetworkError ? "Retry Connection" : "Reload Page"}
          </Button>
        </Box>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;

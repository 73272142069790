import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import DescriptionIcon from "@mui/icons-material/Description";
import InfoIcon from "@mui/icons-material/Info";
import PersonIcon from "@mui/icons-material/Person";
import { LoadingButton } from "@mui/lab";
import {
  Autocomplete,
  Box,
  Button,
  Chip,
  FormControl,
  FormControlLabel,
  Grid,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { generateReport, REPORT_TYPE } from "../../api/reportApi";
import { getStudentsByTermId, getStudentTerms } from "../../api/studentApi";
import {
  fetchActiveTerms,
  fetchFutureTerms,
  fetchPastTerms,
} from "../../api/termApi";
import { getStudents } from "../../api/userApi";
import { useReportContext } from "../../contexts/report-context";
import { useAsyncOperation } from "../../hooks/useAsyncOperation";
import { debug } from "../../utils/debug";

const POLLING_INTERVAL = 3000;

const ReportGenerator = () => {
  const navigate = useNavigate();
  const [isGenerating, setIsGenerating] = useState(false);
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [selectedTerm, setSelectedTerm] = useState(null);
  const [selectedReportType, setSelectedReportType] = useState(
    REPORT_TYPE.ATTENDANCE_BY_EVENT
  );
  const [selectedFormat, setSelectedFormat] = useState("PDF");
  const [termStudents, setTermStudents] = useState([]);
  const [transcriptType, setTranscriptType] = useState("cumulative");
  const [selectedTerms, setSelectedTerms] = useState([]);
  const [availableTerms, setAvailableTerms] = useState([]);
  const [students, setStudents] = useState([]);
  const [isLoadingStudents, setIsLoadingStudents] = useState(false);

  const selectedAccountId = useSelector(
    (state) => state.auth.selectedAccountId
  );
  const { setMessage } = useReportContext();

  // Fetch term students with useAsyncOperation
  const { execute: fetchTermStudents } = useAsyncOperation(
    async (termId, signal) => {
      if (!termId) return [];
      const students = await getStudentsByTermId(
        termId,
        selectedAccountId,
        signal
      );
      return students || [];
    }
  );

  // Fetch terms with useAsyncOperation
  const { data: terms, execute: fetchTerms } = useAsyncOperation(
    async (signal) => {
      const [activeTerms, pastTerms, futureTerms] = await Promise.all([
        fetchActiveTerms(0, 100, signal),
        fetchPastTerms(0, 100, signal),
        fetchFutureTerms(0, 100, signal),
      ]);

      const combinedTerms = [
        ...(activeTerms?.data || []),
        ...(pastTerms?.data || []),
        ...(futureTerms?.data || []),
      ];

      return combinedTerms;
    }
  );

  // Fetch students on mount
  useEffect(() => {
    const fetchStudents = async () => {
      setIsLoadingStudents(true);
      try {
        const fetchedStudents = await getStudents(selectedAccountId);
        setStudents(fetchedStudents);
      } catch (error) {
        debug.error("Error fetching students:", error);
        setMessage({
          text: "Failed to fetch students",
          severity: "error",
          flag: true,
        });
      } finally {
        setIsLoadingStudents(false);
      }
    };

    if (selectedReportType === REPORT_TYPE.TRANSCRIPT) {
      fetchStudents();
    }
  }, [selectedReportType, selectedAccountId, setMessage]);

  // Restore term fetching on mount
  useEffect(() => {
    fetchTerms();
  }, [fetchTerms]);

  // Generate report operation
  const { execute: executeGenerateReport } = useAsyncOperation(
    async (request, signal) => {
      debug.log("Executing report generation with request:", request);
      const result = await generateReport(request, signal);

      setMessage({
        text: "Report generation started. Check the reports page for status.",
        severity: "success",
        flag: true,
      });

      navigate("/reports");
      return result;
    }
  );

  // Handle term selection change
  const handleTermChange = useCallback(
    async (newTerm) => {
      debug.log("Term changed to:", newTerm);
      setSelectedTerm(newTerm);
      setSelectedStudent(null); // Clear student selection when term changes

      if (newTerm) {
        try {
          const students = await fetchTermStudents(newTerm.id);
          debug.log("Fetched students for term:", students);
          setTermStudents(students);
        } catch (error) {
          debug.error("Error fetching term students:", error);
          if (error.code !== "ERR_CANCELED") {
            setMessage({
              text: "Failed to fetch students for selected term",
              severity: "error",
              flag: true,
            });
            setTermStudents([]);
          }
        }
      } else {
        setTermStudents([]);
      }
    },
    [fetchTermStudents, setMessage]
  );

  // Fetch terms when student is selected for transcript
  useEffect(() => {
    if (selectedStudent && selectedReportType === REPORT_TYPE.TRANSCRIPT) {
      const fetchStudentTerms = async () => {
        try {
          const terms = await getStudentTerms(
            selectedStudent.id,
            selectedAccountId
          );
          setAvailableTerms(terms);
        } catch (error) {
          debug.error("Error fetching student terms:", error);
          setMessage({
            text: "Failed to fetch terms for student",
            severity: "error",
            flag: true,
          });
        }
      };
      fetchStudentTerms();
    }
  }, [selectedStudent, selectedReportType, selectedAccountId, setMessage]);

  const handleTermToggle = (term) => {
    setSelectedTerms((prev) => {
      const isSelected = prev.some((t) => t.id === term.id);
      if (isSelected) {
        return prev.filter((t) => t.id !== term.id);
      }
      return [...prev, term];
    });
  };

  const getReportTypeLabel = useCallback((type) => {
    switch (type) {
      case REPORT_TYPE.ATTENDANCE_BY_EVENT:
        return "Attendance Report";
      case REPORT_TYPE.TRANSCRIPT:
        return "Academic Transcript";
      default:
        return type;
    }
  }, []);

  const handleGenerateReport = useCallback(async () => {
    if (!selectedStudent) {
      setMessage({
        text: "Please select a student",
        severity: "warning",
        flag: true,
      });
      return;
    }

    // Validate based on report type
    if (selectedReportType === REPORT_TYPE.TRANSCRIPT) {
      if (transcriptType === "selected" && selectedTerms.length === 0) {
        setMessage({
          text: "Please select at least one term or choose cumulative transcript",
          severity: "warning",
          flag: true,
        });
        return;
      }
    } else if (!selectedTerm) {
      setMessage({
        text: "Please select a term",
        severity: "warning",
        flag: true,
      });
      return;
    }

    setIsGenerating(true);

    try {
      const requestData = {
        reportType: selectedReportType,
        studentId: selectedStudent.id,
        accountId: selectedAccountId,
        format: selectedFormat,
        ...(selectedReportType === REPORT_TYPE.TRANSCRIPT
          ? transcriptType === "selected"
            ? { termIds: selectedTerms.map((t) => t.id) }
            : {} // Empty object for cumulative
          : { termId: selectedTerm.id }),
      };

      await executeGenerateReport(requestData);
    } catch (error) {
      debug.error("Generate report error:", error);
      setIsGenerating(false);

      if (error?.code === "ERR_NETWORK") {
        setMessage({
          text: "Unable to connect to server. Please check your connection and try again.",
          severity: "error",
          flag: true,
        });
      } else {
        setMessage({
          text: error?.message || "Failed to generate report",
          severity: "error",
          flag: true,
        });
      }
    }
  }, [
    selectedStudent,
    selectedTerm,
    selectedReportType,
    selectedFormat,
    selectedAccountId,
    transcriptType,
    selectedTerms,
    executeGenerateReport,
    setMessage,
  ]);

  const renderTermSelection = () => {
    if (selectedReportType !== REPORT_TYPE.TRANSCRIPT || !selectedStudent) {
      return null;
    }

    return (
      <Box sx={{ mt: 3, mb: 2 }}>
        <Box sx={{ mb: 3 }}>
          <Typography variant="subtitle1" sx={{ mb: 1, fontWeight: 500 }}>
            Transcript Type
            <Tooltip title="Choose between a cumulative transcript of all terms or select specific terms">
              <InfoIcon
                fontSize="small"
                sx={{
                  ml: 1,
                  color: "text.secondary",
                  verticalAlign: "middle",
                }}
              />
            </Tooltip>
          </Typography>
          <RadioGroup
            value={transcriptType}
            onChange={(e) => {
              setTranscriptType(e.target.value);
              if (e.target.value === "cumulative") {
                setSelectedTerms([]);
              }
            }}
            sx={{ flexDirection: "row", gap: 3 }}
          >
            <FormControlLabel
              value="cumulative"
              control={
                <Radio
                  sx={{
                    color: "primary.main",
                    "&.Mui-checked": {
                      color: "primary.main",
                    },
                  }}
                />
              }
              label={
                <Typography variant="body1" sx={{ fontWeight: 500 }}>
                  Cumulative (All Terms)
                </Typography>
              }
            />
            <FormControlLabel
              value="selected"
              control={
                <Radio
                  sx={{
                    color: "primary.main",
                    "&.Mui-checked": {
                      color: "primary.main",
                    },
                  }}
                />
              }
              label={
                <Typography variant="body1" sx={{ fontWeight: 500 }}>
                  Select Terms
                </Typography>
              }
            />
          </RadioGroup>
        </Box>

        {transcriptType === "selected" && (
          <Box>
            <Typography variant="subtitle1" sx={{ mb: 1, fontWeight: 500 }}>
              Select Terms
              <Tooltip title="Choose one or more terms to include in the transcript">
                <InfoIcon
                  fontSize="small"
                  sx={{
                    ml: 1,
                    color: "text.secondary",
                    verticalAlign: "middle",
                  }}
                />
              </Tooltip>
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                gap: 1,
                p: 2,
                border: "1px solid",
                borderColor: "divider",
                borderRadius: 2,
                bgcolor: "background.paper",
              }}
            >
              {availableTerms.length === 0 ? (
                <Typography variant="body2" color="text.secondary">
                  No terms available for this student
                </Typography>
              ) : (
                availableTerms.map((term) => renderTermChip(term))
              )}
            </Box>
          </Box>
        )}
      </Box>
    );
  };

  // Update term chip display to use the simplified term data
  const renderTermChip = (term) => (
    <Chip
      key={term.id}
      label={term.name}
      onClick={() => handleTermToggle(term)}
      color={
        selectedTerms.some((t) => t.id === term.id) ? "primary" : "default"
      }
      variant={
        selectedTerms.some((t) => t.id === term.id) ? "filled" : "outlined"
      }
      sx={{
        borderRadius: 2,
        transition: "all 0.2s ease-in-out",
        "&:hover": {
          backgroundColor: (theme) =>
            selectedTerms.some((t) => t.id === term.id)
              ? theme.palette.primary.dark
              : theme.palette.primary.lighter,
        },
      }}
    />
  );

  return (
    <Box>
      {/* Enhanced Header Section */}
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          alignItems: { xs: "flex-start", sm: "center" },
          justifyContent: "space-between",
          mb: 4,
          gap: 2,
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Button
            startIcon={<ArrowBackIcon />}
            onClick={() => navigate("/reports")}
            sx={{ mr: 2 }}
          >
            Back to Reports
          </Button>
          <Typography variant="h2">Generate Report</Typography>
        </Box>
      </Box>

      {/* Main Content Area */}
      <Paper
        elevation={0}
        sx={{
          maxWidth: "800px",
          mx: "auto",
          p: { xs: 2, sm: 3 },
          borderRadius: 2,
          bgcolor: "background.paper",
          border: "1px solid",
          borderColor: "divider",
        }}
      >
        <Grid container spacing={3}>
          {/* Report Type Selection */}
          <Grid item xs={12} md={6}>
            <Typography variant="subtitle1" sx={{ mb: 1, fontWeight: 500 }}>
              Report Type
              <Tooltip title="Select the type of report you want to generate">
                <InfoIcon
                  fontSize="small"
                  sx={{
                    ml: 1,
                    color: "text.secondary",
                    verticalAlign: "middle",
                  }}
                />
              </Tooltip>
            </Typography>
            <FormControl fullWidth>
              <Select
                value={selectedReportType}
                onChange={(event) => {
                  setSelectedReportType(event.target.value);
                  // Reset selections when changing report type
                  setSelectedStudent(null);
                  setSelectedTerm(null);
                  setSelectedTerms([]);
                  setTranscriptType("cumulative");
                }}
                displayEmpty
                startAdornment={
                  <DescriptionIcon color="action" sx={{ ml: 1, mr: 1 }} />
                }
                disabled={isGenerating}
                sx={{
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderRadius: "8px",
                  },
                }}
              >
                <MenuItem value={REPORT_TYPE.ATTENDANCE_BY_EVENT}>
                  Attendance Report
                </MenuItem>
                <MenuItem value={REPORT_TYPE.TRANSCRIPT}>
                  Academic Transcript
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>

          {/* Format Selection */}
          <Grid item xs={12} md={6}>
            <Typography variant="subtitle1" sx={{ mb: 1, fontWeight: 500 }}>
              Format
              <Tooltip title="Choose the format for your report">
                <InfoIcon
                  fontSize="small"
                  sx={{
                    ml: 1,
                    color: "text.secondary",
                    verticalAlign: "middle",
                  }}
                />
              </Tooltip>
            </Typography>
            <FormControl fullWidth>
              <Select
                value={selectedFormat}
                onChange={(event) => setSelectedFormat(event.target.value)}
                displayEmpty
                startAdornment={
                  <DescriptionIcon color="action" sx={{ ml: 1, mr: 1 }} />
                }
                disabled={isGenerating}
                sx={{
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderRadius: "8px",
                  },
                }}
              >
                <MenuItem value="PDF">PDF Document</MenuItem>
                <MenuItem value="XLSX">Excel Spreadsheet</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          {/* Student Selection */}
          <Grid item xs={12} md={6}>
            <Typography variant="subtitle1" sx={{ mb: 1, fontWeight: 500 }}>
              Student
              <Tooltip title="Select the student for the report">
                <InfoIcon
                  fontSize="small"
                  sx={{
                    ml: 1,
                    color: "text.secondary",
                    verticalAlign: "middle",
                  }}
                />
              </Tooltip>
            </Typography>
            <FormControl fullWidth>
              <Autocomplete
                value={selectedStudent}
                onChange={(_, newValue) => setSelectedStudent(newValue)}
                options={students}
                getOptionLabel={(option) =>
                  `${option.firstName} ${option.lastName}`
                }
                isOptionEqualToValue={(option, value) => option.id === value.id}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Select Student"
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: (
                        <PersonIcon color="action" sx={{ ml: 1, mr: 1 }} />
                      ),
                    }}
                  />
                )}
                disabled={isGenerating}
              />
            </FormControl>
          </Grid>

          {/* Show Term Selection only for non-transcript reports */}
          {selectedReportType !== REPORT_TYPE.TRANSCRIPT && (
            <Grid item xs={12}>
              <Typography variant="subtitle1" sx={{ mb: 1, fontWeight: 500 }}>
                Term
                <Tooltip title="Select the term for the report">
                  <InfoIcon
                    fontSize="small"
                    sx={{
                      ml: 1,
                      color: "text.secondary",
                      verticalAlign: "middle",
                    }}
                  />
                </Tooltip>
              </Typography>
              <FormControl fullWidth>
                <Autocomplete
                  value={selectedTerm}
                  onChange={(_, newValue) => handleTermChange(newValue)}
                  options={terms || []}
                  getOptionLabel={(option) => option.name}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Select Term"
                      InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                          <CalendarTodayIcon
                            color="action"
                            sx={{ ml: 1, mr: 1 }}
                          />
                        ),
                      }}
                    />
                  )}
                  disabled={isGenerating}
                />
              </FormControl>
            </Grid>
          )}
        </Grid>

        {/* Transcript Term Selection */}
        {renderTermSelection()}

        {/* Action Buttons */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            gap: 2,
            mt: 4,
          }}
        >
          <Button
            variant="outlined"
            onClick={() => navigate("/reports")}
            disabled={isGenerating}
          >
            Cancel
          </Button>
          <LoadingButton
            loading={isGenerating}
            variant="contained"
            onClick={handleGenerateReport}
            startIcon={<DescriptionIcon />}
            size="large"
            disabled={
              !selectedStudent ||
              (selectedReportType !== REPORT_TYPE.TRANSCRIPT &&
                !selectedTerm) ||
              isGenerating
            }
            loadingPosition="start"
          >
            {isGenerating ? "Generating Report..." : "Generate Report"}
          </LoadingButton>
        </Box>
      </Paper>
    </Box>
  );
};

export default ReportGenerator;

import { Box, Tab, Tabs } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import ConfirmationDialog from "../../../components/common/ConfirmationDialog";
import HsModal from "../../../components/common/HsModal";
import EventDetailsTab from "./tabs/EventDetailsTab";
import LessonDetailsTab from "./tabs/LessonDetailsTab";

const formatProviderName = (source) => {
  switch (source) {
    case "GOOGLE":
      return "Google";
    case "MICROSOFT":
      return "Microsoft";
    default:
      return "Local";
  }
};

const EventDetailsViewModal = ({ event, open, handleClose, onEventUpdate }) => {
  const [activeTab, setActiveTab] = useState(0);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [pendingChanges, setPendingChanges] = useState(null);
  const theme = useTheme();

  // Only show lesson tab for class-related events
  const showLessonTab =
    event?.classLessonSummary || event?.classInstanceSummary;

  // Reset to first tab when modal opens or event changes
  useEffect(() => {
    setActiveTab(0);
  }, [open, event]);

  // Cleanup state when modal closes
  useEffect(() => {
    if (!open) {
      setPendingChanges(null);
      setShowConfirmation(false);
    }
  }, [open]);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleUpdateEvent = (updates) => {
    if (!open) return; // Prevent state updates if modal is closed
    setPendingChanges(updates);
    setShowConfirmation(true);
  };

  const handleConfirmUpdate = async () => {
    if (!open) return; // Prevent state updates if modal is closed

    try {
      await onEventUpdate(pendingChanges);
      setShowConfirmation(false);
      setPendingChanges(null);
      handleClose();
    } catch (error) {
      console.error("Failed to update event:", error);
      // Handle error if needed
    }
  };

  const handleCancelClose = () => {
    if (!open) return; // Prevent state updates if modal is closed
    setShowConfirmation(false);
    setPendingChanges(null);
  };

  return (
    <>
      <HsModal
        open={open}
        handleClose={handleClose}
        title={
          event?.source === "LOCAL"
            ? "Event Details"
            : `${formatProviderName(event?.source)} Calendar Event`
        }
      >
        <Box sx={{ width: "100%" }}>
          {showLessonTab ? (
            <>
              <Tabs
                value={activeTab}
                onChange={handleTabChange}
                variant="fullWidth"
                sx={{
                  borderBottom: 1,
                  borderColor: "divider",
                  mb: 2,
                }}
              >
                <Tab label="Details" />
                <Tab label="Lesson Information" />
              </Tabs>

              <Box sx={{ p: 2 }}>
                {activeTab === 0 && (
                  <EventDetailsTab
                    event={event}
                    onUpdate={handleUpdateEvent}
                    isEditable={event?.source === "LOCAL"}
                  />
                )}
                {activeTab === 1 && (
                  <LessonDetailsTab
                    event={event}
                    onStatusUpdate={handleUpdateEvent}
                  />
                )}
              </Box>
            </>
          ) : (
            <Box sx={{ p: 2 }}>
              <EventDetailsTab
                event={event}
                onUpdate={handleUpdateEvent}
                isEditable={event?.source === "LOCAL"}
              />
            </Box>
          )}
        </Box>
      </HsModal>

      <ConfirmationDialog
        open={showConfirmation}
        title="Confirm Update"
        message="Are you sure you want to update this event?"
        onConfirm={handleConfirmUpdate}
        onCancel={handleCancelClose}
      />
    </>
  );
};

export default EventDetailsViewModal;

import InfoIcon from "@mui/icons-material/Info";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import { Field, Form, Formik } from "formik";
import React, { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { checkClassInstanceExists } from "../../api/classInstanceApi";
import { applyLessonPlanToTerm } from "../../api/lessonPlanApi";
import HsModal from "../../components/common/HsModal";
import { useReportContext } from "../../contexts/report-context";
import { useAsyncOperation } from "../../hooks/useAsyncOperation";
import { enrollStudentValidationSchema } from "../../utils/validation";

class SelectErrorBoundary extends React.Component {
  state = { hasError: false, error: null };

  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  componentDidCatch(error, errorInfo) {
    console.error("Select Error:", error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return <div>Error in select: {this.state.error?.message}</div>;
    }
    return this.props.children;
  }
}

const PastLessonStatusSelector = ({ value, onChange, termStartDate }) => {
  const isPastTerm = dayjs(termStartDate).isBefore(dayjs(), "day");
  const isPartialTerm =
    dayjs(termStartDate).isBefore(dayjs(), "day") &&
    dayjs(termStartDate).isAfter(dayjs().subtract(1, "month"), "day");

  if (!isPastTerm) return null;

  return (
    <Box
      sx={{
        bgcolor: "background.gray",
        borderRadius: 2,
        p: 2,
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center", mb: 1.5 }}>
        <Typography variant="subtitle1" color="text.secondary" sx={{ mr: 1 }}>
          {isPartialTerm
            ? "You are enrolling in the middle of a term"
            : "You are enrolling in a past term"}
        </Typography>
        <Tooltip title="Choose how to handle lessons that occurred before today's date">
          <InfoIcon fontSize="small" color="action" />
        </Tooltip>
      </Box>

      <FormControl component="fieldset">
        <Typography variant="body2" color="text.secondary" sx={{ mb: 1 }}>
          Mark past lessons as:
        </Typography>
        <RadioGroup
          value={value || "COMPLETED"}
          onChange={(e) => onChange(e.target.value)}
        >
          <FormControlLabel
            value="COMPLETED"
            control={<Radio />}
            label={
              <Tooltip title="Mark all past lessons as finished">
                <Typography variant="body2">Completed (Default)</Typography>
              </Tooltip>
            }
          />
          <FormControlLabel
            value="PENDING"
            control={<Radio />}
            label={
              <Tooltip title="Keep past lessons in pending state">
                <Typography variant="body2">Pending</Typography>
              </Tooltip>
            }
          />
          <FormControlLabel
            value="EXCUSED"
            control={<Radio />}
            label={
              <Tooltip title="Mark past lessons as excused">
                <Typography variant="body2">Excused</Typography>
              </Tooltip>
            }
          />
          <FormControlLabel
            value="SKIPPED"
            control={<Radio />}
            label={
              <Tooltip title="Mark past lessons as skipped">
                <Typography variant="body2">Skipped</Typography>
              </Tooltip>
            }
          />
        </RadioGroup>
        <FormHelperText>
          This will affect all lessons scheduled before{" "}
          {dayjs().format("MMM D, YYYY")}
        </FormHelperText>
      </FormControl>
    </Box>
  );
};

const EnrollStudentModal = ({
  open,
  handleApply,
  handleClose,
  studentDetails,
  classes,
  terms,
  onSuccess,
}) => {
  const { setMessage } = useReportContext();
  const selectedAccountId = useSelector(
    (state) => state.auth.selectedAccountId
  );
  const [existingEnrollmentError, setExistingEnrollmentError] = useState(null);
  const { execute: executeApplyLessonPlan } = useAsyncOperation(
    applyLessonPlanToTerm
  );
  const { execute: executeCheckExists } = useAsyncOperation(
    checkClassInstanceExists
  );
  const [pastLessonStatus, setPastLessonStatus] = useState("COMPLETED");

  const groupedTerms = useMemo(() => {
    const now = new Date();
    const sortByStartDate = (a, b) =>
      new Date(b.startDate) - new Date(a.startDate);

    const currentTerms = terms
      .filter((term) => {
        const start = new Date(term.startDate);
        const end = new Date(term.endDate);
        return start <= now && end >= now;
      })
      .sort(sortByStartDate);

    const futureTerms = terms
      .filter((term) => {
        const start = new Date(term.startDate);
        return start > now;
      })
      .sort(sortByStartDate);

    const pastTerms = terms
      .filter((term) => {
        const end = new Date(term.endDate);
        return end < now;
      })
      .sort(sortByStartDate);

    return {
      current: currentTerms,
      future: futureTerms,
      past: pastTerms,
    };
  }, [terms]);

  const renderTermGroup = useMemo(() => {
    return (terms, label) => {
      if (!terms?.length) return [];

      return [
        <MenuItem key={`${label}-header`} disabled sx={{ opacity: 0.7, py: 1 }}>
          <Typography
            variant="body2"
            color="text.secondary"
            sx={{ fontWeight: 500 }}
          >
            {label}
          </Typography>
        </MenuItem>,
        ...terms.map((term) => (
          <MenuItem key={term.id} value={term.id}>
            {term.name}
          </MenuItem>
        )),
      ];
    };
  }, []); // Empty dependency array since this function doesn't depend on any props/state

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      const exists = await executeCheckExists(
        studentDetails.id,
        selectedAccountId,
        values.termId,
        values.lessonPlanId
      );

      if (exists) {
        setExistingEnrollmentError(
          "Student is already enrolled in this class for the selected term"
        );
        setSubmitting(false);
        return;
      }

      await executeApplyLessonPlan(values.lessonPlanId, {
        termId: values.termId,
        studentId: studentDetails.id,
        markPriorLessons:
          pastLessonStatus !== "PENDING" ? pastLessonStatus : undefined,
      });

      await onSuccess();
      handleApply();
    } catch (err) {
      setMessage({
        text: err.message || "Failed to enroll student",
        severity: "error",
        flag: true,
      });
    }
    setSubmitting(false);
  };

  return (
    <HsModal
      open={open}
      handleClose={handleClose}
      title="Enroll Student in Class"
      maxWidth="sm"
    >
      <Formik
        initialValues={{
          termId: "",
          lessonPlanId: "",
        }}
        validationSchema={enrollStudentValidationSchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting, values, touched, errors, setFieldValue }) => (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Field name="termId">
                  {({ field, meta }) => (
                    <TextField
                      {...field}
                      select
                      label="Term"
                      fullWidth
                      size="small"
                      error={Boolean(
                        meta.touched && (meta.error || existingEnrollmentError)
                      )}
                      helperText={
                        meta.touched && (meta.error || existingEnrollmentError)
                      }
                      onChange={(e) => {
                        setFieldValue("termId", e.target.value);
                        setExistingEnrollmentError(null);
                      }}
                      SelectProps={{
                        MenuProps: {
                          PaperProps: {
                            sx: {
                              maxHeight: 400,
                            },
                          },
                        },
                      }}
                    >
                      {[
                        ...renderTermGroup(
                          groupedTerms.current,
                          "Current Terms"
                        ),
                        ...renderTermGroup(groupedTerms.future, "Future Terms"),
                        ...renderTermGroup(groupedTerms.past, "Past Terms"),
                      ]}
                    </TextField>
                  )}
                </Field>
              </Grid>

              <Grid
                item
                xs={12}
                sx={{ height: values.termId ? "auto" : 0, overflow: "hidden" }}
              >
                <PastLessonStatusSelector
                  value={pastLessonStatus}
                  onChange={setPastLessonStatus}
                  termStartDate={
                    terms.find((t) => t.id === values.termId)?.startDate
                  }
                />
              </Grid>

              <Grid item xs={12}>
                <Field name="lessonPlanId">
                  {({ field, meta }) => (
                    <TextField
                      {...field}
                      select
                      label="Class"
                      fullWidth
                      size="small"
                      error={Boolean(meta.touched && meta.error)}
                      helperText={meta.touched && meta.error}
                      onChange={(e) => {
                        setFieldValue("lessonPlanId", e.target.value);
                        setExistingEnrollmentError(null);
                      }}
                      SelectProps={{
                        MenuProps: {
                          PaperProps: {
                            sx: {
                              maxHeight: 400,
                            },
                          },
                        },
                      }}
                    >
                      {classes.map((option) => (
                        <MenuItem key={option.id} value={option.id}>
                          {`${option.name} (${option.durationPerLesson} : ${option.lessonFrequency})`}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                </Field>
              </Grid>

              <Grid item xs={12}>
                <Stack direction="row" justifyContent="flex-end" spacing={2}>
                  <Button onClick={handleClose}>Cancel</Button>
                  <LoadingButton
                    type="submit"
                    variant="contained"
                    disabled={
                      isSubmitting ||
                      !values.termId ||
                      !values.lessonPlanId ||
                      Object.keys(errors).length > 0 ||
                      existingEnrollmentError
                    }
                    loading={isSubmitting}
                  >
                    Schedule
                  </LoadingButton>
                </Stack>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </HsModal>
  );
};

export default EnrollStudentModal;

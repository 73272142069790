import SchoolIcon from "@mui/icons-material/School";
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Slider,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import AvatarEditor from "react-avatar-editor";
import { useSelector } from "react-redux";
import { uploadProfileImage } from "../../api/userApi";
import { useAsyncOperation } from "../../hooks/useAsyncOperation";
import { debug } from "../../utils/debug";
import HsModal from "./HsModal";

const AvatarUpload = ({
  currentImageUrl,
  onImageUpload,
  name,
  editable = true,
  size = 150,
  userId,
  isEditorOpen,
  onEditorClose,
}) => {
  const [avatarFile, setAvatarFile] = useState(null);
  const [avatarScale, setAvatarScale] = useState(1);
  const selectedAccountId = useSelector(
    (state) => state.auth.selectedAccountId
  );

  const editorRef = useRef(null);
  const fileInputRef = useRef(null);
  const mountedRef = useRef(true);

  const { execute: executeUploadImage, loading: uploadingImage } =
    useAsyncOperation(uploadProfileImage);

  useEffect(() => {
    return () => {
      mountedRef.current = false;
    };
  }, []);

  useEffect(() => {
    if (!isEditorOpen) {
      setAvatarFile(null);
      setAvatarScale(1);
    }
  }, [isEditorOpen]);

  const handleAvatarChange = (event) => {
    if (event.target.files?.[0]) {
      setAvatarFile(event.target.files[0]);
      onEditorClose(true);
    }
  };

  const handleSaveAvatar = async () => {
    if (!editorRef.current) return;

    try {
      debug.avatar.log("AvatarUpload: Starting save avatar...");
      const canvas = editorRef.current.getImageScaledToCanvas();
      const blob = await new Promise((resolve) => {
        canvas.toBlob(resolve, "image/png");
      });

      const formData = new FormData();
      formData.append("file", blob);

      debug.avatar.log("AvatarUpload: Uploading image...");
      const result = await executeUploadImage(
        userId,
        formData,
        selectedAccountId
      );

      debug.avatar.log("AvatarUpload: Upload response:", result);

      const imageUrl = result?.profileImageUrl;
      if (!imageUrl) {
        debug.avatar.error("AvatarUpload: No profileImageUrl in response");
        return;
      }

      debug.avatar.log(
        "AvatarUpload: Calling onImageUpload with profileImageUrl:",
        imageUrl
      );
      const success = await onImageUpload(imageUrl);
      debug.avatar.log("AvatarUpload: onImageUpload success:", success);

      if (mountedRef.current && success) {
        debug.avatar.log("AvatarUpload: Closing modal...");
        setAvatarFile(null);
        onEditorClose(false);
        debug.avatar.log("AvatarUpload: Modal close called");
      }
    } catch (error) {
      debug.avatar.error("Error uploading avatar:", error);
    }
  };

  const handleClose = () => {
    setAvatarFile(null);
    onEditorClose(false);
  };

  debug.avatar.log("AvatarUpload render - isEditorOpen:", isEditorOpen);

  return (
    <>
      <Box>
        <Avatar
          src={currentImageUrl}
          alt={name}
          sx={{
            width: size,
            height: size,
            bgcolor: "primary.light",
          }}
        >
          {!currentImageUrl && <SchoolIcon sx={{ fontSize: size * 0.5 }} />}
        </Avatar>
        <input
          id="avatar-upload"
          type="file"
          hidden
          accept="image/*"
          onChange={handleAvatarChange}
          ref={fileInputRef}
        />
      </Box>

      <HsModal
        open={isEditorOpen}
        handleClose={handleClose}
        title="Edit Avatar"
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            p: 2,
          }}
        >
          {avatarFile && (
            <AvatarEditor
              ref={editorRef}
              image={avatarFile}
              width={250}
              height={250}
              border={50}
              borderRadius={125}
              color={[255, 255, 255, 0.6]}
              scale={avatarScale}
              rotate={0}
            />
          )}
          <Typography gutterBottom sx={{ mt: 2 }}>
            Zoom
          </Typography>
          <Slider
            value={avatarScale}
            min={1}
            max={2}
            step={0.01}
            onChange={(e, newValue) => setAvatarScale(newValue)}
            sx={{ width: "80%", mb: 2 }}
          />
          <Button
            onClick={handleSaveAvatar}
            variant="contained"
            disabled={uploadingImage}
          >
            {uploadingImage ? <CircularProgress size={24} /> : "Save"}
          </Button>
        </Box>
      </HsModal>
    </>
  );
};

export default AvatarUpload;

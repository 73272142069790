import React from "react";
import Lightbox from "yet-another-react-lightbox";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/styles.css";
import { debug } from "../../utils/debug";

const LightboxPreview = ({ open, onClose, urls, activeStep, onStepChange }) => {
  debug.wizard.state("LightboxPreview render:", {
    open,
    urlCount: urls?.length,
    activeStep,
  });

  return (
    <Lightbox
      open={open}
      close={onClose}
      index={activeStep}
      slides={urls.map((url) => ({ src: url }))}
      on={{
        view: ({ index }) => onStepChange?.(index),
      }}
      plugins={[Zoom, Thumbnails]}
      animation={{ fade: 300 }}
      carousel={{ finite: true }}
      render={{
        buttonPrev: activeStep === 0 ? () => null : undefined,
        buttonNext: activeStep === urls.length - 1 ? () => null : undefined,
      }}
    />
  );
};

export default LightboxPreview;

import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import HomeIcon from "@mui/icons-material/Home";
import { Box, IconButton, Stack, Typography } from "@mui/material";
import dayjs from "dayjs";
import React, { useCallback } from "react";

const WeekNavigation = ({
  currentWeekStart,
  setCurrentWeekStart,
  isMobile,
}) => {
  const handlePrevWeek = useCallback(() => {
    setCurrentWeekStart((prev) => prev.subtract(1, "week"));
  }, [setCurrentWeekStart]);

  const handleNextWeek = useCallback(() => {
    setCurrentWeekStart((prev) => prev.add(1, "week"));
  }, [setCurrentWeekStart]);

  const handleCurrentWeek = useCallback(() => {
    setCurrentWeekStart(dayjs().startOf("week"));
  }, [setCurrentWeekStart]);

  return (
    <Stack
      direction="column"
      spacing={2}
      justifyContent={isMobile ? "flex-start" : "flex-end"}
      alignItems={isMobile ? "flex-start" : "flex-end"}
    >
      <Box
        display="flex"
        alignItems="center"
        justifyContent={isMobile ? "space-between" : "flex-end"}
        width="100%"
      >
        <Box display="flex" alignItems="center">
          <IconButton onClick={handlePrevWeek}>
            <ArrowBackIosNewIcon />
          </IconButton>
          <Typography variant="h6" sx={{ mx: 1 }}>
            Week of {currentWeekStart.format("MMMM D, YYYY")}
          </Typography>
          <IconButton onClick={handleNextWeek}>
            <ArrowForwardIosIcon />
          </IconButton>
        </Box>
        <IconButton onClick={handleCurrentWeek} color="primary" sx={{ ml: 1 }}>
          <HomeIcon />
        </IconButton>
      </Box>
    </Stack>
  );
};

export default WeekNavigation;

import InfoIcon from "@mui/icons-material/Info";
import {
  Alert,
  Box,
  Button,
  Chip,
  FormControlLabel,
  IconButton,
  Stack,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import dayjs from "dayjs";
import React, { useState } from "react";

const StyledChip = styled(Chip)(({ theme }) => ({
  borderRadius: "16px",
  fontWeight: 500,
  "&.type-SYSTEM": {
    backgroundColor: theme.palette.warning.light,
    color: theme.palette.warning.dark,
  },
  "&.type-USER": {
    backgroundColor: theme.palette.primary.lighter,
    color: theme.palette.primary.dark,
  },
  "&.type-USER_BLOCK": {
    backgroundColor: theme.palette.error.light,
    color: theme.palette.error.dark,
  },
  "&.source-GOOGLE": {
    backgroundColor: theme.palette.info.light,
    color: theme.palette.info.dark,
  },
  "&.source-MICROSOFT": {
    backgroundColor: theme.palette.secondary.light,
    color: theme.palette.secondary.dark,
  },
}));

const LabelValue = ({ label, value, children }) => (
  <Stack direction="row" spacing={2} alignItems="center" sx={{ mb: 2 }}>
    <Typography variant="bodyFL" sx={{ minWidth: 120 }}>
      {label}:
    </Typography>
    {children || <Typography variant="bodyFV">{value}</Typography>}
  </Stack>
);

const EventDetailsTab = ({ event, onUpdate, isEditable }) => {
  const [editMode, setEditMode] = useState(false);
  const [formData, setFormData] = useState({
    title: event?.title || "",
    description: event?.description || "",
    startTime: event?.userStartTime ? dayjs(event.userStartTime) : null,
    endTime: event?.userEndTime ? dayjs(event.userEndTime) : null,
    type: event?.type || "USER",
    rebalance: false,
  });

  const showRebalanceOption = event?.termId && formData.type === "USER_BLOCK";

  const handleEdit = () => {
    setEditMode(true);
    setFormData({
      title: event?.title || "",
      description: event?.description || "",
      startTime: event?.userStartTime ? dayjs(event.userStartTime) : null,
      endTime: event?.userEndTime ? dayjs(event.userEndTime) : null,
      type: event?.type || "USER",
      rebalance: false,
    });
  };

  const handleSave = () => {
    const updatedEvent = {
      ...event,
      title: formData.title,
      description: formData.description,
      userStartTime: formData.startTime.format("YYYY-MM-DDTHH:mm:ss"),
      userEndTime: formData.endTime.format("YYYY-MM-DDTHH:mm:ss"),
      type: formData.type,
      rebalance: formData.rebalance,
    };

    if (event.termId) {
      updatedEvent.termName = event.termName;
    }

    onUpdate(updatedEvent);
    setEditMode(false);
  };

  const handleCancel = () => {
    setEditMode(false);
  };

  const handleBlockingToggle = () => {
    setFormData((prev) => ({
      ...prev,
      type: prev.type === "USER" ? "USER_BLOCK" : "USER",
    }));
  };

  const renderViewMode = () => (
    <Box>
      <Stack direction="row" spacing={1} sx={{ mb: 3 }}>
        <StyledChip
          label={event?.type?.replace("_", " ")}
          className={`type-${event?.type}`}
        />
        {event?.source !== "LOCAL" && (
          <StyledChip
            label={event?.source}
            className={`source-${event?.source}`}
          />
        )}
      </Stack>

      <LabelValue label="Title" value={event?.title} />
      <LabelValue
        label="Description"
        value={event?.description || "No description"}
      />
      <LabelValue
        label="Start Time"
        value={dayjs(event?.userStartTime).format("MMM D, YYYY h:mm A")}
      />
      <LabelValue
        label="End Time"
        value={dayjs(event?.userEndTime).format("MMM D, YYYY h:mm A")}
      />

      {event?.termId && event?.termName && (
        <LabelValue label="Term" value={event?.termName} />
      )}

      {event?.student && (
        <LabelValue
          label="Student"
          value={`${event.student.firstName} ${event.student.lastName}`}
        />
      )}

      {event?.educator && (
        <LabelValue
          label="Educator"
          value={`${event.educator.firstName} ${event.educator.lastName}`}
        />
      )}

      {isEditable && (
        <Box sx={{ mt: 3 }}>
          <Button variant="contained" onClick={handleEdit}>
            Edit Event
          </Button>
        </Box>
      )}
    </Box>
  );

  const renderEditMode = () => (
    <Box>
      <Stack spacing={3}>
        <TextField
          label="Title"
          fullWidth
          value={formData.title}
          onChange={(e) =>
            setFormData((prev) => ({ ...prev, title: e.target.value }))
          }
        />

        <TextField
          label="Description"
          fullWidth
          multiline
          rows={3}
          value={formData.description}
          onChange={(e) =>
            setFormData((prev) => ({ ...prev, description: e.target.value }))
          }
        />

        <DateTimePicker
          label="Start Time"
          value={formData.startTime}
          onChange={(newValue) =>
            setFormData((prev) => ({ ...prev, startTime: newValue }))
          }
          slotProps={{ textField: { fullWidth: true } }}
        />

        <DateTimePicker
          label="End Time"
          value={formData.endTime}
          onChange={(newValue) =>
            setFormData((prev) => ({ ...prev, endTime: newValue }))
          }
          slotProps={{ textField: { fullWidth: true } }}
        />

        <FormControlLabel
          control={
            <Switch
              checked={formData.type === "USER_BLOCK"}
              onChange={handleBlockingToggle}
            />
          }
          label="Blocking Event"
        />

        {showRebalanceOption && (
          <Box sx={{ mt: 2 }}>
            <Alert severity="info" icon={false} sx={{ mb: 2 }}>
              <Stack direction="row" spacing={1} alignItems="flex-start">
                <Typography>
                  This is a blocking event in a school term. You can choose to
                  automatically adjust other events to prevent overlaps.
                </Typography>
                <Tooltip title="When enabled, any conflicting events will be automatically rescheduled while maintaining all scheduling requirements">
                  <IconButton size="small">
                    <InfoIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              </Stack>
            </Alert>
            <FormControlLabel
              control={
                <Switch
                  checked={formData.rebalance}
                  onChange={(e) =>
                    setFormData((prev) => ({
                      ...prev,
                      rebalance: e.target.checked,
                    }))
                  }
                  color="primary"
                />
              }
              label="Auto-adjust conflicting events"
            />
          </Box>
        )}

        <Stack direction="row" spacing={2}>
          <Button variant="outlined" onClick={handleCancel}>
            Cancel
          </Button>
          <Button variant="contained" onClick={handleSave}>
            Save Changes
          </Button>
        </Stack>
      </Stack>
    </Box>
  );

  return editMode ? renderEditMode() : renderViewMode();
};

export default EventDetailsTab;

// hooks/useTerms.js
import { useCallback, useState } from "react";
import { useSelector } from "react-redux";
import {
  deleteTerm as apiDeleteTerm,
  fetchActiveTerms as apiFetchActiveTerms,
  fetchFutureTerms as apiFetchFutureTerms,
  fetchPastTerms as apiFetchPastTerms,
} from "../api/termApi";
import { debug } from "../utils/debug";
import { useAsyncOperation } from "./useAsyncOperation";

export const useTerms = () => {
  const { selectedAccountId } = useSelector((state) => state.auth);
  const [activeTerms, setActiveTerms] = useState([]);
  const [futureTerms, setFutureTerms] = useState([]);
  const [pastTerms, setPastTerms] = useState([]);

  const { loading: activeTermsLoading, execute: fetchActiveTerms } =
    useAsyncOperation(async (signal) => {
      debug.api.log("Fetching active terms");
      const result = await apiFetchActiveTerms(0, 100, signal);
      debug.api.log("Active terms result:", result);
      setActiveTerms(result.data);
      return result;
    });

  const { loading: futureTermsLoading, execute: fetchFutureTerms } =
    useAsyncOperation(async (signal) => {
      debug.api.log("Fetching future terms");
      const result = await apiFetchFutureTerms(0, 100, signal);
      debug.api.log("Future terms result:", result);
      setFutureTerms(result.data);
      return result;
    });

  const { loading: pastTermsLoading, execute: fetchPastTerms } =
    useAsyncOperation(async (signal) => {
      debug.api.log("Fetching past terms");
      const result = await apiFetchPastTerms(0, 100, signal);
      debug.api.log("Past terms result:", result);
      setPastTerms(result.data);
      return result;
    });

  const refreshTerms = useCallback(async () => {
    debug.api.log("Refreshing all terms");
    try {
      await Promise.all([
        fetchActiveTerms(),
        fetchFutureTerms(),
        fetchPastTerms(),
      ]);
      debug.api.log("All terms refreshed successfully");
    } catch (error) {
      debug.api.error("Error refreshing terms:", error);
      throw error;
    }
  }, [fetchActiveTerms, fetchFutureTerms, fetchPastTerms]);

  const deleteTerm = useCallback(async (termId) => {
    debug.api.log("Deleting term:", termId);
    try {
      await apiDeleteTerm(termId);
      debug.api.log("Term deleted successfully:", termId);
    } catch (error) {
      debug.api.error("Error deleting term:", error);
      throw error;
    }
  }, []);

  return {
    activeTerms,
    futureTerms,
    pastTerms,
    activeTermsLoading,
    futureTermsLoading,
    pastTermsLoading,
    refreshTerms,
    deleteTerm,
  };
};

import AssessmentIcon from "@mui/icons-material/Assessment";
import CalendarIcon from "@mui/icons-material/CalendarToday";
import FaceIcon from "@mui/icons-material/Face";
import HomeIcon from "@mui/icons-material/Home";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import Timelapse from "@mui/icons-material/Timelapse";
import {
  Box,
  Drawer,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDrawer } from "../hooks/useDrawer";

const drawerItems = [
  { text: "Checklist", icon: <HomeIcon />, path: "/home" },
  { text: "Calendar", icon: <CalendarIcon />, path: "/calendar-events" },
  { text: "Students", icon: <FaceIcon />, path: "/users" },
  { text: "School Terms", icon: <Timelapse />, path: "/terms" },
  { text: "Lesson Plans", icon: <MenuBookIcon />, path: "/lesson-plans" },
  { text: "Reports", icon: <AssessmentIcon />, path: "/reports" },
];

const DrawerComponent = () => {
  const { isOpen, toggleDrawer, drawerWidth } = useDrawer();
  const location = useLocation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const navigate = useNavigate();

  const handleListItemClick = (path) => {
    navigate(path);
    if (isMobile) {
      toggleDrawer();
    }
  };

  return (
    <Drawer
      variant={isMobile ? "temporary" : "permanent"}
      open={isMobile ? isOpen : true}
      onClose={toggleDrawer}
      ModalProps={{ keepMounted: false }}
      sx={(theme) => ({
        width: isMobile ? 0 : drawerWidth + 8,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: drawerWidth,
          boxSizing: "border-box",
          bgcolor: theme.palette.secondary.light,
          borderRight: "none",
          borderRadius: isMobile ? 0 : `${theme.shape.borderRadius}px`,
          margin: isMobile ? 0 : theme.spacing(1),
          height: isMobile ? "100%" : `calc(100% - ${theme.spacing(4)})`,
          overflowX: "hidden",
        },
      })}
    >
      <Box sx={{ p: 4 }}>
        {/* <img
          src="/assets/images/logo_dark.png"
          alt="Logo"
          style={{ marginBottom: theme.spacing(3), width: 32, height: 32 }}
        /> */}
      </Box>
      <List sx={{ mt: 2 }}>
        {drawerItems.map((item) => (
          <ListItemButton
            key={item.text}
            onClick={() => handleListItemClick(item.path)}
            selected={location.pathname === item.path}
            sx={(theme) => ({
              borderRadius: 100,
              mr: 1.5,
              ml: 1.5,
              color: theme.palette.drawer.text,
              "&.Mui-selected": {
                backgroundColor: "white",
                "& .MuiListItemIcon-root": {
                  color: theme.palette.primary.main,
                },
              },
              "& .MuiListItemIcon-root": {
                color: theme.palette.primary.main,
              },
            })}
          >
            <ListItemIcon sx={{ minWidth: 40 }}>{item.icon}</ListItemIcon>
            <ListItemText
              primary={item.text}
              primaryTypographyProps={{
                sx: {
                  fontWeight: 500,
                },
              }}
            />
          </ListItemButton>
        ))}
      </List>
    </Drawer>
  );
};

export default DrawerComponent;

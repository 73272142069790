import { PRODUCT_IDS } from "../config/productConstants";
import api from "./api";

export const printedPlannerApi = {
  getTermsAndStudents: async (signal) => {
    const response = await api.get(
      "/api/v1/printed-planner/terms-and-students",
      { signal }
    );
    return response.data;
  },

  getPlannerPreviews: async (termId, studentId, signal) => {
    const response = await api.get(
      `/api/v1/printed-planner/previews/${termId}/${studentId}`,
      { signal }
    );
    return response.data;
  },

  getThemes: async (signal) => {
    const response = await api.get("/api/v1/printed-planner/themes", {
      signal,
    });
    return response.data;
  },

  getShippingOptions: async (request, signal) => {
    const response = await api.post(
      "/api/v1/print-jobs/shipping-options",
      request,
      { signal }
    );
    return response.data;
  },

  addToCart: async (orderDetails, signal) => {
    try {
      const response = await api.post(
        "/api/v1/cart/add",
        {
          productId: PRODUCT_IDS.PLANNER,
          quantity: 1,
          numberOfPages: orderDetails.numberOfPages,
          reportId: orderDetails.reportId,
          plannerOptions: {
            coverFinish: orderDetails.plannerOptions.coverFinish,
            paperType: orderDetails.plannerOptions.paperType,
            colorType: orderDetails.plannerOptions.colorType,
            paperQuality: orderDetails.plannerOptions.paperQuality,
            size: orderDetails.plannerOptions.size,
            bindingType: orderDetails.plannerOptions.bindingType,
          },
        },
        {
          signal,
        }
      );

      if (response.status !== 200) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      return response.data;
    } catch (error) {
      console.error("Error in addToCart:", error);
      throw error;
    }
  },

  generatePlannerPreview: async (orderDetails, signal) => {
    const params = new URLSearchParams({
      term_id: orderDetails.termId,
      user_id: orderDetails.userId,
      student_id: orderDetails.studentId,
      account_id: orderDetails.accountId,
      template: mapThemeIdToReportTemplate(orderDetails.themeId),
    });

    const response = await api.post(
      `/api/v1/planner?${params.toString()}`,
      {}, // empty body
      { signal }
    );
    return response.data;
  },

  getPlannerPreviewStatus: async (reportId, accountId, signal) => {
    const params = new URLSearchParams({
      account_id: accountId,
    });

    const response = await api.get(
      `/api/v1/report/status/${reportId}?${params.toString()}`,
      {
        signal,
      }
    );
    return response.data;
  },

  calculatePrintJobCost: async (request, signal) => {
    const response = await api.post(
      "/api/v1/print-jobs/calculate-cost",
      request,
      {
        signal,
      }
    );
    return response.data;
  },

  // Remove the getAsset function as it's no longer needed
};

// Helper function to map theme IDs to report templates
function mapThemeIdToReportTemplate(themeId) {
  const templateMap = {
    1: "V1",
    2: "V2",
    3: "V3",
    4: "V4",
  };
  return templateMap[themeId] || "V2"; // Default to V2 if no match
}

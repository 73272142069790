import apiUrl from "../config/config.js";
import { api } from "./api.js";

export const getStudentClassesCounts = async (studentId, accountId, signal) => {
  const params = {
    page: 0,
    size: 1, // We only need counts
    account_id: accountId,
  };

  const [current, future, past] = await Promise.all([
    api.get(`${apiUrl}/api/v1/class-instance/student/${studentId}/search`, {
      params: { ...params, termTiming: "CURRENT" },
      signal,
    }),
    api.get(`${apiUrl}/api/v1/class-instance/student/${studentId}/search`, {
      params: { ...params, termTiming: "FUTURE" },
      signal,
    }),
    api.get(`${apiUrl}/api/v1/class-instance/student/${studentId}/search`, {
      params: { ...params, termTiming: "PAST" },
      signal,
    }),
  ]);

  return {
    current: current.data.totalElements,
    future: future.data.totalElements,
    past: past.data.totalElements,
  };
};

export const getStudentClasses = async (
  studentId,
  accountId,
  termTiming,
  page = 0,
  size = 10,
  signal
) => {
  const params = {
    page,
    size,
    termTiming,
    sortBy: "termSummary.startDate",
    sortDirection: "DESC",
    account_id: accountId,
  };

  const response = await api.get(
    `${apiUrl}/api/v1/class-instance/student/${studentId}/search`,
    { params, signal }
  );

  return response.data;
};

export const checkClassInstanceExists = async (
  studentId,
  accountId,
  termId,
  lessonPlanId,
  signal
) => {
  const params = {
    account_id: accountId,
    termId,
    lessonPlanId,
  };

  const response = await api.get(
    `${apiUrl}/api/v1/class-instance/student/${studentId}`,
    { params, signal }
  );

  return response.data.totalElements > 0;
};

import styled from "@emotion/styled";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Stack,
  Tab,
  TableCell,
  Tabs,
  Typography,
} from "@mui/material";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  getStudentClasses,
  getStudentClassesCounts,
} from "../../api/classInstanceApi";
import { getLessonPlans } from "../../api/lessonPlanApi";
import { fetchActiveTerms, fetchFutureTerms } from "../../api/termApi";
import { deleteProfileImage, deleteUser, getUserById } from "../../api/userApi";
import AvatarUpload from "../../components/common/AvatarUpload";
import HsModal from "../../components/common/HsModal";
import HsTable from "../../components/common/HsTable";
import DeleteConfirmationModal from "../../components/modals/DeleteConfirmationModal";
import { useReportContext } from "../../contexts/report-context";
import { useAsyncOperation } from "../../hooks/useAsyncOperation";
import { debug } from "../../utils/debug";
import DeleteClassEnrollmentModal from "./DeleteClassEnrollmentModal";
import EditUserModal from "./EditUserModal";
import EnrollStudentModal from "./EnrollStudentModal";

const StyledButtonGroup = styled(Stack)(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    "& .MuiButton-root": {
      flex: 1,
    },
  },
}));

const MoreActionsButton = styled(Button)(({ theme }) => ({
  color: theme.palette.text.secondary,
  borderColor: theme.palette.divider,
  marginLeft: "auto",
  [theme.breakpoints.down("sm")]: {
    marginLeft: 0,
    marginTop: theme.spacing(1.5),
  },
}));

const StyledTabs = styled(Tabs)(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    "& .MuiTab-root": {
      minWidth: "auto",
      flex: 1,
      padding: "12px 8px",
      fontSize: "0.8125rem",
    },
  },
}));

const UserDetailsPage = () => {
  const { id } = useParams();
  const selectedAccountId = useSelector(
    (state) => state.auth.selectedAccountId
  );
  const { setMessage } = useReportContext();
  const [activeTerms, setActiveTerms] = useState([]);
  const [futureTerms, setFutureTerms] = useState([]);
  const [pastTerms, setPastTerms] = useState([]);
  const [openEnrollStudentModal, setOpenEnrollStudentModal] = useState(false);
  const [lessonPlans, setLessonPlans] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const navigate = useNavigate();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const { execute: executeDeleteUser, loading: isDeleting } =
    useAsyncOperation(deleteUser);
  const [isAvatarEditorOpen, setIsAvatarEditorOpen] = useState(false);
  const [currentAvatarUrl, setCurrentAvatarUrl] = useState(null);
  const { execute: executeDeleteAvatar } =
    useAsyncOperation(deleteProfileImage);
  const [showAvatarModal, setShowAvatarModal] = useState(false);
  const [isLoadingTerms, setIsLoadingTerms] = useState(false);
  const [tabCounts, setTabCounts] = useState({
    current: 0,
    future: 0,
    past: 0,
  });
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedInstance, setSelectedInstance] = useState(null);
  const [showDeleteEnrollmentModal, setShowDeleteEnrollmentModal] =
    useState(false);

  const {
    data: classData,
    loading: classesLoading,
    error: classesError,
    execute: executeClassesFetch,
  } = useAsyncOperation(getStudentClasses, {
    onError: (error) => {
      if (error?.message === "canceled" || error?.name === "CanceledError")
        return;
      if (error?.code === "ERR_NETWORK") return;

      setMessage({
        text: "Unable to load classes. Please try again later.",
        severity: "error",
        flag: true,
      });
    },
  });

  const { execute: executeCountsFetch } = useAsyncOperation(
    getStudentClassesCounts,
    {
      onError: (error) => {
        if (error?.message === "canceled" || error?.name === "CanceledError")
          return;
        if (error?.code === "ERR_NETWORK") return;

        setMessage({
          text: "Failed to fetch class counts",
          severity: "error",
          flag: true,
        });
      },
    }
  );

  const fetchUserDetails = useCallback(
    async (signal) => {
      const response = await getUserById(id, selectedAccountId, signal);
      return response;
    },
    [id, selectedAccountId]
  );

  const {
    data: userDetails,
    loading: userLoading,
    error: userError,
    execute: executeUserFetch,
  } = useAsyncOperation(fetchUserDetails);

  useEffect(() => {
    if (selectedAccountId) {
      executeUserFetch();
    }
  }, [selectedAccountId, executeUserFetch]);

  useEffect(() => {
    if (userError) {
      setMessage({
        text: userError.message || "Failed to fetch user details",
        severity: "error",
        flag: true,
      });
    }
  }, [userError, setMessage]);

  useEffect(() => {
    if (userDetails?.profileImageUrl) {
      setCurrentAvatarUrl(userDetails.profileImageUrl);
    }
  }, [userDetails?.profileImageUrl]);

  useEffect(() => {
    let mounted = true;
    const abortController = new AbortController();

    const fetchCounts = async () => {
      if (id && selectedAccountId) {
        try {
          const counts = await executeCountsFetch(id, selectedAccountId);
          if (mounted) {
            setTabCounts(counts);
          }
        } catch (err) {
          if (mounted && err?.name !== "CanceledError") {
            setMessage({
              text: err.message || "Failed to fetch class counts",
              severity: "error",
              flag: true,
            });
          }
        }
      }
    };

    fetchCounts();

    return () => {
      mounted = false;
      abortController.abort();
    };
  }, [id, selectedAccountId, executeCountsFetch, setMessage, classData]);

  useEffect(() => {
    let mounted = true;
    const abortController = new AbortController();

    const fetchData = async () => {
      if (id && selectedAccountId && mounted) {
        try {
          await executeClassesFetch(
            id,
            selectedAccountId,
            activeTab === 0 ? "CURRENT" : activeTab === 1 ? "FUTURE" : "PAST",
            page,
            rowsPerPage,
            abortController.signal
          );
        } catch (err) {
          if (mounted && err?.name !== "CanceledError") {
            setMessage({
              text: "Unable to load classes. Please try again later.",
              severity: "error",
              flag: true,
            });
          }
        }
      }
    };

    fetchData();

    return () => {
      mounted = false;
      abortController.abort();
    };
  }, [
    id,
    selectedAccountId,
    activeTab,
    page,
    rowsPerPage,
    executeClassesFetch,
    setMessage,
  ]);

  useEffect(() => {
    if (classesError) {
      setMessage({
        text: classesError.message || "Failed to fetch student details",
        severity: "error",
        flag: true,
      });
    }
  }, [classesError, setMessage]);

  const fetchLessonPlans = useCallback(async () => {
    try {
      const data = await getLessonPlans(selectedAccountId, 0, 100);
      setLessonPlans(data.content);
    } catch (err) {
      setMessage({
        text: "Failed to fetch lesson plans",
        severity: "error",
        flag: true,
      });
    }
  }, [selectedAccountId, setMessage]);

  useEffect(() => {
    fetchLessonPlans();
  }, [fetchLessonPlans]);

  const fetchTermsForEnrollment = useCallback(
    async (signal) => {
      setIsLoadingTerms(true);
      try {
        const [activeResponse, futureResponse] = await Promise.all([
          fetchActiveTerms(0, 100, signal),
          fetchFutureTerms(0, 100, signal),
        ]);

        setActiveTerms(activeResponse.data || []);
        setFutureTerms(futureResponse.data || []);
      } catch (err) {
        setMessage({
          text: err.message || "Failed to fetch terms",
          severity: "error",
          flag: true,
        });
      } finally {
        setIsLoadingTerms(false);
      }
    },
    [setMessage]
  );

  const handleTabChange = useCallback((event, newValue) => {
    setActiveTab(newValue);
    setPage(0);
  }, []);

  useEffect(() => {
    if (openEnrollStudentModal) {
      fetchTermsForEnrollment();
    }
  }, [openEnrollStudentModal, fetchTermsForEnrollment]);

  const handleOpenEnrollStudentModal = () => {
    setOpenEnrollStudentModal(true);
  };

  const handleCloseEnrollStudentModal = () => {
    setOpenEnrollStudentModal(false);
  };

  const handleEnrollStudent = () => {
    fetchLessonPlans();
    setOpenEnrollStudentModal(false);
  };

  const isActiveTermClass = useCallback(
    (termId) => {
      return activeTerms.some((term) => term.id === termId);
    },
    [activeTerms]
  );

  const enrollmentColumns = useMemo(
    () => [
      {
        id: "class",
        label: "Class",
        width: "25%",
      },
      {
        id: "subject",
        label: "Subject",
        width: "25%",
      },
      {
        id: "duration",
        label: "Duration",
        width: "25%",
      },
      {
        id: "termName",
        label: "Term",
        width: "25%",
      },
    ],
    []
  );

  const handleMenuClick = (event, instance) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setSelectedInstance(instance);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleDeleteClick = () => {
    handleMenuClose();
    setTimeout(() => {
      setShowDeleteEnrollmentModal(true);
    }, 100);
  };

  const handleCloseDeleteModal = () => {
    if (!classesLoading) {
      setShowDeleteEnrollmentModal(false);
      setSelectedInstance(null);
    }
  };

  const renderDesktopRow = useCallback(
    (row) => (
      <>
        {enrollmentColumns.map((column) => (
          <TableCell key={column.id} sx={{ cursor: "default" }}>
            {column.id === "termName" ? (
              <Stack direction="row" alignItems="center" spacing={1}>
                <Typography
                  sx={{
                    color: isActiveTermClass(row.termId)
                      ? "primary.main"
                      : "text.secondary",
                    fontWeight: isActiveTermClass(row.termId) ? 500 : 400,
                  }}
                >
                  {row[column.id]}
                </Typography>
                <IconButton
                  size="small"
                  onClick={(e) => handleMenuClick(e, row)}
                  sx={{ ml: "auto" }}
                >
                  <MoreVertIcon fontSize="small" />
                </IconButton>
              </Stack>
            ) : (
              row[column.id]
            )}
          </TableCell>
        ))}
      </>
    ),
    [isActiveTermClass, handleMenuClick]
  );

  const renderMobileRow = useCallback(
    (row) => (
      <Box
        sx={{
          p: 2,
          backgroundColor: isActiveTermClass(row.termId)
            ? "primary.lighter"
            : "inherit",
          position: "relative",
          cursor: "default",
          "&::before": isActiveTermClass(row.termId)
            ? {
                content: '""',
                position: "absolute",
                left: 0,
                top: 0,
                bottom: 0,
                width: 4,
                backgroundColor: "primary.main",
                borderTopLeftRadius: 4,
                borderBottomLeftRadius: 4,
              }
            : {},
        }}
      >
        <Stack spacing={1.5}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            spacing={1}
          >
            <Typography
              variant="subtitle1"
              component="div"
              sx={{
                flex: 1,
                pr: 1,
                fontWeight: 500,
              }}
            >
              {row.class}
            </Typography>
            <IconButton
              size="small"
              onClick={(e) => handleMenuClick(e, row)}
              sx={{
                color: "text.secondary",
                backgroundColor: "action.hover",
                "&:hover": {
                  backgroundColor: "action.selected",
                },
                padding: 1,
                borderRadius: 1,
              }}
            >
              <MoreVertIcon fontSize="small" />
            </IconButton>
          </Stack>

          <Stack spacing={2}>
            <Stack
              direction="row"
              spacing={0.5}
              sx={{ color: "text.secondary" }}
            >
              <Typography
                variant="body2"
                sx={{
                  fontWeight: 500,
                  color: "text.primary",
                }}
              >
                Subject:
              </Typography>
              <Typography variant="body2">{row.subject}</Typography>
            </Stack>

            <Stack
              direction="row"
              spacing={0.5}
              sx={{ color: "text.secondary" }}
            >
              <Typography
                variant="body2"
                sx={{
                  fontWeight: 500,
                  color: "text.primary",
                }}
              >
                Duration:
              </Typography>
              <Typography variant="body2">
                {row.duration === "-" ? "Not set" : `${row.duration} minutes`}
              </Typography>
            </Stack>
          </Stack>

          <Typography
            variant="body2"
            sx={{
              color: isActiveTermClass(row.termId)
                ? "primary.main"
                : "text.secondary",
              fontWeight: isActiveTermClass(row.termId) ? 500 : 400,
              display: "flex",
              alignItems: "center",
              gap: 0.5,
              mt: 0.5,
            }}
          >
            {isActiveTermClass(row.termId) && (
              <Box
                component="span"
                sx={{
                  width: 8,
                  height: 8,
                  borderRadius: "50%",
                  backgroundColor: "primary.main",
                  display: "inline-block",
                }}
              />
            )}
            {row.termName}
          </Typography>
        </Stack>
      </Box>
    ),
    [isActiveTermClass, handleMenuClick]
  );

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (newRowsPerPage) => {
    setRowsPerPage(newRowsPerPage);
    setPage(0);
  };

  const handleDelete = async () => {
    try {
      await executeDeleteUser(selectedAccountId, id);
      setMessage({
        text: "User deleted successfully",
        severity: "success",
        flag: true,
      });
      navigate("/users");
    } catch (err) {
      setMessage({
        text: err.message || "Failed to delete user",
        severity: "error",
        flag: true,
      });
    }
    setShowDeleteModal(false);
  };

  const tableData = useMemo(() => {
    if (!classData?.content) return [];

    return classData.content.map((instance) => ({
      id: instance.id,
      class: instance.name || "Untitled Class",
      subject: instance.lessonPlanSummary?.subject || "-",
      duration: instance.lessonPlanSummary?.durationPerLesson || "-",
      termId: instance.termSummary?.id,
      termName: instance.termSummary?.name || "-",
      originalInstance: instance,
    }));
  }, [classData]);

  const handleAvatarUpload = useCallback(
    async (imageUrl) => {
      try {
        debug.avatar.log("UserDetails: Received new avatar URL:", imageUrl);

        // Update local state immediately for better UX
        setCurrentAvatarUrl(imageUrl);

        // Close the modal before fetching
        setIsAvatarEditorOpen(false);

        // Fetch updated user details in background
        await executeUserFetch();

        setMessage({
          text: "Avatar updated successfully",
          severity: "success",
          flag: true,
        });
        return true;
      } catch (err) {
        debug.avatar.error("UserDetails: Avatar update failed:", err);
        setMessage({
          text: err.message || "Failed to update avatar",
          severity: "error",
          flag: true,
        });
        return false;
      }
    },
    [executeUserFetch, setMessage]
  );

  const handleDeleteAvatar = async () => {
    try {
      debug.avatar.log("UserDetails: Deleting avatar...");
      await executeDeleteAvatar(userDetails.id, selectedAccountId);

      // Update local state immediately
      setCurrentAvatarUrl(null);

      // Close the modal
      setShowAvatarModal(false);

      // Fetch updated user details in background
      await executeUserFetch();

      setMessage({
        text: "Avatar deleted successfully",
        severity: "success",
        flag: true,
      });
    } catch (err) {
      debug.avatar.error("UserDetails: Delete avatar failed:", err);
      setMessage({
        text: err.message || "Failed to delete avatar",
        severity: "error",
        flag: true,
      });
    }
  };

  const handleAvatarClick = () => {
    setShowAvatarModal(true);
  };

  const handleSelectNewImage = () => {
    document.getElementById("avatar-upload").click();
    setShowAvatarModal(false);
  };

  const handleEnrollSuccess = useCallback(async () => {
    if (userDetails?.id && selectedAccountId) {
      try {
        // Wait for both operations to complete using Promise.all
        await Promise.all([
          executeClassesFetch(
            userDetails.id,
            selectedAccountId,
            activeTab === 0 ? "CURRENT" : activeTab === 1 ? "FUTURE" : "PAST",
            page,
            rowsPerPage
          ),
          executeCountsFetch(userDetails.id, selectedAccountId),
        ]);

        // Only close modal after both operations complete successfully
        setShowDeleteEnrollmentModal(false);
        setSelectedInstance(null);
      } catch (err) {
        // Only show error if it's not a canceled request
        if (err?.name !== "CanceledError") {
          // Check if data was actually updated despite the error
          if (!classData?.content) {
            setMessage({
              text: "Failed to refresh class list",
              severity: "error",
              flag: true,
            });
          }
        }
      }
    }
  }, [
    executeClassesFetch,
    executeCountsFetch,
    userDetails?.id,
    selectedAccountId,
    activeTab,
    page,
    rowsPerPage,
    setMessage,
    classData,
  ]);

  debug.avatar.log(
    "UserDetails render - isAvatarEditorOpen:",
    isAvatarEditorOpen,
    "userDetails:",
    userDetails?.id
  );

  if (userLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="200px"
      >
        <CircularProgress />
      </Box>
    );
  }

  if (userError || !userDetails) {
    return (
      <Box>
        <Typography color="error">
          {userError?.message || "Failed to load user details"}
        </Typography>
      </Box>
    );
  }

  return (
    <Box>
      <Stack direction={{ xs: "column", sm: "row" }} spacing={3} sx={{ mb: 3 }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: { xs: "center", sm: "flex-start" },
          }}
        >
          <AvatarUpload
            currentImageUrl={currentAvatarUrl}
            onImageUpload={handleAvatarUpload}
            name={`${userDetails?.firstName} ${userDetails?.lastName}`}
            editable={false}
            size={120}
            userId={userDetails.id}
            isEditorOpen={isAvatarEditorOpen}
            onEditorClose={setIsAvatarEditorOpen}
          />
        </Box>

        <Box sx={{ flex: 1, textAlign: { xs: "center", sm: "left" } }}>
          <Typography variant="h4" gutterBottom>
            {userDetails.firstName} {userDetails.lastName}
          </Typography>
          <Typography variant="body1" color="text.secondary" gutterBottom>
            {userDetails.email}
          </Typography>

          <StyledButtonGroup
            direction={{ xs: "column", sm: "row" }}
            spacing={1.5}
            sx={{ mt: 2 }}
          >
            <Button
              variant="outlined"
              size="small"
              startIcon={<PhotoCameraIcon />}
              onClick={handleAvatarClick}
              sx={{ borderRadius: 1 }}
            >
              Edit Avatar
            </Button>
            <Button
              variant="outlined"
              size="small"
              startIcon={<EditIcon />}
              onClick={() => setShowEditModal(true)}
              sx={{ borderRadius: 1 }}
            >
              Edit Details
            </Button>
            <MoreActionsButton
              variant="outlined"
              size="small"
              startIcon={<MoreVertIcon />}
              onClick={(e) => setAnchorEl(e.currentTarget)}
              sx={{ borderRadius: 1 }}
            >
              More Actions
            </MoreActionsButton>
          </StyledButtonGroup>
        </Box>
      </Stack>

      <HsModal
        open={showAvatarModal}
        handleClose={() => setShowAvatarModal(false)}
        title="Edit Profile Image"
      >
        <Stack spacing={2}>
          <Typography>What would you like to do?</Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSelectNewImage}
            fullWidth
          >
            Select New Image
          </Button>
          {userDetails.profileImageUrl && (
            <Button
              variant="contained"
              color="error"
              onClick={handleDeleteAvatar}
              fullWidth
            >
              Remove Current Image
            </Button>
          )}
        </Stack>
      </HsModal>

      <Paper
        sx={{
          mb: 3,
          borderRadius: 2,
          overflow: "hidden",
        }}
      >
        <StyledTabs
          value={activeTab}
          onChange={handleTabChange}
          sx={{
            borderBottom: 1,
            borderColor: "divider",
            bgcolor: "background.paper",
            minHeight: { xs: 48, sm: "auto" },
          }}
        >
          <Tab
            label={
              <Stack direction="row" spacing={1} alignItems="center">
                <span>Current</span>
                <Box
                  sx={{
                    bgcolor: "primary.main",
                    color: "primary.contrastText",
                    borderRadius: "50%",
                    width: { xs: 18, sm: 20 },
                    height: { xs: 18, sm: 20 },
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    fontSize: { xs: "0.675rem", sm: "0.75rem" },
                  }}
                >
                  {tabCounts.current}
                </Box>
              </Stack>
            }
          />
          <Tab
            label={
              <Stack direction="row" spacing={1} alignItems="center">
                <span>Future</span>
                <Box
                  sx={{
                    bgcolor: "primary.main",
                    color: "primary.contrastText",
                    borderRadius: "50%",
                    width: { xs: 18, sm: 20 },
                    height: { xs: 18, sm: 20 },
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    fontSize: { xs: "0.675rem", sm: "0.75rem" },
                  }}
                >
                  {tabCounts.future}
                </Box>
              </Stack>
            }
          />
          <Tab
            label={
              <Stack direction="row" spacing={1} alignItems="center">
                <span>Past</span>
                <Box
                  sx={{
                    bgcolor: "primary.main",
                    color: "primary.contrastText",
                    borderRadius: "50%",
                    width: { xs: 18, sm: 20 },
                    height: { xs: 18, sm: 20 },
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    fontSize: { xs: "0.675rem", sm: "0.75rem" },
                  }}
                >
                  {tabCounts.past}
                </Box>
              </Stack>
            }
          />
        </StyledTabs>

        <Box sx={{ p: { xs: 1.5, sm: 2 } }}>
          <Stack direction="row" justifyContent="flex-end" sx={{ mb: 2 }}>
            <Button
              variant="contained"
              onClick={handleOpenEnrollStudentModal}
              sx={{
                borderRadius: 1,
                minWidth: { xs: "100%", sm: "auto" },
              }}
            >
              Add Class
            </Button>
          </Stack>

          <HsTable
            columns={enrollmentColumns}
            data={tableData}
            totalCount={classData?.totalElements || 0}
            page={page}
            rowsPerPage={rowsPerPage}
            loading={classesLoading}
            error={classesError}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleRowsPerPageChange}
            renderDesktopRow={renderDesktopRow}
            renderMobileRow={renderMobileRow}
            showPagination={true}
            onRowClick={null}
            disableRowHover={true}
            emptyMessage={
              classesError
                ? "Unable to load classes. Please try again later."
                : `No ${
                    activeTab === 0
                      ? "current"
                      : activeTab === 1
                      ? "future"
                      : "past"
                  } classes`
            }
          />
        </Box>
      </Paper>

      <EnrollStudentModal
        open={openEnrollStudentModal}
        handleClose={handleCloseEnrollStudentModal}
        handleApply={handleEnrollStudent}
        studentDetails={userDetails}
        classes={lessonPlans}
        terms={[...activeTerms, ...futureTerms]}
        onSuccess={handleEnrollSuccess}
      />
      <DeleteConfirmationModal
        open={showDeleteModal}
        handleClose={() => setShowDeleteModal(false)}
        onConfirm={handleDelete}
        isLoading={isDeleting}
        title="Delete User"
        message={`Are you sure you want to delete ${userDetails?.firstName} ${userDetails?.lastName}? This action cannot be undone.`}
      />
      <EditUserModal
        open={showEditModal}
        handleClose={() => setShowEditModal(false)}
        user={userDetails}
        onSave={() => {
          executeUserFetch();
          setShowEditModal(false);
        }}
      />

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        PaperProps={{
          sx: {
            mt: 1,
            "& .MuiMenuItem-root": {
              minHeight: 48,
              px: 2,
            },
          },
        }}
      >
        <MenuItem
          onClick={handleDeleteClick}
          sx={{
            color: "error.main",
            "&:hover": {
              backgroundColor: "error.lighter",
            },
            minWidth: 200,
          }}
        >
          <DeleteIcon sx={{ fontSize: 20, mr: 1 }} />
          Un-enroll From Class
        </MenuItem>
      </Menu>

      {showDeleteEnrollmentModal && selectedInstance && (
        <DeleteClassEnrollmentModal
          open={showDeleteEnrollmentModal}
          handleClose={handleCloseDeleteModal}
          classInstance={selectedInstance.originalInstance}
          studentId={userDetails.id}
          onSuccess={handleEnrollSuccess}
        />
      )}
    </Box>
  );
};

export default UserDetailsPage;

import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Divider,
  Link,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";
import { resendVerification } from "../api/authApi";
import { useLogin } from "../hooks/useLogin";
import HsGoogleButton from "./HsGoogleButton";

const Login = ({ modal = false }) => {
  const location = useLocation();
  const [formData, setFormData] = useState({
    username: location.state?.userEmail || "",
    password: "",
  });
  const {
    loginWithCredentials,
    error,
    isLoading,
    verificationRequired: loginVerificationRequired,
    userEmail,
  } = useLogin();
  const [resendingEmail, setResendingEmail] = useState(false);
  const [nextResendTime, setNextResendTime] = useState(null);
  const [resendSuccess, setResendSuccess] = useState(false);

  const showVerification =
    loginVerificationRequired || location.state?.verificationRequired;
  const emailToVerify = userEmail || location.state?.userEmail;
  const isNewRegistration = location.state?.newRegistration;

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleLogin = async (e) => {
    e?.preventDefault();
    loginWithCredentials(formData);
  };

  const handlePasswordKeyDown = (e) => {
    if (e.key === "Enter") {
      handleLogin();
    }
  };

  const handleResendVerification = async () => {
    setResendingEmail(true);
    setResendSuccess(false);
    try {
      await resendVerification(userEmail);
      setNextResendTime(null);
      setResendSuccess(true);
      // Auto-hide success message after 5 seconds
      setTimeout(() => setResendSuccess(false), 5000);
    } catch (error) {
      if (error.type === "RATE_LIMITED") {
        setNextResendTime(error.nextAllowedTime);
      }
    } finally {
      setResendingEmail(false);
    }
  };

  const formatTimeRemaining = (nextAllowedTime) => {
    const now = Date.now();
    const diff = nextAllowedTime - now;
    const minutes = Math.ceil(diff / (1000 * 60));
    return `${minutes} minute${minutes !== 1 ? "s" : ""}`;
  };

  const content = (
    <>
      <Typography variant="h4" gutterBottom align="center">
        Sign In
      </Typography>
      <Typography
        variant="body1"
        align="center"
        color="textSecondary"
        sx={{ mb: 4 }}
      >
        Welcome back to Homeschool Planner
      </Typography>

      {showVerification && (
        <Alert
          severity="info"
          sx={{ mb: 2 }}
          action={
            <Button
              color="inherit"
              size="small"
              onClick={handleResendVerification}
              disabled={resendingEmail || nextResendTime !== null}
              endIcon={resendingEmail && <CircularProgress size={16} />}
            >
              {nextResendTime
                ? `Resend available in ${formatTimeRemaining(nextResendTime)}`
                : "Resend Email"}
            </Button>
          }
        >
          <div>
            {isNewRegistration
              ? "Account created successfully! Please check your email to verify your account."
              : "Please verify your email address. A verification email has been sent to"}{" "}
            {emailToVerify}.
          </div>
        </Alert>
      )}

      {nextResendTime && (
        <Alert severity="warning" sx={{ mb: 2 }}>
          Too many attempts. Please wait {formatTimeRemaining(nextResendTime)}{" "}
          before requesting another email.
        </Alert>
      )}

      {resendSuccess && (
        <Alert severity="success" sx={{ mb: 2 }}>
          Verification email has been resent successfully.
        </Alert>
      )}

      {error && !loginVerificationRequired && (
        <Alert severity="error" sx={{ mb: 2 }}>
          Login failed. Please check your credentials and try again.
        </Alert>
      )}

      <form onSubmit={handleLogin}>
        <TextField
          label="Email"
          name="username"
          value={formData.username}
          onChange={handleInputChange}
          margin="normal"
          fullWidth
          required
          autoComplete="email"
        />
        <TextField
          label="Password"
          type="password"
          name="password"
          value={formData.password}
          onChange={handleInputChange}
          onKeyDown={handlePasswordKeyDown}
          margin="normal"
          fullWidth
          required
          autoComplete="current-password"
        />
        <Box sx={{ mt: 3, mb: 2 }}>
          <Button
            variant="darkRounded"
            color="primary"
            onClick={handleLogin}
            disabled={isLoading}
            fullWidth
          >
            {isLoading ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              "Sign In"
            )}
          </Button>
        </Box>
      </form>

      <Divider sx={{ my: 3 }}>
        <Typography variant="body2" color="textSecondary">
          OR
        </Typography>
      </Divider>

      <Box sx={{ mb: 3 }}>
        <HsGoogleButton mode="login" fullWidth />
      </Box>

      <Typography variant="body2" align="center">
        Don't have an account?{" "}
        <Link
          component={RouterLink}
          to="/register"
          color="primary"
          sx={{ fontWeight: 500 }}
        >
          Sign up here
        </Link>
      </Typography>
    </>
  );

  if (modal) {
    return content;
  }

  return (
    <Box
      sx={{
        position: "relative",
        height: "calc(100vh - 64px)",
        overflow: "auto",
      }}
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "100%",
          maxWidth: "400px",
          p: 2,
        }}
      >
        <Paper
          elevation={4}
          sx={{
            p: 4,
            width: "100%",
            borderRadius: 2,
          }}
        >
          {content}
        </Paper>
      </Box>
    </Box>
  );
};

export default Login;

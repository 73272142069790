import MenuIcon from "@mui/icons-material/Menu";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import PersonIcon from "@mui/icons-material/Person";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import {
  AppBar,
  Avatar,
  Badge,
  Box,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  Toolbar,
  Typography,
  useTheme,
} from "@mui/material";
import { googleLogout } from "@react-oauth/google";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { logout } from "../api/authApi";
import { getCart } from "../api/cartApi";
import HsModal from "../components/common/HsModal";
import LoginModal from "../components/modals/LoginModal";
import { useDrawer } from "../hooks/useDrawer";
import { setCart } from "../redux/cartSlice";
import { debug } from "../utils/debug";
import OrderPrintedPlanner from "./printedPlanner/OrderPrintedPlanner";

const PrintPlannerButton = ({ isMobile, onClick }) => (
  <Button
    onClick={onClick}
    variant="contained"
    color="secondary"
    size={isMobile ? "small" : "medium"}
    startIcon={
      <MenuBookIcon
        sx={{
          color: (theme) => theme.palette.primary.main,
        }}
      />
    }
    sx={{
      backgroundColor: (theme) => theme.palette.secondary.main,
      color: (theme) => theme.palette.drawer.text,
      fontWeight: 500,
      borderRadius: "12px",
      transition: "all 0.2s ease-in-out",
      boxShadow: "none",
      whiteSpace: "nowrap",
      minWidth: isMobile ? "auto" : "180px",
      height: isMobile ? "40px" : "44px",
      px: isMobile ? 2 : 3,
      fontSize: isMobile ? "0.813rem" : "0.875rem",
      "&:hover": {
        backgroundColor: (theme) => theme.palette.secondary.light,
        transform: "translateY(-1px)",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
      },
      "&:active": {
        transform: "translateY(0)",
        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
      },
    }}
  >
    {isMobile ? "Printed Planner" : "Order Printed Planner"}
  </Button>
);

const HeaderBar = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const theme = useTheme();
  const { isOpen, toggleDrawer, isMobile, drawerWidth, setIsOpen } =
    useDrawer();
  const { loggedIn, loggedInUser } = useSelector((state) => state.auth);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [logoutModalOpen, setLogoutModalOpen] = useState(false);
  const [prevIsMobile, setPrevIsMobile] = useState(isMobile);
  const [showOrderPlannerModal, setShowOrderPlannerModal] = useState(false);
  const [orderPlannerKey, setOrderPlannerKey] = useState(0);
  const cartItems = useSelector((state) => {
    const items = state.cart.items;
    debug.cart.log("Cart items updated:", items);
    return items;
  });

  const handleNavigateToRegister = () => navigate("/register");
  const handleLogin = () => setShowLoginModal(true);
  const handleCloseLoginModal = () => setShowLoginModal(false);
  const handleMenu = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);
  const openLogoutModal = () => setLogoutModalOpen(true);
  const closeLogoutModal = () => setLogoutModalOpen(false);

  const handleLogout = async () => {
    googleLogout();
    await dispatch(logout());
    navigate("/");
  };

  const handleClickNavItem = (route) => {
    handleClose();
    navigate(route);
  };

  const handleOrderPlannerClick = () => {
    setOrderPlannerKey((prevKey) => prevKey + 1);
    setShowOrderPlannerModal(true);
  };

  const handleCloseOrderPlannerModal = () => {
    debug.wizard.state("HeaderBar closing planner modal");
    setShowOrderPlannerModal(false);
  };

  const handleCartClick = () => {
    navigate("/cart");
  };

  useEffect(() => {
    const fetchCartData = async () => {
      try {
        const cartData = await getCart();
        dispatch(setCart(cartData));
      } catch (error) {
        debug.cart.error("Failed to fetch cart data:", error);
      }
    };

    if (loggedIn) {
      fetchCartData();
    }
  }, [loggedIn, dispatch]);

  useEffect(() => {
    const updateHeaderBar = () => {
      const appBar = document.querySelector(".MuiAppBar-root");
      if (appBar) {
        if (loggedIn && !isMobile) {
          appBar.style.width = `calc(100% - ${
            isOpen ? drawerWidth + 8 : theme.spacing(9)
          }px)`;
          appBar.style.marginLeft = isOpen
            ? `${drawerWidth}px`
            : theme.spacing(9);
        } else {
          appBar.style.width = "100%";
          appBar.style.marginLeft = "0";
        }
      }
    };

    // Check if transitioning from mobile to desktop
    if (prevIsMobile && !isMobile) {
      setIsOpen(true); // Force drawer to open when transitioning to desktop
    }

    // Check if transitioning from desktop to mobile
    if (!prevIsMobile && isMobile) {
      setIsOpen(false); // Force drawer to close when transitioning to mobile
    }

    updateHeaderBar();
    setPrevIsMobile(isMobile);

    window.addEventListener("resize", updateHeaderBar);

    return () => {
      window.removeEventListener("resize", updateHeaderBar);
    };
  }, [loggedIn, isMobile, isOpen, drawerWidth, theme, prevIsMobile, setIsOpen]);

  return (
    <AppBar
      position="fixed"
      sx={{
        width:
          loggedIn && !isMobile
            ? `calc(100% - ${isOpen ? drawerWidth + 8 : theme.spacing(9)}px)`
            : "100%",
        ml:
          loggedIn && !isMobile ? (isOpen ? drawerWidth : theme.spacing(9)) : 0,
        transition: theme.transitions.create(["width", "margin"], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        bgcolor: "transparent",
      }}
    >
      <Toolbar
        sx={{
          justifyContent: "space-between",
          borderRadius: "16px",
          bgcolor: theme.palette.primary.main,
          minHeight: 64,
          padding: isMobile ? "0 16px" : "0 16px",
          margin: 1,
        }}
      >
        {isMobile && loggedIn && (
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={toggleDrawer}
            sx={{
              mr: 1,
              zIndex: 1,
              position: "relative",
            }}
          >
            <MenuIcon />
          </IconButton>
        )}
        {!isMobile && (
          <Typography variant="h6" sx={{ color: "white" }}>
            Homeschool Planner
          </Typography>
        )}
        {loggedIn ? (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 2,
              zIndex: 1,
              position: "relative",
            }}
          >
            <PrintPlannerButton
              isMobile={isMobile}
              onClick={handleOrderPlannerClick}
            />
            <IconButton
              color="inherit"
              onClick={handleCartClick}
              sx={{
                zIndex: 1,
                position: "relative",
              }}
            >
              <Badge badgeContent={cartItems.length} color="secondary">
                <ShoppingCartIcon />
              </Badge>
            </IconButton>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                p: isMobile ? "4px 8px" : "4px 12px",
                cursor: "pointer",
              }}
              onClick={handleMenu}
            >
              <Avatar
                src={loggedInUser?.profileImageUrl}
                alt={loggedInUser?.firstName}
                sx={{
                  width: isMobile ? 32 : 32,
                  height: isMobile ? 32 : 32,
                  mr: 1,
                }}
              >
                {!loggedInUser?.profileImageUrl && <PersonIcon />}
              </Avatar>
              <Typography
                variant="body2"
                sx={{
                  color: "white",
                  mr: 1,
                  display: { xs: "none", sm: "block" },
                }}
              >
                {loggedInUser?.firstName}
              </Typography>
            </Box>
            <Menu
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              <MenuItem onClick={() => handleClickNavItem("/profile")}>
                Profile
              </MenuItem>
              <MenuItem onClick={() => handleClickNavItem("/account-settings")}>
                Account Settings
              </MenuItem>
              <MenuItem onClick={() => handleClickNavItem("/orders")}>
                Orders
              </MenuItem>
              <MenuItem onClick={openLogoutModal}>Logout</MenuItem>
            </Menu>
          </Box>
        ) : (
          <>
            <Button
              onClick={handleNavigateToRegister}
              variant="contained"
              color="secondary"
            >
              Try for Free
            </Button>
            <Button
              onClick={handleLogin}
              variant="outlined"
              color="inherit"
              sx={{ ml: 2 }}
            >
              Log In
            </Button>
          </>
        )}
      </Toolbar>
      <HsModal
        open={logoutModalOpen}
        onClose={closeLogoutModal}
        title="Confirm Logout"
      >
        <Box
          sx={{
            width: "100%",
            maxWidth: 400,
            bgcolor: "background.paper",
            p: 3,
          }}
        >
          <Typography variant="body1" sx={{ mt: 2, mb: 3 }}>
            Are you sure you want to log out?
          </Typography>
          <Stack direction="row" spacing={2} justifyContent="center">
            <Button variant="outlined" onClick={closeLogoutModal}>
              Cancel
            </Button>
            <Button
              variant="darkRounded"
              color="primary"
              onClick={handleLogout}
            >
              Logout
            </Button>
          </Stack>
        </Box>
      </HsModal>
      {showLoginModal && (
        <LoginModal
          open={showLoginModal}
          handleClose={handleCloseLoginModal}
          showRegisterAccount={handleNavigateToRegister}
        />
      )}
      <OrderPrintedPlanner
        key={orderPlannerKey}
        open={showOrderPlannerModal}
        onClose={handleCloseOrderPlannerModal}
      />
    </AppBar>
  );
};

export default HeaderBar;

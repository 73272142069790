import { Box, Paper, Typography } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import ProfileDetails from "./components/ProfileDetails";

const ProfilePage = () => {
  const { loggedInUser } = useSelector((state) => state.auth);

  if (!loggedInUser) {
    return null;
  }

  return (
    <Box sx={{ maxWidth: 1200, p: 3 }}>
      <Typography variant="h4" gutterBottom>
        Profile
      </Typography>
      <Paper sx={{ p: 3 }}>
        <ProfileDetails user={loggedInUser} />
      </Paper>
    </Box>
  );
};

export default ProfilePage;
